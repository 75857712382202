import { memo, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  Editor as DraftEditor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  type ContentBlock,
  type DraftHandleValue,
  // convertFromHTML,
  convertFromRaw,
  convertToRaw,
  // ContentState,
  type RawDraftContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useTranslation } from 'react-i18next';
import './Editor.css';
import createStyles from 'draft-js-custom-styles';
import DEFAULT_ADVISER_MESSAGES_EN from 'app/components/Editor/adviser-message-en.json';
import DEFAULT_ADVISER_MESSAGES_ZH_HANT_HK from 'app/components/Editor/adviser-message-zh-Hant-HK.json';
import DEFAULT_ADVISER_MESSAGES_ZH from 'app/components/Editor/adviser-message-zh.json';
import DEFAULT_TEAM_MESSAGES from 'app/components/Editor/team-message.json';

import BlockStyleControls from './BlockStyleControls';
// import InlineStyleControls from './InlineStyleControls';
// import ColorButton from './ColorButton';
// import EmojiButton from './EmojiButton';
import BottomOrDialog from '../BottomOrDialog/BottomOrDialog';
import { formatEditorStateValue } from 'utils/validators/validators';
// import { getNextSelection } from 'utils/Draft-js/getNextSelection';
// import { getLastSelection } from 'utils/Draft-js/getLastSelection';
import { getAnchorKey } from 'utils/Draft-js/getAnchorKey';
import { getCurrentColor } from 'utils/Draft-js/getCurrentColor';
import VSpace from 'app/components/VSpace';
// import FontSizeButton from './FontSizeButton';
import createFocusPlugin from 'draft-js-focus-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import EditorToolsBar from './EditorToolBar/EditorToolBar';
// import ColorButton from './ColorButton';


type EditorProps = {
  title: string;
  description?: string | undefined;
  readOnly?: boolean | undefined;
  type: string;
  defaultValue: () => string;
  setContent: (val: RawDraftContentState) => void;
};

const Editor = ({
  title,
  description,
  readOnly,
  type,
  defaultValue,
  setContent,
}: EditorProps) => {
  const { t, i18n } = useTranslation();
  const editorRef = useRef<DraftEditor>(null);
  const boxRef = useRef<HTMLObjectElement>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = useState<boolean>(false);
  const [togglesActive, setTogglesActive] = useState<{
    [key: string]: boolean | string | null | undefined | number;
  }>({
    color: null,
    bold: undefined,
    italic: undefined,
    underline: undefined,
    // fontsize: 16,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const generateDefaultContent = useCallback(() => {
    let msg;
    switch (type) {
      case 'aboutMe':
        msg =
          i18n.language === 'zh-Hant-HK'
            ? DEFAULT_ADVISER_MESSAGES_ZH_HANT_HK
            : (msg =
              i18n.language === 'zh'
                ? DEFAULT_ADVISER_MESSAGES_ZH
                : DEFAULT_ADVISER_MESSAGES_EN);
        break;
      case 'team':
        msg = DEFAULT_TEAM_MESSAGES;
        break;
      default:
        return;
    }
    setEditorState(EditorState.createWithContent(convertFromRaw(msg)));
    setContent(msg);
    handleClose();
  }, [i18n.language, setContent, type]);
  useEffect(() => {
    const message = defaultValue() || generateDefaultContent();
    if (message && message !== '') {
      setEditorState(formatEditorStateValue(message));
    }
  }, [defaultValue]);
  const focusPlugin = createFocusPlugin();
  const toolbarPlugin = createToolbarPlugin();

  return useMemo(() => {
    const { styles, customStyleFn, exporter } = createStyles(['font-size', 'color', 'text-transform'], 'CUSTOM_');

    const getBlockStyle = (block: ContentBlock) => {
      switch (block.getType()) {
        case 'align-left':
        case 'align-center':
        case 'align-right':
          return `DraftEditor-${block.getType()}`;
        case 'blockquote':
          return 'RichEditor-blockquote';
        default:
          return '';
      }
    };

    const handleSetTogglesActive = (editorState: EditorState) => {
      setTogglesActive({
        color: getCurrentColor(editorState),
        bold: editorState.getCurrentInlineStyle().has('BOLD'),
        italic: editorState.getCurrentInlineStyle().has('ITALIC'),
        underline: editorState.getCurrentInlineStyle().has('UNDERLINE'),
      });
    };
    const applyInlineStylesToContent = (editorState: EditorState) => {
      let _editorState = editorState;
      const boldCheck = editorState.getCurrentInlineStyle().has('BOLD');
      const italicCheck = editorState.getCurrentInlineStyle().has('ITALIC');
      const underlineCheck = editorState
        .getCurrentInlineStyle()
        .has('UNDERLINE');

      if (
        togglesActive.bold !== undefined &&
        togglesActive.bold !== boldCheck
      ) {
        _editorState = RichUtils.toggleInlineStyle(_editorState, 'BOLD');
      }
      if (
        togglesActive.italic !== undefined &&
        togglesActive.italic !== italicCheck
      ) {
        _editorState = RichUtils.toggleInlineStyle(_editorState, 'ITALIC');
      }
      if (
        togglesActive.underline !== undefined &&
        togglesActive.underline !== underlineCheck
      ) {
        _editorState = RichUtils.toggleInlineStyle(_editorState, 'UNDERLINE');
      }

      if (
        togglesActive.color !== undefined &&
        togglesActive.color !== null &&
        getCurrentColor(_editorState) !== togglesActive.color
      ) {
        _editorState = styles.color.toggle(_editorState, togglesActive.color);
      }

      // if (
      //   togglesActive.fontsize !== undefined &&
      //   getCurrentFontSize(_editorState) !== togglesActive.fontsize
      // ) {
      //   console.log(
      //     'applyInlineStylesToContent , togglesActive:  toggleInlineStyle in fontsize',
      //   );
      //   _editorState = RichUtils.toggleInlineStyle(
      //     _editorState,
      //     `${FontStyleKey}${togglesActive.fontsize}`,
      //   );
      // } else {
      //   console.log(
      //     'applyInlineStylesToContent togglesActive.fontsize: ',
      //     togglesActive.fontsize,
      //   );
      //   console.log(
      //     'applyInlineStylesToContent getCurrentFontSize(_editorState): ',
      //     getCurrentFontSize(_editorState),
      //   );
      //   console.log(
      //     'applyInlineStylesToContent , togglesActive:  else in fontsize',
      //   );
      // }

      return _editorState;
    };

    const handleRawChange = (newEditorState: EditorState) => {
      const nextState = applyInlineStylesToContent(newEditorState);
      setEditorState(nextState);
      setContent(convertToRaw(nextState.getCurrentContent()));
    };

    const focusEditor = () => {
      if (
        editorRef.current &&
        document.activeElement !== editorRef.current.editor
      ) {
        editorRef?.current?.focus();
      }
    };
    const handleChangeWithAutoFocus = (state: EditorState) => {
      setEditorState(state);
      handleSetTogglesActive(state);
      setContent(convertToRaw(state.getCurrentContent()));
      setTimeout(() => {
        focusEditor();
      }, 0);
    };

    const mapKeyToEditorCommand = (e: any): string | null => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4 /* maxDepth */,
        );
        if (newEditorState !== editorState) {
          handleChangeWithAutoFocus(newEditorState);
        }
        return null;
      }
      return getDefaultKeyBinding(e);
    };

    // const handleToggleChange = (newEditorState: EditorState) => {
    //   const colorChange = getCurrentColor(newEditorState);
    //   if (
    //     colorChange !== togglesActive.color
    //   ) {
    //     setTogglesActive({
    //       ...togglesActive,
    //       // fontsize: fontSize,
    //       color: colorChange,
    //     });
    //   }
    //   setEditorState(newEditorState);
    //   setTimeout(() => {
    //     EditorState.forceSelection(
    //       editorState,
    //       getLastSelection(newEditorState),
    //     );
    //     setEditorState(newEditorState);
    //     setContent(convertToRaw(newEditorState.getCurrentContent()));
    //     focusEditor();
    //   }, 0);
    // };

    const handleKeyCommand = (
      command: string,
      editorState: EditorState,
      eventTimeStamp: number,
    ): DraftHandleValue => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        handleChangeWithAutoFocus(newState);
        return 'handled';
      }
      return 'not-handled';
    };

    const toggleBlockType = (blockType: string) => {
      const nextState = RichUtils.toggleBlockType(editorState, blockType);
      handleChangeWithAutoFocus(nextState);
    };

    // const toggleInlineStyle = (style: string) => {
    //   const newEditorState = RichUtils.toggleInlineStyle(editorState, style);

    //   handleSetTogglesActive(newEditorState);
    //   handleToggleChange(newEditorState);
    // };

    // console.log(
    //   'editorState selection:',
    //   editorState,
    //   ' fontsize: ',
    //   togglesActive.fontsize,
    //   ' editorfont: ',
    //   getCurrentFontSize(editorState),
    // );

    return (
      <>
        <Box
          ref={boxRef}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography fontSize="16px" fontWeight="500">
            {title}
          </Typography>
          <Button
            variant="outlined"
            onClick={readOnly ? undefined : handleOpen}
            sx={{
              fontSize: '13px',
              fontWeight: '500',
              padding: '8px 7px',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            {t('Global.Default')}
          </Button>
        </Box>
        {description && (
          <Typography
            variant="body2"
            sx={{
              mb: 1,
            }}
          >
            {description}
          </Typography>
        )}
        <div className="DraftEditor-wrap">
          {/* <div className="DraftEditor-tools"> */}
          {/* <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
              disabled={readOnly || false}
            /> */}

          {/* <FontSizeButton
              editorRef={editorRef}
              editorState={editorState}
              disabled={readOnly || false}
              onChange={handleToggleChange}
              onClose={handleToggleClose}
            /> */}
          {/* 
            <ColorButton
              editorRef={editorRef}
              editorState={editorState}
              colorStyles={styles.color}
              onChange={handleToggleChange}
              onClose={handleToggleClose}
              disabled={readOnly || false}
            /> */}

          {/* <EmojiButton
              editorState={editorState}
              onToggle={toggleBlockType}
              onChange={handleToggleChange}
              onClose={handleToggleClose}
              disabled={readOnly || false}
            /> */}
          <EditorToolsBar
            styles={styles}
            editorState={editorState}
            onChange={handleChangeWithAutoFocus}
            toogleBlockControls={<BlockStyleControls
              disabled={readOnly || false}
              editorState={editorState}
              onToggle={toggleBlockType}
            />
            }
            readOnly={false}
            plugins={{
              toolbar: toolbarPlugin,
              focus: focusPlugin,
            }}
          />
          {/* </div> */}
          <Box onClick={focusEditor}>
            <DraftEditor
              key={getAnchorKey(editorState)}
              ref={editorRef}
              editorState={editorState}
              customStyleFn={customStyleFn}
              blockStyleFn={(block: ContentBlock) => getBlockStyle(block)}
              keyBindingFn={e => mapKeyToEditorCommand(e)}
              onChange={handleRawChange}
              spellCheck={true}
              handleKeyCommand={handleKeyCommand}
              readOnly={readOnly}
              plugins={[focusPlugin, toolbarPlugin]}
            />
          </Box>
        </div>
        <VSpace size={2} />
        <BottomOrDialog open={open} onClose={handleClose}>
          <Box
            sx={{
              boxShadow: 'var(--shadow-l4)',
              '&:focus-visible': {
                outline: 'none',
              },
            }}
          >
            <Box
              sx={{
                background: 'white',
                padding: '20px 26px 16px',
                borderRadius: '16px',
              }}
            >
              <Typography
                variant="h5"
                color="var(--color-blue-300)"
                fontWeight={500}
                sx={{
                  mb: '20px',
                }}
              >
                {t('Global.Confirm')}
              </Typography>
              <Typography
                color="var(--color-text-primary)"
                sx={{
                  mb: 3,
                }}
              >
                {t('Global.SavedMessageWouldBeDeleted')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    minWidth: '112px',
                  }}
                  onClick={handleClose}
                >
                  {t('Global.Cancel')}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    minWidth: '112px',
                    boxShadow: 'var(--shadow-l6)',
                  }}
                  onClick={generateDefaultContent}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Box>
        </BottomOrDialog>
      </>
    );
  }, [
    description,
    editorState,
    open,
    readOnly,
    setContent,
    t,
    title,
    togglesActive,
    type,
  ]);
};

export default memo(Editor);
