import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './EditProfile.module.css';
import { useEditProfileContext } from 'app/context/editProfileContext';
import { getTargetAvatar } from 'utils/getAvatarList';

function Avatar({ disableChangeAvatar = false, size = {} }) {
  const { t } = useTranslation();
  const { avatar, userName, setAvatarDialog } = useEditProfileContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box className={styles.avatarContainer} sx={{ width: 'fit-content', height: 'fit-content' }}>
        {getTargetAvatar(avatar, userName, size)}
      </Box>
      {!disableChangeAvatar && (
        <Typography
          variant="body2"
          color="primary"
          onClick={() => setAvatarDialog(true)}
          sx={{
            marginTop: '8px',
            cursor: 'pointer',
            textDecoration: 'underLine',
          }}
        >
          {t('Avatar.ChangePicture')}
        </Typography>
      )}
    </Box>
  );
}

export default Avatar;
