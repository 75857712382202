import { RGBColor } from 'types';
// 1. Input Hex Color
// 2. Convert Hex Color to RGB Color
// 3. Output RGB Color

export function hexColorToRGB(hexColor: string): RGBColor {
  // 1. Input Hex Color
  const hex = hexColor.replace('#', '');

  // 2. Convert Hex Color to RGB Color
  const bigint = parseInt(hex, 16);
  const r = ((bigint >> 16) & 255) / 255;
  const g = ((bigint >> 8) & 255) / 255;
  const b = (bigint & 255) / 255;

  // 3. Output RGB Color

  return { r, g, b };
}
