import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { GenericResponse } from 'services/apiTypes';

export const subscription = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    subscribeLinkerFree: builder.mutation<GenericResponse<any>, void>({
      query: () => ({
        body: {
          priceId: process.env.REACT_APP_LINKER_FREE_PRICE_ID,
          cancelAtPeriodEnd: false,
          couponId: undefined,
        },
        url: '/stripe/createSubscription',
        method: 'POST',
      }),
    }),
    getActiveSubscription: builder.query<
      {
        subscriptionNickname:
          | 'LinkerPro'
          | 'Linker'
          | 'Pro'
          | string
          | undefined;
      },
      string
    >({
      query: userId => ({
        url: `/user-subscriptions/check-adviser/${userId}`,
        method: 'GET',
      }),
    }),
  };
};
