import { EditorState } from 'draft-js';

export function getCurrentColor(editorState: EditorState): string | null {
  const inlineStyles = editorState.getCurrentInlineStyle();

  const colorStyle = inlineStyles.find(style =>
    style.startsWith('CUSTOM_COLOR_'),
  );

  if (colorStyle) {
    return colorStyle.replace('CUSTOM_COLOR_', '');
  }
  return null;
}
