import { getIsFreeUser } from 'utils/validators/validator/subscription';
import { HideFeature, InfoAlert } from './AlertComponent/AlertComponent';
import { InfoAlertProps } from './AlertComponent/Components/InfoAlert/InfoAlert.types';
import {
  PaidFeatureAlertProps,
  PaidFeatureAlertType,
} from './PaidFeatureAlert.types';
import { Box } from '@mui/material';

function PaidFeatureAlert({ plan, ...props }: PaidFeatureAlertProps) {
  let shouldShowAlert: boolean;
  const type = props.type;

  if (typeof plan === 'boolean') {
    shouldShowAlert = plan;
  } else {
    shouldShowAlert = getIsFreeUser(plan);
  }
  if (
    type === PaidFeatureAlertType.info ||
    type === PaidFeatureAlertType.team) {
    if ('render' in props) {
      return (
        <InfoAlert
          render={props?.render}
          shouldShowAlert={shouldShowAlert}
          {...(props as InfoAlertProps)}
        />
      );
    } else {
      return (
        <InfoAlert
          render={(onAlert, shouldShowAlert) => (
            <Box onClick={shouldShowAlert ? onAlert : undefined}>
              {props.children}{' '}
            </Box>
          )}
          shouldShowAlert={shouldShowAlert}
          {...(props as InfoAlertProps)}
        />
      );
    }
  } else {
    if ('render' in props) {
      return (
        <HideFeature shouldShowAlert={shouldShowAlert} render={props.render} />
      );
    } else {
      return (
        <HideFeature
          shouldShowAlert={shouldShowAlert}
          render={(onAlert, shouldShowAlert) => <>{props.children}</>}
        />
      );
    }
  }
}
export default PaidFeatureAlert;
