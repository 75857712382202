import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './EditProfile.module.css';
import VSpace from '../VSpace';
import TurorialTips from '../TurorialTips';
import Container from '../Container';
import EditProfileStepper from './EditProfileStepper';
import AboutMe from './EditProfileForm/AboutMe';
import Business from './EditProfileForm/Business';
import Profile from './EditProfileForm/Profile';
import Social from './EditProfileForm/Social';
import Extra from './EditProfileForm/Extra';
import Navigation from '../Navigation';
import { EditProfileContext } from 'app/context/editProfileContext';
import SelectAvatarDialog from './SelectAvatarDialog';
import AvatarUploadDialog from './AvatarUploadDialog';
import type { ViewMode } from 'types';
import { useForm, FormProvider } from 'react-hook-form';
import {
  EDIT_PROFILE_FORM_SCHEMA,
  EDIT_PROFILE_FORM_DEFAULT_VALUE,
} from './EditProfileFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateAdviserProfileMutation } from 'services/api';
import ConfirmSubmitDialog from './ConfirmSubmitDialog';
import { AuthenticatedUserContext, trackMilestoneEvent } from 'hooks/hooks';
import React from 'react';
import Loading from '../Loading/Loading';
import { postMessageToParent } from 'utils/Frame/postMessageToParent';
import { redirectToPFP } from 'app/route/route';
import { isFrameCheck } from 'utils/Frame/isFrameCheck';

export function EditProfile({ viewMode }: { viewMode: ViewMode }) {
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const { t } = useTranslation();
  const [updateAdviser] = useUpdateAdviserProfileMutation();
  const methods = useForm<any>({
    resolver: yupResolver(EDIT_PROFILE_FORM_SCHEMA),
    defaultValues: EDIT_PROFILE_FORM_DEFAULT_VALUE,
  });

  useEffect(() => {
    if (AuthenticatedUser.profile && AuthenticatedUser.profile.adviserInfo) {
      const advisor = AuthenticatedUser.profile;
      methods.setValue('_id', advisor.adviserInfo._id);
      methods.setValue('avatar', advisor.adviserInfo.avatar);
      methods.setValue('name', advisor.adviserInfo.name);
      methods.setValue('email', advisor.adviserInfo.email);
      methods.setValue('gender', advisor.adviserInfo.gender);
      methods.setValue('workEmail', advisor.adviserInfo.workEmail);
      methods.setValue('phoneNumber', advisor.adviserInfo.phoneNumber);
      methods.setValue('phoneNumber2', advisor.adviserInfo.phoneNumber2 || '');
      methods.setValue('whatsapp', advisor.adviserInfo.whatsapp);
      methods.setValue('wechatId', advisor.adviserInfo.wechatId);
      methods.setValue('wechatUrl', advisor.adviserInfo.wechatUrl);
      methods.setValue('lineId', advisor.adviserInfo.lineId);
      methods.setValue('dob', advisor.adviserInfo.dob);
      if (advisor.adviserInfo.age) {
        methods.setValue('age', advisor.adviserInfo.age);
      }
      methods.setValue('personality', advisor.adviserInfo.personality);
      if (advisor.adviserInfo.mainLanguages) {
        methods.setValue('mainLanguages', advisor.adviserInfo.mainLanguages);
      }
      if (advisor.adviserInfo.personalityTags) {
        methods.setValue(
          'personalityTags',
          advisor.adviserInfo.personalityTags,
        );
      }
      methods.setValue(
        'adviserMessage',
        advisor.adviserInfo.adviserMessage || '',
      );
      methods.setValue('company', advisor.adviserInfo.company || '');
      methods.setValue('jobTitle', advisor.adviserInfo.jobTitle || '');
      methods.setValue('seniority', advisor.adviserInfo.seniority || '');
      methods.setValue('license', advisor.adviserInfo.license || '');
      methods.setValue(
        'shouldShowLicense',
        advisor.adviserInfo.shouldShowLicense || false,
      );
      methods.setValue(
        'mainProductsServices',
        advisor.adviserInfo.mainProductsServices || [],
      );
      methods.setValue('isMDRT', advisor.adviserInfo.isMDRT || []);
      methods.setValue(
        'jobExperience',
        advisor.adviserInfo.jobExperience || '',
      );
      methods.setValue('otherAwards', advisor.adviserInfo.otherAwards || '');
      methods.setValue('hobbies', advisor.adviserInfo.hobbies || []);
      methods.setValue(
        'customizedHobby',
        advisor.adviserInfo.customizedHobby || '',
      );
      if (
        advisor.adviserInfo.familiarPlaces &&
        advisor.adviserInfo.familiarPlaces.length > 0
      ) {
        methods.setValue(
          'familiarPlaces',
          '#' + advisor.adviserInfo.familiarPlaces.join('#'),
        );
      }
      if (
        advisor.adviserInfo.university &&
        Array.isArray(advisor.adviserInfo.university) &&
        advisor.adviserInfo.university.length > 0
      ) {
        methods.setValue(
          'university',
          '#' + advisor.adviserInfo.university.join('#'),
        );
      }
      if (
        advisor.adviserInfo.secondarySchool &&
        Array.isArray(advisor.adviserInfo.secondarySchool) &&
        advisor.adviserInfo.secondarySchool.length > 0
      ) {
        methods.setValue(
          'secondarySchool',
          '#' + advisor.adviserInfo.secondarySchool.join('#'),
        );
      }
      if (
        advisor.adviserInfo.otherHashtags &&
        advisor.adviserInfo.otherHashtags.length > 0
      ) {
        methods.setValue(
          'otherHashtags',
          '#' + advisor.adviserInfo.otherHashtags.join('#'),
        );
      }
      methods.setValue('instagram', advisor.adviserInfo.instagram || '');
      methods.setValue('facebookLink', advisor.adviserInfo.facebookLink || '');
      methods.setValue('linkedinLink', advisor.adviserInfo.linkedinLink || '');
      methods.setValue(
        'realLinkedinLink',
        advisor.adviserInfo.realLinkedinLink || '',
      );
      methods.setValue('themeColor', advisor.adviserInfo.themeColor || 'blue');
      methods.setValue('preThemeColor', advisor.adviserInfo.themeColor || null);
      methods.setValue(
        'customThemeColor',
        advisor.adviserInfo.customThemeColor || null,
      );
      if (advisor.adviserInfo.customThemeColor) {
        methods.setValue('themeColor', advisor.adviserInfo.customThemeColor);
      }
      methods.setValue('company', advisor.adviserInfo.company || '');
      methods.setValue(
        'shouldHideProfilePlusCustomQuestions',
        advisor.adviserInfo.shouldHideProfilePlusCustomQuestions === undefined
          ? true
          : advisor.adviserInfo.shouldHideProfilePlusCustomQuestions,
      );
      methods.setValue(
        'shouldShowProfilePlusTeamLogo',
        advisor.adviserInfo.shouldShowProfilePlusTeamLogo || false,
      );
      methods.setValue(
        'shouldShowProfilePlusTeamPage',
        advisor.adviserInfo.shouldShowProfilePlusTeamPage || false,
      );
      methods.setValue(
        'teamDescription',
        advisor.adviserInfo.teamDescription || '',
      );
      methods.setValue(
        'teamInstagram',
        advisor.adviserInfo.teamInstagram || '',
      );
      methods.setValue('teamPage', advisor.adviserInfo.teamPage || '');
      methods.setValue('teamFacebook', advisor.adviserInfo.teamFacebook || '');
      methods.setValue('teamLinkedin', advisor.adviserInfo.teamLinkedin || '');
      methods.setValue('teamWechat', advisor.adviserInfo.teamWechat || '');
      methods.setValue(
        'customQuestions',
        advisor.adviserInfo.customQuestions || [],
      );
      methods.setValue(
        'customizedProductsOrServices',
        advisor.adviserInfo.customizedProductsOrServices || '',
      );
    }
  }, [AuthenticatedUser, AuthenticatedUser.profile, methods]);

  const onSubmit = async () => {
    const themeColorOption = ['blue', 'green', 'orange', 'violet', 'earth'];
    const data = methods.getValues();
    if (data.customThemeColor !== data.themeColor) {
      data.customThemeColor = null;
    }
    if (data.preThemeColor && !themeColorOption.includes(data.themeColor)) {
      data.themeColor = data.preThemeColor;
    }
    delete data.preThemeColor;
    if (data.familiarPlaces.length > 0) {
      const familiarPlacesArr = data.familiarPlaces.split('#');
      if (familiarPlacesArr[0] === '') {
        familiarPlacesArr.shift();
      }
      data.familiarPlaces = familiarPlacesArr;
    } else {
      data.familiarPlaces = [];
    }
    if (data.university.length > 0) {
      const universityArr = data.university.split('#');
      if (universityArr[0] === '') {
        universityArr.shift();
      }
      data.university = universityArr;
    } else {
      data.university = [];
    }
    if (data.secondarySchool.length > 0) {
      const secondarySchoolArr = data.secondarySchool.split('#');
      if (secondarySchoolArr[0] === '') {
        secondarySchoolArr.shift();
      }
      data.secondarySchool = secondarySchoolArr;
    } else {
      data.secondarySchool = [];
    }
    if (data.otherHashtags.length > 0) {
      const otherHashtagsArr = data.otherHashtags.split('#');
      if (otherHashtagsArr[0] === '') {
        otherHashtagsArr.shift();
      }
      data.otherHashtags = otherHashtagsArr;
    } else {
      data.otherHashtags = [];
    }
    const res = await updateAdviser(data).unwrap();
    if (res.success) {
      trackMilestoneEvent({
        type: 'Profile Updated',
        event_properties: {
          Success: true,
        },
      });
      const isFrame = isFrameCheck();
      if (isFrame) {
        postMessageToParent({
          success: true,
          code: 'PROFILE_UPDATED',
          message: 'Profile updated successfully',
        });
      } else {
        redirectToPFP(navigate, '/profile-plus');
      }

      AuthenticatedUser.updateProfile(res.data);
    } else {
      trackMilestoneEvent({
        type: 'Profile Update Failed',
        event_properties: {
          Success: false,
          ...data,
        },
      });
    }
    setConfirmSubmitDialog(false);
  };
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [key: number]: boolean }>({});
  const [avatarDialog, setAvatarDialog] = useState<boolean>(false);
  const [uploadAvatarDialog, setUploadAvatarDialog] = useState<boolean>(false);
  const [confirmSubmitDialog, setConfirmSubmitDialog] =
    useState<boolean>(false);
  const handleStepNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    const completedArr = new Array(activeStep + 1).fill(true);
    const completedObj = Object.assign({}, completedArr);
    setCompleted(completedObj);
  };

  const handleStepBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    const completedArr = new Array(activeStep).fill(true);
    const completedObj = Object.assign({}, completedArr);
    setCompleted(completedObj);
  };

  const watchName = methods.watch('name', '');
  const watchAvatar = methods.watch('avatar', '');
  const setAvatar = val => {
    methods.setValue('avatar', val);
  };

  const renderCurrentStepForm = currentStep => {
    switch (currentStep) {
      case 0:
        return (
          <Container
            sx={{
              height: '100%',
              padding: { xs: '16px', md: '24px' },
            }}
          >
            <Profile next={handleStepNext} />
          </Container>
        );
      case 1:
        return <AboutMe back={handleStepBack} next={handleStepNext} />;
      case 2:
        return <Business back={handleStepBack} next={handleStepNext} />;
      case 3:
        return (
          <Social
            back={handleStepBack}
            next={handleStepNext}
            userId={AuthenticatedUser.user!._id}
          />
        );
      case 4:
        return (
          <Extra
            back={handleStepBack}
            handleSave={() => setConfirmSubmitDialog(true)}
          />
        );
      default:
        throw new Error('[currentStep] CurrentStep has Error.');
    }
  };

  const renderCurrentStepTipsWording = currentStep => {
    switch (currentStep) {
      case 0:
        return t('AdviserProfile.StepTips.Profile');
      case 1:
        return t('AdviserProfile.StepTips.AboutMe');
      case 2:
        return t('AdviserProfile.StepTips.Business');
      case 3:
        return t('AdviserProfile.StepTips.Social');
      case 4:
        return t('AdviserProfile.StepTips.CustomQuestion');
      default:
        throw new Error('[currentStep] CurrentStep has Error.');
    }
  };

  const anchor = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    anchor.current?.scrollIntoView({ behavior: 'smooth' });
  }, [anchor, activeStep]);

  if (
    !methods.getValues()._id ||
    (methods.getValues()._id && methods.getValues()._id === '')
  ) {
    return <Loading />;
  }
  return (
    <>
      <Box
        className={
          viewMode === 'iframe' ? styles.frameContainer : styles.pageContainer
        }
      >
        <div ref={anchor} />
        {!(viewMode === 'iframe') && (
          <Navigation label={t('Global.BACK')} back={() => navigate(-1)} />
        )}
        {viewMode !== 'iframe' && <VSpace size={3} />}
        <TurorialTips label={renderCurrentStepTipsWording(activeStep)} />
        <Grid
          container
          sx={{
            flexWrap: 'nowrap',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Grid
            item
            sx={{
              flex: { xs: 'auto', md: '0 0 184px' },
              marginRight: { xs: '0', md: '28px' },
            }}
          >
            <Container style={styles.stepperContainer}>
              <EditProfileStepper
                currentStep={activeStep}
                completed={completed}
                setCompleted={setCompleted}
                setActiveStep={setActiveStep}
              />
            </Container>
          </Grid>
          <Grid
            item
            maxWidth={{ xs: '100%', md: 'calc(100% - 184px - 28px)' }}
            sx={{ flexGrow: '1' }}
          >
            <EditProfileContext.Provider
              value={{
                avatar: watchAvatar,
                avatarDialog,
                userName: watchName,
                setAvatar,
                setAvatarDialog,
              }}
            >
              <FormProvider {...methods}>
                <form>{renderCurrentStepForm(activeStep)}</form>
              </FormProvider>
            </EditProfileContext.Provider>
          </Grid>
        </Grid>
      </Box>

      <SelectAvatarDialog
        userName={watchName}
        selectedValue={watchAvatar}
        setSelectedValue={setAvatar}
        open={avatarDialog}
        onClose={() => setAvatarDialog(false)}
        openUploadDialog={() => setUploadAvatarDialog(true)}
      />
      <AvatarUploadDialog
        advisorId={AuthenticatedUser!.user!._id}
        setSelectedValue={setAvatar}
        open={uploadAvatarDialog}
        onClose={() => setUploadAvatarDialog(false)}
        closeSelectAvatarModal={() => setAvatarDialog(false)}
      />
      <ConfirmSubmitDialog
        open={confirmSubmitDialog}
        onClose={() => setConfirmSubmitDialog(false)}
        handleSubmit={onSubmit}
      />
    </>
  );
}
