import * as yup from 'yup';

export const EDIT_PROFILE_FORM_SCHEMA = yup.object().shape({
  _id: yup.string().required(),
  avatar: yup.string(),
  name: yup.string().required('此欄位為必填'),
  email: yup.string().required('此欄位為必填'),
  gender: yup.string(),
  workEmail: yup.string(),
  phoneNumber: yup.string().nullable(),
  phoneNumber2: yup.string().nullable(),
  whatsapp: yup.string().nullable(),
  wechatId: yup.string(),
  wechatUrl: yup.string(),
  lineId: yup.string(),
  dob: yup.string().nullable(),
  age: yup.number(),
  personality: yup.string().oneOf(['D', 'I', 'S', 'C']),
  mainLanguages: yup.array().of(yup.string()).min(1, "此欄位為必填").required("此欄位為必填"),
  personalityTags: yup.array().of(yup.string()).min(1, "此欄位為必填").required("此欄位為必填"),
  adviserMessage: yup.string(),
  jobTitle: yup.string(),
  seniority: yup.string(),
  mainProductsServices: yup.array().of(yup.string()).min(1, "此欄位為必填").required("此欄位為必填"),
  customizedProductsOrServices: yup.string(),
  isMDRT: yup.array().of(yup.string()),
  jobExperience: yup.string(),
  hobbies: yup.array().of(yup.string()).min(1, "此欄位為必填").required("此欄位為必填"),
  familiarPlaces: yup.array().of(yup.string()).nullable(),
  university: yup.array().of(yup.string()).nullable(),
  secondarySchool: yup.array().of(yup.string()).nullable(),
  otherHashtags: yup.array().of(yup.string()).nullable(),
  instagram: yup.string(),
  facebookLink: yup.string(),
  linkedinLink: yup.string(),
  themeColor: yup.string().nullable(),
  customThemeColor: yup.string().nullable(),
  company: yup.string(),
  shouldShowProfilePlusTeamLogo: yup.boolean(),
  shouldShowProfilePlusTeamPage: yup.boolean(),
  teamDescription: yup.string(),
  teamPage: yup.string(),
  teamInstagram: yup.string(),
  teamFacebook: yup.string(),
  teamLinkedin: yup.string(),
  teamWechat: yup.string(),
  customQuestions: yup.array().of(yup.object()).nullable(),
});

export const EDIT_PROFILE_FORM_DEFAULT_VALUE = {
  avatar: '',
  name: '',
  email: '',
  gender: '',
  workEmail: '',
  phoneNumber: '',
  phoneNumber2: '',
  whatsapp: '',
  wechatId: '',
  wechatUrl: '',
  lineId: '',
  dob: null,
  age: 0,
  personality: undefined,
  mainLanguages: [],
  personalityTags: [],
  adviserMessage: undefined,
  hobbies: [],
  customizedHobby: '',
  familiarPlaces: [],
  university: [],
  secondarySchool: [],
  otherHashtags: [],
  instagram: '',
  facebookLink: '',
  linkedinLink: '',
  themeColor: 'blue',
  customThemeColor: null,
  company: '',
  shouldShowProfilePlusTeamLogo: false,
  shouldShowProfilePlusTeamPage: false,
  teamDescription: '',
  teamPage: '',
  teamInstagram: '',
  teamFacebook: '',
  teamLinkedin: '',
  teamWechat: '',
  customQuestions: [],
  customizedProductsOrServices: '',
};
