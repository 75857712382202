export function validate(url: string): boolean {
  const pattern = /^(http:\/\/|https:\/\/)/;
  return pattern.test(url);
}

export function isSelfhosted(url: string): boolean {
  const publicUrl = process.env.REACT_APP_PUBLIC_URL;
  if (!publicUrl) return false;
  else return url.startsWith(publicUrl);
}
