// import { EditProfilePage } from 'app/pages/EditProfilePage/Loadable';
import UserConvertion from 'app/pages/Convertion';
import { Route } from '../types';

export const PRIVATE_ROUTES: Route[] = [
  //   {
  //     path: '/my-details',
  //     index: true,
  //     element: <EditProfilePage />,
  //     titlei18nKey: 'EditProfilePage.Title',
  //   },

  {
    path: '/conversion',
    index: true,
    element: <UserConvertion />,
  },
];
