import { Factsheet } from 'types';
import dayjs from './dayjs';

export function formatDob(date: string) {
  const dayjsDate = dayjs(date);
  const formattedDate = dayjsDate.format('DD-MMM-YYYY');
  const age = dayjs().diff(dayjsDate, 'year');
  return `${formattedDate} (${age})`;
}

type MuiColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined;

type LatestMeetingProgress = {
  value: number;
  color: MuiColor;
};

export function formatLatestMeetingProgress(
  date: string,
): LatestMeetingProgress {
  const dayjsDate = dayjs(date);
  const monthsFromNow = dayjs().diff(dayjsDate, 'month');

  if (monthsFromNow < 6) {
    return {
      value: 20,
      color: 'primary',
    };
  }
  if (monthsFromNow < 12) {
    return {
      value: 50,
      color: 'success',
    };
  }
  if (monthsFromNow < 18) {
    return {
      value: 75,
      color: 'warning',
    };
  }
  return {
    value: 100,
    color: 'error',
  };
}

export function formatLatestMeeting(date: string) {
  const dayjsDate = dayjs(date);

  return dayjsDate.fromNow();
}

export function calculateAge(date: string) {
  const dayjsDate = dayjs(date);
  const now = dayjs();

  return now.diff(dayjsDate, 'year');
}

export const formatNumber = (
  number: number,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    ...options,
  }).format(number);

export const BORDER_COLORS: MuiColor[] = [
  'success',
  'warning',
  'error',
  'info',
];
export const CPI_WORDINGS = ['High', 'Good', 'Fair', 'No Data'];

export const getCPIScoreColor = (client: Factsheet) =>
  client.validScore && client.scoreLevel < BORDER_COLORS.length
    ? BORDER_COLORS[client.scoreLevel]
    : BORDER_COLORS[3];
export const getCPIWording = client => {
  return client.validScore && parseInt(client.scoreLevel) < CPI_WORDINGS.length
    ? CPI_WORDINGS[parseInt(client.scoreLevel)]
    : CPI_WORDINGS[3];
};

export const formatOpenUrl = url => {
  return !url || url.indexOf('http://') > -1 || url.indexOf('https://') > -1
    ? url
    : `https://${url}`;
};

// export const formatReferralLink = (advisor: Advisor) => {
//   // !!! Should change to /api/v1/
//   const HOSTNAME = process.env.REACT_APP_PFPLUS_BACKEND_URL;
//   const { adviserInfo } = advisor;
//   return `${HOSTNAME}/adviser/${adviserInfo._id}`;
// };

export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber ? phoneNumber.replace(/\+852|\+86|\+886/, '$& ') : '';
