/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getDeviceOS() {
  var uA = navigator.userAgent || navigator.vendor;
  if (
    /iPad|iPhone|iPod/.test(uA) ||
    (uA.includes('Mac') && 'ontouchend' in document)
  )
    return 'iOS';

  var i: number,
    os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
  for (i = 0; i < os.length; i++)
    if (new RegExp(os[i], 'i').test(uA)) return os[i];

  return 'unknown';
}

export function isMobileBrowser() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|iphone|ipad|ipod|mobile/i.test(userAgent);
};
