import { Helmet } from 'react-helmet-async';
import { EditProfile } from 'app/components/EditProfile/EditProfile';
import { loginUrl } from '../../../constants';
import { ViewMode } from 'types';
import { AuthenticatedUserContext, trackMilestoneEvent } from 'hooks/hooks';
import { useContext, useEffect, useMemo } from 'react';

export function EditProfilePage(props: { viewMode?: ViewMode }) {
  const search = new URLSearchParams(window.location.search);
  const _viewMode: ViewMode =
    (props.viewMode || (search.get('viewMode') as ViewMode)) ?? 'standard';
  const AuthenticatedUser = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (!AuthenticatedUser.isLoading.user && !AuthenticatedUser.isLoading.profile) {
      trackMilestoneEvent({
        type: 'Profile Edit Started',
        event_properties: {
          Success: true,
        },
      })
    }
  }, [AuthenticatedUser.isLoading.user && AuthenticatedUser.isLoading.profile]);

  return useMemo(() => {
    if (!AuthenticatedUser.initialized || AuthenticatedUser.isAnyPartLoading) {
      return null;
    }

    if (!AuthenticatedUser.user || !AuthenticatedUser.profile) {
      window.location.replace(loginUrl);
      return null;
    }

    return (
      <>
        <Helmet>
          <title>Edit Profile</title>
        </Helmet>
        <EditProfile viewMode={_viewMode} />
      </>
    );
  }, [AuthenticatedUser.isLoading.profile, AuthenticatedUser.isLoading.user, AuthenticatedUser.profile, AuthenticatedUser.user, _viewMode]);
}
