import { Timeslot } from 'services/timeslot/types';
import { Typography, Button, Card, Box } from '@mui/material';
import TimeslotCardItem from './TimeslotCardItem';
import { CalendarToday, AccessTime, LocationOn } from '@mui/icons-material';
import VSpace from 'app/components/VSpace';
import { useTranslation } from 'react-i18next';
import Loader from 'app/components/Loader/Loader';
import { SelectableStatus } from 'app/components/Animatable/Animatable';
import { getTranslation } from 'utils/localization/localization';

const TrHeader = 'TimeslotSelector.TimeslotCard';
function TimeslotCard(props: {
  status: SelectableStatus;
  timeslot: Timeslot;
  index: number;

  onMakeAppointment: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { timeslot, index, onMakeAppointment, status } = props;
  const [t] = useTranslation();

  return (
    <Box style={{ padding: '8px' }}>
      <Card
        sx={{
          boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.6)',
          backgroundColor: '#fefefe',
          padding: '12px 24px',
          color: 'inherit',
          borderRadius: '20px',
        }}
      >
        {(timeslot.eventName || timeslot.eventDescription) && (
          <>
            {timeslot.eventName && (
              <Typography variant={'h6'} color="primary">
                {getTranslation(timeslot.eventName)}
              </Typography>
            )}
            {timeslot.eventDescription && (
              <Typography variant={'body2'}>
                {getTranslation(timeslot.eventDescription)}
              </Typography>
            )}
            <VSpace size={1} />
          </>
        )}
        <TimeslotCardItem
          icon={<CalendarToday />}
          text={new Date(timeslot.datetime).toLocaleDateString()}
        />
        <TimeslotCardItem
          icon={<AccessTime />}
          text={new Date(timeslot.datetime).toLocaleTimeString()}
        />
        <TimeslotCardItem
          icon={<LocationOn />}
          text={getTranslation(timeslot.address)}
        />
        <VSpace size={2} />
        <Loader
          container={{
            sx: {
              display: 'block',
              textAlign: 'center',
              width: '100%',
              minHeight: '37px',
            },
          }}
          loading={status === SelectableStatus.SELECTED}
        >
          <Button
            fullWidth
            disabled={status !== SelectableStatus.DISPLAYING}
            onClick={onMakeAppointment}
            variant="contained"
            color="primary"
          >
            {t(TrHeader + '.cta-make-appoinment')}
          </Button>
        </Loader>
      </Card>
    </Box>
  );
}

export default TimeslotCard;
