import { CountryCode } from 'libphonenumber-js/core';

export const countryList: { labels: CountryCode[] } = {
  labels: [
    'AU',
    'CA',
    'CN',
    'FR',
    'HK',
    'ID',
    'JP',
    'MO',
    'MY',
    'NZ',
    'PH',
    'SG',
    'KR',
    'TW',
    'TH',
    'GB',
    'US',
    'VN',
    'DE',
  ],
};
