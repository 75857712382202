import { useState, useEffect } from 'react';
import type { EditProfileFormProps } from './formPorps';
import { Box, Button, Grid, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImageIcon from '@mui/icons-material/Image';
import FormField from 'app/components/FormField';
import VSpace from 'app/components/VSpace';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './EditProfileForm.module.css';
import { PERSONALITY_OPTIONS } from '../../../../constants/smartFilter';
import Avatar from '../Avatar';
import {
  useIsMobile,
  useIsMiniScreen,
} from 'hooks/useBreakpoints/useBreakpoints';
import PersonalityTypeDialog from '../../PersonalityTypeDialog';
import WechatQrCodeDescriptionDialog from '../../WechatQrCodeDescriptionDialog';
import type { PersonalityType } from 'types';
import { calculateAge } from 'utils/formatters';
import jsQR from 'jsqr';
import DiamondTrailing from 'app/components/DiamondTrailing/DiamondTrailing';
import HSpace from 'app/components/HSpace';
import { toast } from 'react-toastify';

function Profile({ next }: EditProfileFormProps) {
  const [
    wechatQrCodeDescriptionDialogOpen,
    setWechatQrCodeDescriptionDialogOpen,
  ] = useState<boolean>(false);
  const [wechatUrlError, setWechatUrlError] = useState<string>('');
  const { t, i18n } = useTranslation();
  const [personalityTypeDialog, setPersonalityTypeDialog] =
    useState<boolean>(false);
  const [personalityType, setPersonalityType] = useState<PersonalityType>('D');
  const isMobile = useIsMobile();
  const isMiniScreen = useIsMiniScreen();
  const { control, trigger, setValue, watch } = useFormContext();

  const dob = watch('dob');
  const wechatUrl = watch('wechatUrl');

  useEffect(() => {
    if (dob) {
      setValue('age', calculateAge(dob));
    }
  }, [dob]);

  const GENDER_OPTIONS = [
    {
      label: `🙋‍ ${t('AdviserProfile.Male')}`,
      value: 'Male',
    },
    {
      label: `🙋‍♀️ ${t('AdviserProfile.Female')}`,
      value: 'Female',
    },
  ];

  const handleNextOnClick = async () => {
    const validateResult = await trigger([
      'name',
      'gender',
      'email',
      'workEmail',
      'phoneNumber',
      'phoneNumber2',
      'whatsapp',
      'wechatId',
      'wechatUrl',
      'lineId',
      'dob',
      'personality',
    ]);
    if (validateResult) {
      next!();
    } else {
      const error = t('AdviserProfile.Error.Required');
      toast.error(error);
    }
  };

  const setpersonalityTypeValue = (val: PersonalityType) => {
    setValue('personality', val);
  };

  const handleQrCodeUpload = async event => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();

      reader.onload = e => {
        const image = new Image();
        if (e.target) {
          image.src = e.target.result as string;
        }

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (!context) {
            throw new Error('Unable to get 2d context');
          }

          // Resize the image for better QR detection
          const maxDimension = 500; // Limit the maximum dimension to 500px
          const scale = Math.min(
            maxDimension / image.width,
            maxDimension / image.height,
            1,
          );
          canvas.width = image.width * scale;
          canvas.height = image.height * scale;

          context.drawImage(image, 0, 0, canvas.width, canvas.height);

          // Convert to grayscale for better QR detection
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height,
          );
          for (let i = 0; i < imageData.data.length; i += 4) {
            const r = imageData.data[i];
            const g = imageData.data[i + 1];
            const b = imageData.data[i + 2];
            const gray = 0.299 * r + 0.587 * g + 0.114 * b;
            imageData.data[i] = gray; // Red
            imageData.data[i + 1] = gray; // Green
            imageData.data[i + 2] = gray; // Blue
          }

          // Invert colors: Dark QR on light background
          for (let i = 0; i < imageData.data.length; i += 4) {
            imageData.data[i] = 255 - imageData.data[i]; // Red inversion
            imageData.data[i + 1] = 255 - imageData.data[i + 1]; // Green inversion
            imageData.data[i + 2] = 255 - imageData.data[i + 2]; // Blue inversion
          }

          context.putImageData(imageData, 0, 0);

          // Pass the preprocessed image to jsQR
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code?.data && code.data.includes('wechat.com')) {
            setValue('wechatUrl', code.data);
            setWechatUrlError('');
          } else {
            setValue('wechatUrl', '');
            setWechatUrlError(
              t(
                'AdviserProfile.WechatQrCodeInstruction.unable-to-detect-wechat-qr-code',
              ),
            );
          }
        };
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error decoding QR code:', error);
      setValue('wechatUrl', '');
      setWechatUrlError(
        t(
          'AdviserProfile.WechatQrCodeInstruction.unable-to-detect-wechat-qr-code',
        ),
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Grid
          container
          sx={{
            marginBottom: '20px',
          }}
        >
          <Grid
            item
            sx={{
              flex: { xs: '100%', sm: '0 0 140px' },
              marginRight: { xs: '0', sm: '24px' },
              display: { xs: 'flex', sm: 'block' },
              alignItems: { xs: 'center' },
              marginBottom: { xs: '30px', sm: '0px' },
            }}
          >
            <Avatar />
            {isMobile && (
              <Box
                sx={{
                  marginLeft: '16px',
                  flexGrow: '1',
                }}
              >
                <FormField
                  type="text"
                  label={t('AdviserProfile.Name')}
                  name="name"
                  control={control}
                  required
                />
              </Box>
            )}
          </Grid>
          <Grid item sx={{ flexGrow: '1' }}>
            {!isMobile && (
              <FormField
                type="text"
                label={t('AdviserProfile.Name')}
                name="name"
                control={control}
                required
              />
            )}
            <FormField
              type="radioCard"
              label={t('AdviserProfile.Gender')}
              name="gender"
              control={control}
              options={GENDER_OPTIONS}
            />
          </Grid>
        </Grid>
        <Box className={styles.fieldsGroup}>
          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.LoginEmail')}
              name="email"
              control={control}
              required
              readOnly
            />
          </Box>
          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.WorkEmail')}
              name="workEmail"
              control={control}
            />
          </Box>
        </Box>
        <Box className={styles.fieldsGroup}>
          <Box>
            <FormField
              type="phoneNumber"
              label={t('AdviserProfile.Telephone')}
              name="phoneNumber"
              control={control}
            />
          </Box>
          <Box>
            <FormField
              type="phoneNumber"
              label={t('AdviserProfile.Telephone2')}
              name="phoneNumber2"
              control={control}
            />
          </Box>
        </Box>

        <Box className={`${styles.fieldsGroup}`}>
          <Box>
            <FormField
              type="phoneNumber"
              label={t('AdviserProfile.WhatsApp')}
              name="whatsapp"
              control={control}
              trailing={<DiamondTrailing focusingAdviserId={true} />}
            />
          </Box>

          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.LineId')}
              name="lineId"
              control={control}
            />
          </Box>
        </Box>

        <Box className={`${styles.fieldsGroup}`}>
          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.WechatId')}
              name="wechatId"
              control={control}
            />
          </Box>

          <Box>
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1} >
                  <Grid item>
                    <Typography>{t('AdviserProfile.WechatQrCode')}</Typography>
                  </Grid>
                  <Grid item>
                    <HSpace size={1} />
                    <DiamondTrailing focusingAdviserId={true} />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => setWechatQrCodeDescriptionDialogOpen(true)}
                      color="primary"
                      style={{ padding: '0 4px', minWidth: 'unset' }}
                    >
                      {t('AdviserProfile.WechatQrCodeInstruction.tutorial')}
                    </Button>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item>
                {wechatUrl ? (
                  <Button
                    variant="outlined"
                    onClick={() => setValue('wechatUrl', '')}
                    color="primary"
                  >
                    {t('AdviserProfile.WechatQrCodeInstruction.delete')}
                  </Button>
                ) : (
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<ImageIcon />}
                  >
                    {t('AdviserProfile.WechatQrCodeInstruction.upload')}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleQrCodeUpload}
                    />
                  </Button>
                )}
              </Grid>
            </Grid>

            {wechatUrl ? (
              <Typography style={{ color: '#4caf50' }}>
                ✅{' '}
                {t(
                  'AdviserProfile.WechatQrCodeInstruction.wechat-qr-code-uploaded',
                )}
              </Typography>
            ) : (
              !wechatUrlError && (
                <Typography>
                  ❌{' '}
                  {t(
                    'AdviserProfile.WechatQrCodeInstruction.no-wechat-qr-code-uploaded',
                  )}
                </Typography>
              )
            )}
            {wechatUrlError && (
              <Typography color="error">{wechatUrlError}</Typography>
            )}

            <VSpace size={2} />
          </Box>
        </Box>

        <Box className={`${styles.fieldsGroup} ${styles.twoColumns}`}>
          <Box>
            <FormField
              type="date"
              label={t('AdviserProfile.DateOfBirth')}
              name="dob"
              language={i18n.language}
              control={control}
            />
          </Box>
          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.Age')}
              name="age"
              readOnly
              control={control}
            />
          </Box>
        </Box>

        <FormField
          type="radioCard"
          label={t('AdviserProfile.PersonalityType')}
          name="personality"
          options={PERSONALITY_OPTIONS}
          tips="點選字母圖符以選擇客戶的D.I.S.C類型，系統將會提供增加成交率的建議"
          tipsMethod={() => setPersonalityTypeDialog(true)}
          control={control}
        />
        <VSpace size={2} />

        <Box
          className={styles.footer}
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{
              minWidth: { xs: '100%', md: '120px' },
              boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
            }}
            onClick={handleNextOnClick}
          >
            {t('Global.Next')}
          </Button>
        </Box>
      </div>
      <PersonalityTypeDialog
        open={personalityTypeDialog}
        onClose={() => setPersonalityTypeDialog(false)}
        selectedValue={personalityType}
        setSelectedValue={setPersonalityType}
        setPersonalityTypeValue={setpersonalityTypeValue}
      />
      <WechatQrCodeDescriptionDialog
        open={wechatQrCodeDescriptionDialogOpen}
        onClose={() => setWechatQrCodeDescriptionDialogOpen(false)}
      />
    </>
  );
}

export default Profile;
