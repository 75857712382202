import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Timeslot, TimeslotType } from './types';
import { GenericResponse } from 'services/apiTypes';

export * from './types';

export const timeslotAPI = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    availableTimeslot: builder.query<GenericResponse<Timeslot[]>, TimeslotType>(
      {
        query: type => `/timeslot/${type}/available`,
      },
    ),
  };
};
