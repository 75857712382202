import { SelectableStatus } from 'app/components/Animatable/Animatable';
import VSpace from 'app/components/VSpace';
import { useIsTablet } from 'hooks/useBreakpoints/useBreakpoints';
import { Timeslot } from 'services/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import TimeslotCard from '../TimeslotCard/TimeslotCard';
import { TimeslotSelectorLayoutProps } from '../TimeslotSelectorPanel.types';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const TimeSelectorSwiper = (props: TimeslotSelectorLayoutProps) => {
  const isTablet = useIsTablet();

  return (
    <div style={{ position: 'relative' }}>
      <Swiper
        modules={[Navigation]} // Register Navigation module
        initialSlide={0}
        slidesPerView={isTablet ? 1.35 : 1.5}
        fadeEffect={{
          crossFade: true,
        }}
        loop={true}
        centeredSlides={true}
        centeredSlidesBounds={true}
        autoHeight={true}
        touchStartPreventDefault={false}
        effect="coverflow"
        autoplay={{
          delay: 12000,
        }}
        coverflowEffect={{
          rotate: 0,
          depth: 350,
          modifier: 2,
          stretch: 75,
          slideShadows: false,
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        {props.timeslots.map((timeslot: Timeslot, index) => (
          <SwiperSlide key={timeslot._id}>
            <TimeslotCard
              timeslot={timeslot}
              index={index}
              onMakeAppointment={() => {
                props.handleMakeAppointment(timeslot);
              }}
              status={
                props.timeslotLoading === timeslot._id
                  ? SelectableStatus.SELECTED
                  : props.timeslotLoading === undefined
                  ? SelectableStatus.DISPLAYING
                  : SelectableStatus.NOTSELECTED
              }
            />
            <VSpace size={4} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
};

export default TimeSelectorSwiper;
