import { useAuthenticateUserQuery } from 'services/api';
import { Advisor } from 'types';

export function useAdviserProfileOwner(advisor?: Advisor) {
  const { data: user } = useAuthenticateUserQuery();
  const isAdvisorProfileOwnedByUser =
    !!(user?._id && advisor?.adviserInfo._id) &&
    user._id === advisor.adviserInfo._id;

  return isAdvisorProfileOwnedByUser;
}
