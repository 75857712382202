import React from 'react';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import Lottie from '../Lottie/Lottie';
import _ from 'lodash';
import { useAdvisorProfileTheme } from '../AdvisorProfile/AdvisorProfileThemeProvider';

const TypoStyle = {
  fontWeight: 'bold',
  fontSize: '1.5em',
};

const LottieContainerStyle = {
  position: 'relative',
  width: 24,
  height: 24,
};

const LottieStyle = {
  width: 54,
  height: 54,
  position: 'absolute',
  top: -22,
  left: -16,
};

function LikeAnimation({
  likes,
  TypoProps,
  LottieProps,
  style,
  color,
  render,
}: {
  label?: string;
  color?: string;
  likes?: number;
  TypoProps?: {
    style: React.CSSProperties;
  };
  LottieProps?: {
    style: React.CSSProperties;
  };
  style?: SxProps;
  render?: (animation: JSX.Element) => JSX.Element;
}) {
  const typoStyle = _.get(TypoProps, 'style');
  const lottieStyle = _.get(LottieProps, 'style');
  const formattedLottieProps = _.omit(LottieProps, ['style']);
  const { mainColor } = useAdvisorProfileTheme();
  const animation = (
    <Box sx={LottieContainerStyle}>
      <Lottie
        style={
          {
            ...lottieStyle,
            ...LottieStyle,
          } as React.CSSProperties
        }
        options={{
          animationData: '/lottie/likeanimation.json',
          loop: false,
          autoplay: true,
          color: color ?? mainColor,
        }}
        {...formattedLottieProps}
      />
    </Box>
  );

  if (render) {
    return render(animation);
  } else {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={style}
      >
        <Grid item>
          {likes && (
            <Typography
              style={{ ...TypoStyle, ...typoStyle, color: mainColor }}
            >
              {likes || 0}
            </Typography>
          )}
        </Grid>
        <Grid item>{animation}</Grid>
      </Grid>
    );
  }
}

export default LikeAnimation;
