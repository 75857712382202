export const validator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

function validate(email?: string): boolean {
  if (!email || email === '') return false;
  return validator.test(email);
}

const email = {
  validate,
  validator,
};

export default email;
