import { Typography } from '@mui/material';
import AdvisorProfileCard from './AdvisorProfileCard';
import VSpace from '../VSpace';
import Tags from '../Tags';
import { useTranslation } from 'react-i18next';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import ReadOnlyEditor from '../Editor/ReadOnlyEditor';
import { getAdviserMessage } from 'utils/validators/validators';

export default function AdvisorProfileAboutMeCard({ advisor, isDemo, isFreeUser }) {
  const { t, i18n } = useTranslation();
  const { tagStyle } = useAdvisorProfileTheme();

  if (!advisor) {
    return <AdvisorProfileCard loading isDemo={isDemo} isFreeUser={isFreeUser} />;
  }
  const {
    adviserInfo: { personalityTags },
  } = advisor;
  const message = getAdviserMessage(advisor.adviserInfo, i18n.language);
  return (
    <AdvisorProfileCard heading={t('AdviserProfile.ABOUTME')} isDemo={isDemo} isFreeUser={isFreeUser}>
      <Typography variant="subtitle2" fontWeight={500}>
        🤠 {t('AdviserProfile.MyPersonality')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={
          personalityTags?.map(tag =>
            t(`AdviserProfile.PersonalityTag.${tag}`),
          ) ?? []
        }
        tagProps={{
          sx: tagStyle,
          variant: 'outlined',
        }}
      />
      <VSpace size={4} />
      <Typography variant="subtitle2" fontWeight={500}>
        💬 {t('AdviserProfile.MyIntroduction')}
      </Typography>
      <VSpace size={1} />
      {/* TODO: migrate wysiwyg editor */}
      <ReadOnlyEditor defaultValue={message} readOnly />
      <VSpace size={5} />
    </AdvisorProfileCard>
  );
}
