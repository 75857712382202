import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import { RegistrationFormData } from './schema';
import { EmailCheckerResponse, SignupParams, SignupResponse } from './types';
import { GenericResponse } from 'services/apiTypes';

export * from './types';
export * from './schema';

export const signupAPI = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    checkEmailVerified: builder.mutation<GenericResponse<undefined>, String>({
      query: email => ({
        url: `/check-email/${email}/status`,
        method: 'POST',
      }),
    }),
    sendVerifyEmail: builder.mutation<any, String>({
      query: email => ({
        url: 'approveUser',
        method: 'POST',
        body: {
          resendVerification: 'resendVerification',
          email: email,
        },
      }),
    }),
    emailChecker: builder.mutation<EmailCheckerResponse, String>({
      query: email => ({ url: `check-email/${email}` }),
    }),
    signup: builder.mutation<
      SignupResponse,
      { extra?: Object; form: RegistrationFormData } | SignupParams
    >({
      query: data => {
        // const extra = data.extra;
        if ('form' in data) {
          const form = data.form;
          const campaignCodes = form.campaignCodes.map(code => ({
            text: code,
            isReferral: false,
          }));
          const body = {
            ...form,
            workEmail: form.email,
            campaignCodes: campaignCodes,
            userType: 'Adviser',
          };

          return {
            url: 'registerUser',
            method: 'POST',
            body: body,
          };
        } else {
          return {
            url: 'registerUser',
            method: 'POST',
            body: data,
          };
        }
      },
    }),
  };
};
