// Import necessary components
import { AdvisorProfilePage } from '../../pages/AdvisorProfilePage/Loadable';
import { ProfilePlusPage } from '../../pages/ProfilePlusPage/Loadable';
import { SignupPage } from '../../pages/SignupPage/Loadable';
import { GetPortfoplusPage } from '../../pages/GetPortfoplus/Loadable';
// import { DemoPage } from '../../pages/DemoPage/Loadable';
import { DebugPage } from '../../pages/DebugPage/Loadable';
import { NFCCardPage } from '../../pages/NFCCardPage/Loadable';
import { Route } from '../types';
import { Login } from 'app/frames/Login/Loadable';
import Landing from 'app/components/Landing/Landing2';
import { AppDLRedirect } from 'app/pages/AppDLRedircet/Loadable';

export const PUBLIC_ROUTES: Route[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'profile-plus',
    element: <ProfilePlusPage />,
  },
  {
    path: 'adviser/:adviserId',
    element: <AdvisorProfilePage />,
  },
  {
    path: 'debug',
    element: <DebugPage />,
  },
  {
    path: 'get-portfoplus',
    element: <GetPortfoplusPage />,
  },
  {
    path: 'landing',
    element: <Landing />,
  },
  {
    path: 'register',
    element: <SignupPage />,
  },
  // {
  //   path: 'demo',
  //   element: <DemoPage />,
  // },
  {
    path: 'ca/:id',
    element: <NFCCardPage />,
  },
  {
    path: '/dl/app',
    element: <AppDLRedirect />,
  },
  {
    path: '/',
    element: <ProfilePlusPage />,
  },
];
