import { memo, useRef } from 'react';
import { Box } from '@mui/material';
import {
  Editor as ReadOnlyEditor,
  ContentBlock,
  // convertFromHTML,
} from 'draft-js';

import 'draft-js/dist/Draft.css';
import './Editor.css';
import createStyles from 'draft-js-custom-styles';
import { formatEditorStateValue } from 'utils/validators/validators';

type EditorProps = {
  defaultValue: string | Object;
  readOnly?: boolean | undefined;
};

const Editor = ({ readOnly, defaultValue }: EditorProps) => {
  const editorRef = useRef(null);
  const editorState = formatEditorStateValue(defaultValue);

  const { customStyleFn, styles } = createStyles(
    ['font-size', 'color'],
    'CUSTOM_',
  );

  const getBlockStyle = (block: ContentBlock) => {
    switch (block.getType()) {
      case 'align-left':
      case 'align-center':
      case 'align-right':
        return `DraftEditor-${block.getType()}`;
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return '';
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: 'fit-content',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .DraftEditor-root': {
            padding: 0,
            boxShadow: 'unset',
          },
        }}
      >
        <ReadOnlyEditor
          ref={editorRef}
          editorState={editorState}
          blockStyleFn={(block: ContentBlock) => getBlockStyle(block)}
          customStyleFn={customStyleFn}
          readOnly={readOnly}
        />
      </Box>
    </>
  );
};

export default memo(Editor);
