import {
  createTheme,
  type PaletteMode,
  type ThemeOptions,
} from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const getThemeOptions = (mode: PaletteMode): ThemeOptions => {
  const style = getComputedStyle(document.body);
  const defaultTheme = createTheme();
  const shadows = defaultTheme.shadows;
  shadows[1] = style.getPropertyValue('--shadow-l1');
  shadows[2] = style.getPropertyValue('--shadow-l2');
  return {
    palette: {
      mode,
      primary: {
        main: style.getPropertyValue('--color-primary-main'),
        dark: style.getPropertyValue('--color-primary-dark'),
        light: style.getPropertyValue('--color-primary-light'),
        contrastText: style.getPropertyValue('--color-primary-contrast-text'),
      },
      secondary: {
        main: style.getPropertyValue('--color-secondary-main'),
        dark: style.getPropertyValue('--color-secondary-dark'),
        light: style.getPropertyValue('--color-secondary-light'),
        contrastText: style.getPropertyValue('--color-secondary-contrast-text'),
      },
      error: {
        main: style.getPropertyValue('--color-error-main'),
        dark: style.getPropertyValue('--color-error-dark'),
        light: style.getPropertyValue('--color-error-light'),
        contrastText: style.getPropertyValue('--color-error-contrast-text'),
      },
      warning: {
        main: style.getPropertyValue('--color-warning-main'),
        dark: style.getPropertyValue('--color-warning-dark'),
        light: style.getPropertyValue('--color-warning-light'),
        contrastText: style.getPropertyValue('--color-warning-contrast-text'),
      },
      info: {
        main: style.getPropertyValue('--color-info-main'),
        dark: style.getPropertyValue('--color-info-dark'),
        light: style.getPropertyValue('--color-info-light'),
        contrastText: style.getPropertyValue('--color-info-contrast-text'),
      },
      success: {
        main: style.getPropertyValue('--color-success-main'),
        dark: style.getPropertyValue('--color-success-dark'),
        light: style.getPropertyValue('--color-success-light'),
        contrastText: style.getPropertyValue('--color-success-contrast-text'),
      },
      ...(mode === 'light'
        ? {
            text: {
              primary: style.getPropertyValue('--text-primary-light'),
              secondary: style.getPropertyValue('--text-secondary-light'),
            },
          }
        : {
            text: {
              primary: style.getPropertyValue('--text-primary-dark'),
              secondary: style.getPropertyValue('--text-secondary-dark'),
            },
          }),
    },
    typography: {
      fontFamily: ['Roboto', 'Noto Sans TC', 'Noto Sans SC', 'sans-serif'].join(
        ',',
      ),
      h1: {
        fontSize: '96px',
        fontWeight: 300,
        letterSpacing: '-1.5px',
      },
      h2: {
        fontSize: '60px',
        fontWeight: 300,
        letterSpacing: '-0.5px',
      },
      h3: {
        fontSize: '48px',
        fontWeight: 400,
      },
      h4: {
        fontSize: '34px',
        fontWeight: 500,
        letterSpacing: '0.25px',
      },
      h5: {
        fontSize: '24px',
        fontWeight: 500,
      },
      h6: {
        fontSize: '20px',
        fontWeight: 600,
        letterSpacing: '0.15px',
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.1px',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.15px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.15px',
      },
      caption: {
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.4px',
        marginTop: '24px',
        marginBottom: '8px',
      },
    },
    shadows,
    breakpoints: {
      values: {
        xs: Number(style.getPropertyValue('--breakpoint-xs')),
        sm: Number(style.getPropertyValue('--breakpoint-sm')),
        md: Number(style.getPropertyValue('--breakpoint-md')),
        lg: Number(style.getPropertyValue('--breakpoint-lg')),
        xl: Number(style.getPropertyValue('--breakpoint-xl')),
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            minHeight: '48px',
            borderRadius: '12px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: style.getPropertyValue('--background-paper-light'),
            borderRadius: '16px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 0,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: '72px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '20px',
          },
        },
      },
    },
  };
};

export default getThemeOptions;
