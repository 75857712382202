import { api } from '../../../services/api';
import { TimeslotType, Timeslot } from 'services/timeslot/types';
import Loading from '../Loading/Loading';
import {
  TimeslotSelectorPanelLayout,
  TimeslotSelectorPanelProps,
} from './TimeslotSelectorPanel.types';
import { useContext, useState } from 'react';
// import VSpace from '../VSpace';
import { GenericResponse } from 'services/apiTypes';
import { Appointment } from 'services/appointment/types';
import { useIsTablet } from 'hooks/useBreakpoints/useBreakpoints';
import TimeslotSelectorSwiper from './TimeslotSelectorLayout/TimeslotSelectorSwiper';
import TimeslotSelectorVScroll from './TimeslotSelectorLayout/TimeslotSelectorVScroll';
import { AuthenticatedUserContext } from 'hooks/useAuthenticatedUser/useAuthenticatedUser';
import { trackInteractionEvent, trackMilestoneEvent } from 'hooks/hooks';

function TimeslotSelectorPanel(props: TimeslotSelectorPanelProps) {
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const [triggerNextAppointment] = api.endpoints.newAppointment.useMutation();
  const [triggerRearrangeAppointment] =
    api.endpoints.rearrangeAppointment.useMutation();
  const { data: timeslots } = api.useAvailableTimeslotQuery(
    TimeslotType.CustomerService,
  );
  const [timeslotLoading, setTimeslotLoading] = useState<undefined | string>(
    undefined,
  );
  const isTablet = useIsTablet();

  if (!timeslots?.data) {
    return <Loading />;
  }

  const { currentAppointment, onAppointmentUpdate, size = { xs: 12 } } = props;

  async function handleMakeAppointment(timeslot: Timeslot) {
    trackInteractionEvent({
      type: 'Button Clicked',
      event_properties: {
        Name: 'Register',
        'Content Name': 'Appointment',
        Event: timeslot._id,
        'Event Name': timeslot.eventName?.['zh-Hant-HK'],
      },
    });

    setTimeslotLoading(timeslot._id);
    try {
      let res: GenericResponse<Appointment[]>;
      if (currentAppointment) {
        res = await triggerRearrangeAppointment({
          appointment: currentAppointment._id,
          timeslot: timeslot._id,
        }).unwrap();

        trackMilestoneEvent({
          type: 'Linker Registered',
          event_properties: {
            Success: res.success,
            'Event Name': timeslot.eventName?.['zh-Hant-HK'],
            Event: timeslot._id,
            'User Id': AuthenticatedUser.user?._id,
          },
        });
      } else {
        res = await triggerNextAppointment(timeslot._id).unwrap();
      }
      if (res.success && res.data) {
        onAppointmentUpdate(res.data);
        await AuthenticatedUser.reAuth();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeslotLoading(undefined);
    }
  }

  if (props.layout === TimeslotSelectorPanelLayout.swiper) {
    return (
      <TimeslotSelectorSwiper
        timeslots={timeslots.data}
        timeslotLoading={timeslotLoading}
        handleMakeAppointment={handleMakeAppointment}
      />
    );
  } else {
    return (
      <TimeslotSelectorVScroll
        timeslots={timeslots.data}
        timeslotLoading={timeslotLoading}
        handleMakeAppointment={handleMakeAppointment}
        sizeProfile={{ xs: 12, sm: 6, md: 4, lg: 3 }}
      />
    );
  }
}

export default TimeslotSelectorPanel;
