import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Team } from './teams.types';
export * from './teams.types';

export const teams = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    getTeams: builder.query<Team[] | undefined, void>({
      query: () => ({
        url: '/teams',
        method: 'GET',
      }),
    }),
  };
};
