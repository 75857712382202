import { Selectable } from './Animations/Selectable';

export enum AnimationType {
  Selectable = 'Selectable',
}

export function defaultAnimation(type: AnimationType) {
  switch (type) {
    case AnimationType.Selectable:
      return Selectable;
  }
}
