import { EditProfilePage } from '../../pages/EditProfilePage/Loadable';
import { Route } from '../types';

export const DASHBOARD_ROUTES: Route[] = [
  {
    path: '/my-details',
    index: true,
    element: <EditProfilePage />,
    isMenuCollapsable: true,
    showTitle: true,
    titlei18nKey: 'EditProfilePage.Title',
  },
];
