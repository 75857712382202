export const COLORS = [
  '#61BD6D',
  '#1ABC9C',
  '#54ACD2',
  '#2C82C9',
  '#9365B8',
  '#475577',
  '#CCCCCC',
  '#41A85F',
  '#00A885',
  '#3D8EB9',
  '#2969B0',
  '#553982',
  '#28324E',
  '#000000',
  '#F7DA64',
  '#FBA026',
  '#EB6B56',
  '#E25041',
  '#A38F84',
  '#EFEFEF',
  '#FFFFFF',
  '#FAC51C',
  '#F37934',
  '#D14841',
  '#B8312F',
  '#7C706B',
  '#D1D5D8',
];
