import { SliderThumb } from '@mui/material/Slider';
import styles from './CustomThumbComponent.module.css';

interface CustomThumbComponentProps extends React.HTMLAttributes<unknown> {}

export default function CustomThumbComponent({
  children,
  ...otherProps
}: CustomThumbComponentProps) {
  return (
    <SliderThumb className={styles.thumb} {...otherProps}>
      {children}
      <span className="custom-thumb-bar" />
      <span className="custom-thumb-bar" />
      <span className="custom-thumb-bar" />
    </SliderThumb>
  );
}
