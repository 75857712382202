import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import Editor from 'app/components/Editor/Editor';
import FormField from 'app/components/FormField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './EditProfileForm.module.css';
import type { EditProfileFormProps } from './formPorps';
import TurorialTips from '../../TurorialTips';
import Container from 'app/components/Container';
import type { CustomQuestion } from 'types';
import { AuthenticatedUserContext } from 'hooks/useAuthenticatedUser/useAuthenticatedUser';
import { useContext } from 'react';
import PaidFeatureAlert from 'app/components/PaidFeatureAlert/PaidFeatureAlert';
import { PaidFeatureAlertType } from 'app/components/PaidFeatureAlert/PaidFeatureAlert.types';
import DiamondTrailing from 'app/components/DiamondTrailing/DiamondTrailing';

function Extra({ back, handleSave }: EditProfileFormProps) {
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const { t } = useTranslation();
  const { control, setValue, getValues, formState, setError } =
    useFormContext();

  const getEditorValue = () => getValues('teamDescription');

  const setEditorValue = val => {
    setValue('teamDescription', JSON.stringify(val));
  };

  const customQuestionValidator = () => {
    const customQuestions: CustomQuestion[] | undefined =
      getValues('customQuestions');
    if (!customQuestions) return true;
    const check = customQuestions.map((q, index) => {
      if (q.question && q.question.includes('.')) {
        setError(`customQuestions.${index}.question`, {
          type: 'input',
          message: '問題不能包含 . ',
        });
        return false;
      } else {
        return true;
      }
    });

    if (check.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSaveOnClick = async () => {
    const validateResult = customQuestionValidator();
    if (validateResult) {
      handleSave!();
    }
  };

  const isFreeUser = AuthenticatedUser.isFreeUser;
  const showTeamPlanAlert = !AuthenticatedUser.teams || AuthenticatedUser.teams.length === 0;


  return (
    <div className={styles.container}>
      <PaidFeatureAlert type={PaidFeatureAlertType.info} plan={isFreeUser}>
        <Container
          sx={{
            padding: { xs: '16px', md: '24px' },
            marginBottom: '16px',
          }}
        >
          <>
            <FormField
              type="invertedCheckbox"
              label={t('AdviserProfile.CustomQuestions')}
              name="shouldHideProfilePlusCustomQuestions"
              control={control}
              readOnly={isFreeUser}
              trailing={<DiamondTrailing preventDefault={true} focusingAdviserId={true} />}
              hideSpacing
            />
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.Question1')}
                name="customQuestions.0.question"
                control={control}
                readOnly={isFreeUser}
                placeholder={t('AdviserProfile.Placeholder.CustomQuestion')}
              />
            </Box>
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.Answer1')}
                name="customQuestions.0.answers"
                control={control}
                readOnly={isFreeUser}
                placeholder={t(
                  'AdviserProfile.Placeholder.CustomQuestionAnswer',
                )}
              />
            </Box>
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.Question2')}
                name="customQuestions.1.question"
                control={control}
                readOnly={isFreeUser}
                placeholder={t('AdviserProfile.Placeholder.CustomQuestion')}
              />
            </Box>
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.Answer2')}
                name="customQuestions.1.answers"
                control={control}
                readOnly={isFreeUser}
                placeholder={t(
                  'AdviserProfile.Placeholder.CustomQuestionAnswer',
                )}
              />
            </Box>
          </>
        </Container>
      </PaidFeatureAlert>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          marginBottom: '16px',
        }}
      >
        <>
          <>
            <TurorialTips label={t('AdviserProfile.StepTips.Team')} />
            <Box className={styles.fieldsGroup}>
              <Box>
                <PaidFeatureAlert
                  type={isFreeUser ? PaidFeatureAlertType.info : PaidFeatureAlertType.team}
                  plan={isFreeUser || showTeamPlanAlert}
                >
                  <FormField
                    type="checkbox"
                    label={t('AdviserProfile.ShowTeamPage')}
                    name="shouldShowProfilePlusTeamPage"
                    control={control}
                    readOnly={isFreeUser || showTeamPlanAlert}
                    trailing={<DiamondTrailing preventDefault={true} focusingAdviserId={true} />}
                  />
                </PaidFeatureAlert>
              </Box>
              <Box>
                <PaidFeatureAlert
                  type={isFreeUser ? PaidFeatureAlertType.info : PaidFeatureAlertType.team}
                  plan={isFreeUser || showTeamPlanAlert}
                >
                  <FormField
                    type="checkbox"
                    label={t('AdviserProfile.ShowTeamLogo')}
                    name="shouldShowProfilePlusTeamLogo"
                    control={control}
                    readOnly={isFreeUser || showTeamPlanAlert}
                    trailing={<DiamondTrailing preventDefault={true} focusingAdviserId={true} />}
                  />
                </PaidFeatureAlert>
              </Box>
            </Box>
            <Editor
              title={t('AdviserProfile.TeamIntroduction')}
              type="team"
              defaultValue={getEditorValue}
              setContent={setEditorValue}
              readOnly={false}
            />
            <Typography
              className={styles.subtitle}
              variant="caption"
              fontWeight="500"
            >
              📱 {t('AdviserProfile.TeamContacts')}
            </Typography>
            <Box
              className={styles.fieldsGroup}
              sx={{
                marginTop: '8px',
              }}
            >
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.TeamPage')}
                  name="teamPage"
                  control={control}
                  readOnly={false}
                />
              </Box>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.InstagramAccount')}
                  name="teamInstagram"
                  control={control}
                  readOnly={false}
                />
              </Box>
            </Box>
            <Box className={styles.fieldsGroup}>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.FacebookLink')}
                  name="teamFacebook"
                  control={control}
                  readOnly={false}
                />
              </Box>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.LinkedinLink')}
                  name="teamLinkedin"
                  control={control}
                  readOnly={false}
                />
              </Box>
            </Box>
            <FormField
              type="text"
              label={t('AdviserProfile.WechatId')}
              name="teamWechat"
              control={control}
              readOnly={false}
            />
          </>

          <Box
            className={styles.footer}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={back}
            >
              {t('Global.Back')}
            </Button>
            <Button
              variant="contained"
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={handleSaveOnClick}
            >
              {t('Global.Save')}
            </Button>
          </Box>
        </>
      </Container>
    </div>
  );
}

export default Extra;
