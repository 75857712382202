import { Box, Grid } from '@mui/material';
import type { ResponsiveContentLayout } from './ResponsiveWrapper.types';
import { useCapableNode } from 'hooks/useBreakpoints/useBreakpoints';
import type { ResponsiveReactNodeProfile } from 'hooks/useBreakpoints/useBreakpoints.types';

function ResponsiveWrapper(props: ResponsiveContentLayout): JSX.Element {
  const { children, sizeProfiles = {}, sx } = props;
  const { sm = 12, md = 12, lg = 6, xl = [7, 5] } = sizeProfiles;
  const capableNode = useCapableNode(
    Array.isArray(children)
      ? { sm: children }
      : (children as ResponsiveReactNodeProfile),
  );

  if (capableNode.length === 1) {
    return <Box sx={sx}>{capableNode[0]}</Box>;
  }

  return (
    <Box sx={sx}>
      <Grid container>
        {capableNode.map((child, index) => {
          const sizeProfile: { [key: string]: number } = {
            sm:
              Array.isArray(sm) && sm.length > index
                ? sm[index]
                : (sm as number),
            md:
              Array.isArray(md) && md.length > index
                ? md[index]
                : (md as number),
            lg:
              Array.isArray(lg) && lg.length > index
                ? lg[index]
                : (lg as number),
            xl:
              Array.isArray(xl) && xl.length > index
                ? xl[index]
                : (xl as number),
          };
          return (
            <Grid item {...sizeProfile} key={index}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default ResponsiveWrapper;
