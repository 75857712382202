import { Dialog, Drawer } from '@mui/material';
import { useIsMobile } from 'hooks/useBreakpoints/useBreakpoints';
import {
  BottomOrDialogProps,
  StandardBottomSheetPaper,
  StandardDialogPaper,
} from './BottomOrDialog.types';

function BottomOrDialog({ open, children, onClose }: BottomOrDialogProps) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        PaperProps={StandardBottomSheetPaper}
      >
        {children}
      </Drawer>
    );
  } else {
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        PaperProps={StandardDialogPaper}
      >
        {children}
      </Dialog>
    );
  }
}

export default BottomOrDialog;
