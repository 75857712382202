import spacerStyles from './Space.module.css';
import { Box } from '@mui/material';

export default function VSpace({
  size,
}: // 1-6 or xxpx
{
  size: 1 | 2 | 3 | 4 | 5 | 6 | string;
}) {
  if (typeof size === 'string') {
    return <Box style={{ height: size, width: size }} />;
  } else {
    return (
      <div
        className={[spacerStyles.space, spacerStyles[`space-${size}`]].join(
          ' ',
        )}
      />
    );
  }
}
