import { Avatar } from '@mui/material';
// import AVATAR_FAMALE_1 from 'app/assets/icons/avatar-female-1.svg';
// import AVATAR_FAMALE_2 from 'app/assets/icons/avatar-female-2.svg';
// import AVATAR_MALE_1 from 'app/assets/icons/avatar-male-1.svg';
// import AVATAR_MALE_2 from 'app/assets/icons/avatar-male-2.svg';

// import AVATAR_MAN_1 from 'app/assets/avatar/man1.png';
// import AVATAR_WONMAN_1 from 'app/assets/avatar/woman1.png';
// import AVATAR_MAN_3 from 'app/assets/avatar/man3.png';
// import AVATAR_WONMAN_3 from 'app/assets/avatar/woman3.png';
// import AVATAR_MAN_4 from 'app/assets/avatar/man4.png';
// import AVATAR_WONMAN_6 from 'app/assets/avatar/woman6.png';
// import AVATAR_MAN_7 from 'app/assets/avatar/man7.png';
// import AVATAR_WONMAN_7 from 'app/assets/avatar/woman7.png';
// import LK_AVATAR_FAMALE_1 from 'app/assets/icons/avatar-female-1.svg';
// import LK_AVATAR_FAMALE_2 from 'app/assets/icons/avatar-female-2.svg';
// import LK_AVATAR_MALE_1 from 'app/assets/icons/avatar-male-1.svg';
// import LK_AVATAR_MALE_2 from 'app/assets/icons/avatar-male-2.svg';

const avatarStyle = {
  '.MuiBox-root &': {
    width: 124,
    height: 124,
  },
  '.MuiListItemAvatar-root &': {
    width: 64,
    height: 64,
  },
};
export const customAvatarStyle = {
  ...avatarStyle,
  fontSize: '75px',
  fontWeight: 800,
};

export function getTargetAvatar(val = '', name = 'Guest', size = {}) {
  let sx = {};
  if (Object.keys(size).length > 0) {
    sx = { ...size };
  } else {
    sx = {
      width: 'fit-content',
      height: 'fit-content',
      ...avatarStyle,
    };
  }

  // 因為不知道在 RTK 中怎麼定義 PromiseLike，暫行先在此取值
  if (
    val.startsWith('http') ||
    val.startsWith('https') ||
    val.includes('download-url')
  ) {
    return <Avatar id="avatar-1" src={val} sx={sx} />;
  } else {
    const targetAvatar = getAvatarList(sx).find(avatar => avatar.val === val);
    if (targetAvatar) {
      return targetAvatar.content;
    } else {
      return <Avatar id="avatar-3" sx={sx} />;
    }
  }
}
export function getAvatarList(sx = {}) {
  return [
    {
      content: <Avatar src="/img/avatar/man1.png" alt="man1" sx={sx} />,
      val: 'img/avatar/man1.png',
      selectable: true,
    },
    {
      content: <Avatar src={'/img/avatar/woman1.png'} alt="man2" sx={sx} />,
      val: 'img/avatar/woman1.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/man3.png" alt="man3" sx={sx} />,
      val: 'img/avatar/man3.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/woman3.png" alt="woman3" sx={sx} />,
      val: 'img/avatar/woman3.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/man4.png" alt="man4" sx={sx} />,
      val: 'img/avatar/man4.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/woman6.png" alt="woman6" sx={sx} />,
      val: 'img/avatar/woman6.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/man7.png" alt="man7" sx={sx} />,
      val: 'img/avatar/man7.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/woman7.png" alt="woman7" sx={sx} />,
      val: 'img/avatar/woman7.png',
      selectable: true,
    },
    {
      content: <Avatar src="/img/avatar/female-1.svg" alt="female-1" sx={sx} />,
      val: 'img/avatar/female-1.svg',
      selectable: false,
    },
    {
      content: <Avatar src="/img/avatar/female-2.svg" alt="female-2" sx={sx} />,
      val: 'img/avatar/female-2.svg',
      selectable: false,
    },
    {
      content: <Avatar src="/img/avatar/male-1.svg" alt="male-1" sx={sx} />,
      val: 'img/avatar/male-1.svg',
      selectable: false,
    },
    {
      content: <Avatar src="/img/avatar/male-2.svg" alt="male-2" sx={sx} />,
      val: 'img/avatar/male-2.svg',
      selectable: false,
    },
    // {
    //   content: (
    //     <Avatar sx={{ ...customAvatarStyle, bgcolor: 'var(--color-blue-300)' }}>
    //       {name}
    //     </Avatar>
    //   ),
    //   val: 'custom_avatar_1',
    // },
  ];
}

export function getAvatarURL(val: string | undefined) {
  if (!val) return '';
  if (val.startsWith('https')) {
    return val;
  } else if (val.startsWith('img')) {
    return '/' + val;
  } else {
    return val;
  }
}
