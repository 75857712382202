import { SelectOption } from 'app/components/FormField';
import styles from 'app/components/Clients/ClientFilters/ColorCircleIcon.module.css';
import CircleIcon from '@mui/icons-material/Circle';
import { ProfileThemeColorType, PersonalityType } from 'types';
import { Box } from '@mui/material';
import { getMainColorFromThemeColor } from 'app/components/AdvisorProfile/AdvisorProfile';

const RoundedSquareIcon = ({ val }: { val: string }) => (
  <Box className={styles.roundedSquareIcon}>{val}</Box>
);

export const MAIN_LANGUAGES_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Cantonese',
    value: 'zh-Hant-HK',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Mandarin',
    value: 'zh',
  },
  {
    label: 'Korean',
    value: 'kr',
  },
  {
    label: 'Japanese',
    value: 'jp',
  },
  {
    label: 'Thai',
    value: 'th',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'italian',
    value: 'it',
  },
  {
    label: 'Hokkien',
    value: 'fuki',
  },
  {
    label: 'Hakka',
    value: 'hakka',
  },
  {
    label: 'Teochew',
    value: 'chiuchow',
  },
  {
    label: 'Other Chinese',
    value: 'other-chinese',
  },
  {
    label: 'Other foreign languages',
    value: 'others',
  },
];

export const MY_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Gental',
    value: 'gentle',
  },
  {
    label: 'Sporty',
    value: 'sporty',
  },
  {
    label: 'Family',
    value: 'family',
  },
  {
    label: 'Logical',
    value: 'logical',
  },
  {
    label: 'Philosophical',
    value: 'philosophical',
  },
  {
    label: 'Senior',
    value: 'senior',
  },
  {
    label: 'Youthful',
    value: 'youthful',
  },
];

export const WORK_ATTIUDE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Passionate',
    value: 'passionate',
  },
  {
    label: 'Professional',
    value: 'professional',
  },
  {
    label: 'Cautious',
    value: 'cautious',
  },
  {
    label: 'Perfectionist',
    value: 'perfectionist',
  },
  {
    label: 'Responsible',
    value: 'responsible',
  },
  {
    label: 'Not Hard-sell',
    value: 'not-hard-sell',
  },
  {
    label: 'High Efficiency',
    value: 'high-efficiency',
  },
];

export const TREAT_PEOPLE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Approachable',
    value: 'approachable',
  },
  {
    label: 'Artless',
    value: 'artless',
  },
  {
    label: 'talkative',
    value: 'talkative',
  },
  {
    label: 'Tidy',
    value: 'tidy',
  },
  {
    label: 'Smiley',
    value: 'smiley',
  },
  {
    label: 'Punctual',
    value: 'punctual',
  },
  {
    label: 'Good Listener',
    value: 'good-listener',
  },
];

export const LIFE_ATTITUDE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Emotional',
    value: 'emotional',
  },
  {
    label: 'Selfless',
    value: 'selfless',
  },
  {
    label: 'Hedonistic',
    value: 'hedonistic',
  },
  {
    label: 'Lively',
    value: 'lively',
  },
  {
    label: 'Buddhist Lifestyle',
    value: 'buddha-like',
  },
  {
    label: 'Low profile',
    value: 'low-profile',
  },
  {
    label: 'YOLO',
    value: 'yolo',
  },
];

export const SENIORITY_OPTIONS: SelectOption<string>[] = [
  {
    label: '1-5 years',
    value: '1-5',
  },
  {
    label: '6-10 years',
    value: '6-10',
  },
  {
    label: '11-20 years',
    value: '11-20',
  },
  {
    label: 'Over 20 years',
    value: '>20',
  },
];

export const MY_FOCUS_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Life',
    value: 'life',
  },
  {
    label: 'Critical Illness',
    value: 'ci',
  },
  {
    label: 'High-end Medical',
    value: 'high-end-medical',
  },
  {
    label: 'VHIS',
    value: 'vhis',
  },
  {
    label: 'Saving',
    value: 'saving',
  },
  {
    label: 'Accident',
    value: 'accident',
  },
  {
    label: 'MPF',
    value: 'mpf',
  },
  {
    label: 'ILAS',
    value: 'ilas',
  },
  {
    label: 'Motor',
    value: 'car',
  },
  {
    label: 'Annuity',
    value: 'life-annuity',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Travel',
    value: 'travel',
  },
  {
    label: 'QDAP',
    value: 'qdap',
  },
  {
    label: 'Education Fund',
    value: 'edu',
  },
  {
    label: 'General Insurance',
    value: 'general',
  },
  {
    label: 'Business Insurance',
    value: 'business',
  },
  {
    label: 'Coupon Plan',
    value: 'coupon-plan',
  },
  {
    label: 'Passive Income',
    value: 'passive-income',
  },
  {
    label: 'U-Life',
    value: 'u-life',
  },
];

export const MAIN_SERVICES_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Children Protection',
    value: 'children-protection',
  },
  {
    label: 'Asset Management',
    value: 'asset-management',
  },
  {
    label: 'Policy Consolidation',
    value: 'policy-consolidation',
  },
  {
    label: 'Claim',
    value: 'claim',
  },
  {
    label: 'Medical Card',
    value: 'medical-card',
  },
  {
    label: 'Risk Assessment',
    value: 'risk-assessment',
  },
  {
    label: 'Family Heritage',
    value: 'family-heritage',
  },
  {
    label: 'Orphan Policy',
    value: 'orphan-policy',
  },
  {
    label: 'Estate Planning',
    value: 'estate-planning',
  },
  {
    label: 'Financial Planning',
    value: 'financial-planning',
  },
  {
    label: 'Retirement Plan',
    value: 'retirement-plan',
  },
  {
    label: 'Prem Financing',
    value: 'prem-financing',
  },
];

export const QUALIFICATIONS_OPTIONS: SelectOption<string>[] = [
  {
    label: 'MDRT',
    value: 'mdrt-mdrt',
  },
  {
    label: 'COT',
    value: 'mdrt-cot',
  },
  {
    label: 'TOT',
    value: 'mdrt-tot',
  },
  {
    label: 'CFP',
    value: 'mdrt-cfp',
  },
  {
    label: 'AFP',
    value: 'mdrt-afp',
  },
  {
    label: 'CWM',
    value: 'mdrt-cwm',
  },
  {
    label: 'CFA',
    value: 'mdrt-cfa',
  },
  {
    label: 'CPA',
    value: 'mdrt-cpa',
  },
  {
    label: 'FRM',
    value: 'mdrt-frm',
  },
  {
    label: 'MBA',
    value: 'mdrt-mba',
  },
];

export const HOBBIES_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Have Fun',
    value: 'have-fun',
  },
  {
    label: 'Travel',
    value: 'travel',
  },
  {
    label: 'Movie',
    value: 'movie',
  },
  {
    label: 'Shopping',
    value: 'shopping',
  },
  {
    label: 'Basketball',
    value: 'basketball',
  },
  {
    label: 'Football',
    value: 'football',
  },
  {
    label: 'Computer',
    value: 'computer',
  },
  {
    label: 'Photography',
    value: 'photography',
  },
  {
    label: 'Run&Hike',
    value: 'run-hike',
  },
  {
    label: 'Ball Games',
    value: 'ball-games',
  },
  {
    label: 'Water Sports',
    value: 'water-sports',
  },
  {
    label: 'Skiing',
    value: 'skiing',
  },
  {
    label: 'Wine tasting',
    value: 'wine-tasting',
  },
  {
    label: 'Music',
    value: 'music',
  },
  {
    label: 'Video Games',
    value: 'video-games',
  },
  {
    label: 'Car',
    value: 'car',
  },
  {
    label: 'Bringe-watching',
    value: 'binge-watching',
  },
  {
    label: 'Investment',
    value: 'investment',
  },
  {
    label: 'Singing Karaoke',
    value: 'karaoke',
  },
  {
    label: 'Mahjong',
    value: 'mahjong',
  },
  {
    label: 'Chit-chatting',
    value: 'chit-chat',
  },
  {
    label: 'Trendy Info',
    value: 'trendy-info',
  },
  {
    label: 'Sleeping',
    value: 'sleeping',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Reading',
    value: 'reading',
  },
  {
    label: 'Art',
    value: 'art',
  },
  {
    label: 'Pet',
    value: 'pet',
  },
  {
    label: 'Beauty & Slim',
    value: 'beauty-slim',
  },
  {
    label: 'Kids & Family',
    value: 'kids-family',
  },
  {
    label: 'Anime',
    value: 'anime',
  },
  {
    label: 'Cat Lover',
    value: 'cat-lover',
  },
  {
    label: 'Dog Lover',
    value: 'dog-lover',
  },
  {
    label: 'Yoga',
    value: 'yoga',
  },
  {
    label: 'Watch',
    value: 'watch',
  },
  {
    label: 'Dance',
    value: 'dance',
  },
  {
    label: 'Fitness',
    value: 'fitness',
  },
  {
    label: 'Lego',
    value: 'lego',
  },
  {
    label: 'Hi-Fi',
    value: 'hi-fi',
  },
  {
    label: 'Handbag',
    value: 'handbag',
  },
];

export const THEME_OPTIONS: SelectOption<ProfileThemeColorType>[] = [
  {
    icon: (
      <CircleIcon
        sx={{ color: getMainColorFromThemeColor('blue') }}
        className={`${styles.colorCircleIcon} ${styles.xl}`}
      />
    ),
    value: 'blue',
  },
  {
    icon: (
      <CircleIcon
        sx={{ color: getMainColorFromThemeColor('orange') }}
        className={`${styles.colorCircleIcon} ${styles.xl}`}
      />
    ),
    value: 'orange',
  },
  {
    icon: (
      <CircleIcon
        sx={{ color: getMainColorFromThemeColor('violet') }}
        className={`${styles.colorCircleIcon} ${styles.xl}`}
      />
    ),
    value: 'violet',
  },
  {
    icon: (
      <CircleIcon
        sx={{ color: getMainColorFromThemeColor('earth') }}
        className={`${styles.colorCircleIcon} ${styles.xl}`}
      />
    ),
    value: 'earth',
  },
  {
    icon: (
      <CircleIcon
        sx={{ color: getMainColorFromThemeColor('green') }}
        className={`${styles.colorCircleIcon} ${styles.xl}`}
      />
    ),
    value: 'green',
  },
];

export const PERSONALITY_DIALOG_OPTIONS: SelectOption<PersonalityType>[] = [
  {
    icon: <RoundedSquareIcon val="D" />,
    label: 'Outgoing, Task oriented',
    value: 'D',
  },
  {
    icon: <RoundedSquareIcon val="I" />,
    label: 'Outgoing, People oriented',
    value: 'I',
  },
  {
    icon: <RoundedSquareIcon val="S" />,
    label: 'Reserved, People oriented',
    value: 'S',
  },
  {
    icon: <RoundedSquareIcon val="C" />,
    label: 'Reserved, Task oriented',
    value: 'C',
  },
];
