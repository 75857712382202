import { useIsTablet } from 'hooks/useBreakpoints/useBreakpoints';
import { Step, Stepper, StepButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type EditProfileStepperProps = {
  currentStep: number;
  completed: { [key: number]: boolean };
  setCompleted: (val: { [key: number]: boolean }) => void;
  setActiveStep: (val: number) => void;
};

const steps = [
  {
    label: 'PROFILE',
  },
  {
    label: 'ABOUTME',
  },
  {
    label: 'BUSINESS',
  },
  {
    label: 'SOCIAL',
  },
  {
    label: 'EXTRA',
  },
];

export default function EditProfileStepper({
  currentStep,
  completed,
  setCompleted,
  setActiveStep,
}: EditProfileStepperProps) {
  const { t } = useTranslation();

  const isTablet = useIsTablet();
  const orientation = isTablet ? 'horizontal' : 'vertical';

  const handleStepCompleted = idx => {
    const completedArr = new Array(idx + 1).fill(true);
    const completedObj = Object.assign({}, completedArr);
    setCompleted(completedObj);
    setActiveStep(idx);
  };
  return (
    <Stepper
      activeStep={currentStep}
      orientation={orientation}
      nonLinear
      sx={{
        width: { xs: '100%', md: 'auto' },
        '.MuiStepConnector-line': {
          minHeight: { xs: 'auto', md: '65px' },
        },
      }}
    >
      {steps.map((step, idx) => (
        <Step key={step.label} completed={completed[idx]}>
          <StepButton
            onClick={() => handleStepCompleted(idx)}
            sx={{
              '.MuiStepLabel-labelContainer, .MuiStepIcon-root': {
                color: 'var(--color-grey-transparent)',
              },
              '.MuiStepLabel-labelContainer .Mui-active': {
                color: 'var(--color-text-primary)',
              },
              '.Mui-completed': {
                color: 'var(--color-blue-300)',
              },
            }}
          >
            {!isTablet && t(`AdviserProfile.${step.label}`)}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}
