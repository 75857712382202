import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { ReactComponent as AdvisorProfileBottomGraphics } from 'app/assets/images/advisor_profile/main_bottom.svg';
import { ReactComponent as AdvisorProfileMainTopGraphics } from 'app/assets/images/advisor_profile/main_top.svg';
import { ReactComponent as AdvisorProfileOtherTopGraphics } from 'app/assets/images/advisor_profile/other_top.svg';
import styles from './AdvisorProfileCard.module.css';
import { Advisor } from 'types';
import VSpace from '../VSpace';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';

export type AdvisorProfileCardProps = {
  advisor?: Advisor;
  isMainCard?: boolean;
  heading?: string;
  loading?: boolean;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  onShowShortcut?: () => void;
  isDemo: boolean;
  isFreeUser: boolean;
};

export default function AdvisorProfileCard({
  isMainCard = false,
  heading,
  loading = false,
  children,
  footer,
}: AdvisorProfileCardProps) {
  const { color2 } = useAdvisorProfileTheme();

  const cardSx = {
    position: 'relative',
    width: '320px',
    maxWidth: '100%',
    height: '600px',
    maxHeight: '88vh',
    borderTopRightRadius: '90px',
    borderBottomLeftRadius: '90px',
    overflow: 'hidden',
    margin: '0 auto',
    borderRadius: '3px 90px 0px 90px',
    boxShadow: '5px 4px 8px 3px hsla(0,0%,42.7%,.5)',
  };
  if (loading) {
    return (
      <Skeleton variant="rectangular" width={300} height={600} sx={cardSx} />
    );
  }
  return (
    <Paper elevation={3} sx={cardSx}>
      {isMainCard ? (
        // <div
        //   className="styles.mainTopGraphics"
        //   style={{
        //     width: '100%',
        //     height: '100%',
        //     backgroundImage: 'url("/images/main_top.svg"',
        //     backgroundSize: '100%',
        //   }}
        // ></div>
        <AdvisorProfileMainTopGraphics
          className={styles.mainTopGraphics}
          width={undefined}
          height={undefined}
        />
      ) : (
        <AdvisorProfileOtherTopGraphics
          className={styles.otherTopGraphics}
          width={undefined}
          height={undefined}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isMainCard && heading && (
          <>
            <Box
              sx={{
                paddingTop: '18px',
                paddingX: '24px',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: color2,
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  marginRight: 10,
                  marginTop: 3,
                }}
              />
              <Typography variant="h5" fontWeight={600} sx={{ color: color2 }}>
                {heading}
              </Typography>
            </Box>
            <VSpace size={3} />
          </>
        )}
        <Box
          sx={{
            padding: '0px 24px 0px 24px',
            height: isMainCard ? '88vh' : '60vh',
            maxHeight: isMainCard ? undefined : '440px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '6px',
              marginRight: '-32px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '3px',
            },
          }}
        >
          {children}
        </Box>
        {footer && (
          <Box sx={{ paddingX: '32px', paddingBottom: '16px' }}> {footer} </Box>
        )}
      </Box>

      <AdvisorProfileBottomGraphics
        className={styles.bottomGraphics}
        width={undefined}
        height={undefined}
      />
    </Paper>
  );
}
