/* 
	Potentially we'll need to include the other functionalities supported 
	by web/utils/download.js in the future
 */
export default function useDownload(): {
  download: (url: string, name: string) => Promise<void>;
  downloadFile: (file: File) => Promise<void>;
} {
  const download = async (url: string, name: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
  };

  const downloadFile = async (file: File) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = file.name;
    link.click();
    link.remove();
  };

  return { download, downloadFile };
}
