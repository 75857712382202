import { Grid, Box } from '@mui/material';
import { useAdvisorProfileTheme } from '../AdvisorProfile/AdvisorProfileThemeProvider';
import Backbutton from '../Backbutton/Backbutton';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export function ProfilePlusTopNav({ isSelfCard }: { isSelfCard: boolean }) {
  const { mainColor } = useAdvisorProfileTheme();
  const target = `${process.env.REACT_APP_PFPLUS_FRONTEND_URL}/home`;

  return (
    <Box
      sx={{
        width: 'calc(100% - 24px)',
        position: 'absolute',
        top: '12px',
        left: '12px',
        right: '12px',
        zIndex: 100,
      }}
    >
      <Box sx={{ margin: 'auto' }}>
        <Grid
          sx={{ width: '100%', margin: 'auto', position: 'relative' }}
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
        >
          {isSelfCard ? (
            <Backbutton sx={{ color: mainColor }} target={target} />
          ) : (
            <Box></Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
