import { UploadMediaParams } from './apiTypes';
import { Buffer } from 'buffer';

export const getUploadMediaFormData = ({
  mediaConfig,
  file,
}: UploadMediaParams) => {
  const formData = new FormData();
  formData.append(
    'policy',
    Buffer.from(JSON.stringify(mediaConfig.policy)).toString('base64'),
  );
  formData.append('key', mediaConfig.policy.conditions[1]['key']);
  formData.append(
    'Content-Type',
    mediaConfig.policy.conditions[2]['Content-Type'],
  );
  formData.append(
    'x-amz-algorithm',
    mediaConfig.policy.conditions[4]['x-amz-algorithm'],
  );
  formData.append(
    'x-amz-credential',
    mediaConfig.policy.conditions[5]['x-amz-credential'],
  );
  formData.append('x-amz-date', mediaConfig.policy.conditions[6]['x-amz-date']);
  if (
    mediaConfig.policy.conditions.length >= 8 &&
    mediaConfig.policy.conditions[7]['acl']
  ) {
    formData.append('acl', mediaConfig.policy.conditions[7]['acl']);
  }
  formData.append('x-amz-signature', mediaConfig.signature);
  formData.append('file', file);
  return formData;
};

export const getDownloadUrl = (key: string) => {
  return `${process.env.REACT_APP_CSP_IMG_SRC}/` + key;
};
