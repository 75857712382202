import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint, useTheme } from '@mui/material/styles';
import { ResponsiveReactNodeProfile } from './useBreakpoints.types';

export function useIsMobile(): boolean {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile;
}

export function useIsTablet(): boolean {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return isTablet;
}

export function useIsMiniScreen(): boolean {
  const theme = useTheme();
  const isMiniScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return isMiniScreen;
}

export function useDownXl(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xl'));
}

export function useDown(size: number | Breakpoint): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(size));
}

export function useMostFitDownSize(): string {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownXl = useMediaQuery(theme.breakpoints.down('xl'));

  if (isDownSm) {
    return 'sm';
  } else if (isDownMd) {
    return 'md';
  } else if (isDownLg) {
    return 'lg';
  } else if (isDownXl) {
    return 'xl';
  }
  return 'xl'; // Default to 'xl' if no other breakpoints match
}

export function useCapableSize(): string {
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  if (isUpXl) {
    return 'xl';
  } else if (isUpLg) {
    return 'lg';
  } else if (isUpMd) {
    return 'md';
  } else if (isUpSm) {
    return 'sm';
  }
  return 'sm'; // Default to 'sm' if no other breakpoints match
}

export function useHeightDifference(
  targetHeight: number,
  windowHeight?: number,
): number {
  return (windowHeight || window.innerHeight) - targetHeight;
}

export function useCapableNode(
  profile: ResponsiveReactNodeProfile,
): React.ReactNode[] {
  const useCapableSizeValue = useCapableSize();

  const order = ['xl', 'lg', 'md', 'sm'];

  let targetNode: React.ReactNode[] | undefined = profile[useCapableSizeValue];

  let index = order.indexOf(useCapableSizeValue);

  while (!targetNode && index < order.length) {
    index++;
    targetNode = profile[order[index]];
  }

  if (!targetNode) {
    throw new Error(
      `No capable node found for this breakpoint: ${useCapableSizeValue}`,
    );
  }

  return targetNode || [];
}
