import { Timeslot } from 'services/api';
import { Appointment } from 'services/appointment/types';

export interface TimeslotSelectorPanelProps {
  currentAppointment?: Appointment;
  size?: {
    xl?: number;
    md?: number;
    xs?: number;
  };
  layout?: TimeslotSelectorPanelLayout;
  onAppointmentUpdate: (newAppointment: Appointment[]) => void;
}

export enum TimeslotSelectorPanelLayout {
  swiper = 'swiper',
  hScroll = 'hScroll',
  vScroll = 'vScroll',
}

export interface TimeslotSelectorLayoutProps {
  timeslots: Timeslot[];
  timeslotLoading: string | undefined;
  handleMakeAppointment: (t: Timeslot) => void;
}
