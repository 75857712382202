import { IconButton, Menu, MenuItem, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useState } from 'react';
import {
  languageToLabel,
  SupportedLanguage,
  supportedLanguages,
} from 'locales/i18n';

// NOTE: This button can potentially be extended to support
// text label button
export default function LanguageButton({ sx, color }: { sx?: SxProps<Theme>, color?: string }) {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: SupportedLanguage) => {
    i18n.changeLanguage(language);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const languagesToSwitch: SupportedLanguage[] = supportedLanguages
    .filter(language => language !== i18n.language)
    .map(language => language as SupportedLanguage);

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClick} sx={sx}>
        <LanguageIcon style={{ color: color || undefined }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {languagesToSwitch.map(language => (
          <MenuItem key={language} onClick={() => setLanguage(language)}>
            {languageToLabel(language)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
