import { Box, Typography } from '@mui/material';
import BulbIcon from './Icons/BulbIcon';
import CloseIcon from '@mui/icons-material/Close';

export type TurorialTipsProps = {
  label: string;
  description?: string;
  closeTurorail?: () => void;
  handleModalOpen?: () => void;
};

export default function TurorialTips({
  label,
  description,
  closeTurorail,
  handleModalOpen,
}: TurorialTipsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        padding: '14px 16px',
        border: '1px solid var(--color-turorail-border-purple)',
        borderRadius: '12px',
        background: 'var(--color-turorail-background-purple)',
        marginBottom: { xs: '16px', md: '24px' },
      }}
    >
      <BulbIcon
        sx={{
          alignSelf: 'baseline',
        }}
      />
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          sx={{
            color: '#000',
            marginLeft: '12px',
          }}
        >
          {label}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            sx={{
              color: '#000',
              marginLeft: '12px',
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 'auto',
          cursor: 'pointer',
        }}
      >
        {handleModalOpen && (
          <Typography
            variant="subtitle2"
            color="#000"
            sx={{
              lineHeight: 1,
            }}
            onClick={handleModalOpen}
          >
            WATCH THE VIDEO
          </Typography>
        )}
        {closeTurorail && (
          <CloseIcon
            sx={{
              fontSize: '20px',
              marginLeft: '12px',
            }}
            onClick={closeTurorail}
          />
        )}
      </Box>
    </Box>
  );
}
