import { useContext } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import AdvisorProfileCard, {
  AdvisorProfileCardProps,
} from './AdvisorProfileCard';
import VSpace from '../VSpace';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import {
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
} from '@mui/icons-material';
import { ReactComponent as LineIcon } from '../../../icons/line.svg';
import { ReactComponent as WeChatIcon } from '../../../icons/wechat.svg';

import { formatOpenUrl, formatPhoneNumber } from 'utils/formatters';
import { getReferralLink, ReferralMedium } from 'utils/referral';
import { useTranslation } from 'react-i18next';
import useCopy from 'hooks/useCopy';
import { Advisor } from 'types';
import RoundButton from '../RoundButton/RoundButton';
import PoweredBy from '../PoweredBy/PoweredBy';
import { isMobileBrowser } from 'utils/Device/getDeviceOS';
import { AuthenticatedUserContext } from 'hooks/useAuthenticatedUser/useAuthenticatedUser';
import DiamondTrailing from '../DiamondTrailing/DiamondTrailing';
import HSpace from '../HSpace';

export function ContactField({
  icon,
  text,
  href,
  onClick,
  trailing,
}: {
  icon: React.ReactNode;
  text: string;
  href?: string;
  onClick?: (...args: any[]) => any;
  trailing?: React.ReactNode;
}) {
  // const { mainColor } = useAdvisorProfileTheme();
  if (!!href && !!onClick) throw new Error('Cannot have both href and onClick');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {icon && <Box> {icon}</Box>}
      {href && (
        <Link
          href={href}
          target="_blank"
          rel="noreferrer"
          variant="subtitle2"
          color="inherit"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Link>
      )}
      {!!onClick && (
        <Link
          component="button"
          variant="subtitle2"
          color="inherit"
          onClick={onClick}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Link>
      )}
      {!href && !onClick && (
        <Typography
          variant="subtitle2"
          fontWeight={500}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Typography>
      )}
      {trailing}
    </Box>
  );
}

export function ContactWechatField({
  icon,
  wechatUrl,
  wechatId,
  copyWechatId,
  openWechatMiniProgram,
  trailing,
}: {
  icon: React.ReactNode;
  wechatUrl?: string;
  wechatId?: string;
  copyWechatId?: (...args: any[]) => any;
  openWechatMiniProgram?: (...args: any[]) => any;
  trailing?: React.ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {icon}
      {wechatUrl ? (
        isMobileBrowser() ? (
          <Box display="flex" flexDirection="column">
            <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
              <Button
                variant="outlined"
                color="primary"
                onClick={openWechatMiniProgram}
                style={{ padding: '2px 6px', minWidth: 'unset' }}
              >
                {t('AdviserProfile.WechatQrCodeInstruction.add-wechat')}
              </Button>
              <HSpace size={1} />
              {trailing}
            </Box>
            {wechatId && (
              <Typography fontSize={14} style={{ marginTop: 5 }}>
                {t('AdviserProfile.WechatQrCodeInstruction.wechat-id-colon')}
                <strong onClick={copyWechatId} style={{ cursor: 'pointer' }}>
                  {wechatId}
                </strong>
              </Typography>
            )}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignContent="center" alignItems="center">
              <QRCodeSVG
                style={{ maxWidth: '75px', paddingBottom: 5 }}
                width="100%"
                height="100%"
                value={wechatUrl}
              />
              <HSpace size={2} />
              {trailing}
            </Box>
            <Typography fontSize={14}>
              {' '}
              {t(
                'AdviserProfile.WechatQrCodeInstruction.open-wechat-and-scan-above-qr-code',
              )}
            </Typography>
            {wechatId && (
              <Typography
                fontSize={14}
                onClick={copyWechatId}
                style={{ cursor: 'pointer' }}
              >
                {t('AdviserProfile.WechatQrCodeInstruction.or-add-wechat-id')}{' '}
                {wechatId}
              </Typography>
            )}
          </Box>
        )
      ) : wechatId ? (
        <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
          <Link
            component="button"
            variant="subtitle2"
            color="inherit"
            onClick={copyWechatId}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {wechatId}
          </Link>
          <HSpace size={1} />
          {trailing}
        </Box>
      ) : (
        <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            -
          </Typography>
          {trailing}
        </Box>
      )}
    </Box>
  );
}

function AdvisorProfileContactCard({
  advisor,
  isDemo,
  isFreeUser,
}: {
  advisor: Advisor;
  isDemo: boolean;
  isFreeUser: boolean;
}) {
  const { t } = useTranslation();
  const { mainColor } = useAdvisorProfileTheme();
  const {
    adviserInfo: {
      phoneNumber,
      phoneNumber2,
      whatsapp,
      workEmail,
      facebookLink,
      instagram,
      linkedinLink,
      realLinkedinLink,
      wechatId,
      wechatUrl,
      lineId,
    },
  } = advisor;

  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const shouldShowLinkerProFeatures =
    !isFreeUser ||
    AuthenticatedUser.isAdviserProfileOwner(advisor.adviserInfo._id);

  const whatsAppUrl = `https://api.whatsapp.com/send?phone=${whatsapp}&text=${encodeURIComponent(
    t('AdviserProfile.WhatsAppMessage', {
      link: getReferralLink({
        medium: ReferralMedium.LINK,
        target: advisor.adviserInfo._id,
      }),
    }),
  )}`;
  const copyLineId = useCopy({
    textToCopy: lineId,
    successMessage: t('AdviserProfile.AppMessageCopied'),
  });

  const copyWechatId = useCopy({
    textToCopy: wechatId,
    successMessage: t(
      'AdviserProfile.WechatQrCodeInstruction.wechat-id-copied',
    ),
  });

  const openWechatMiniProgram = () => {
    let wechatMiniProgramUrl = `weixin://dl/business/?appid=wxd066ac768dfb3ef8&path=pages/qrCode/qrCode&query=userId%3D${
      advisor.adviserInfo._id
    }&env_version=${
      process.env.REACT_APP_STAGE === 'production' ? 'release' : 'trial'
    }`;
    window.open(wechatMiniProgramUrl, '_blank');
  };

  const contactButtonSize = 20;
  return (
    <AdvisorProfileCard
      isDemo={isDemo}
      isFreeUser={isFreeUser}
      heading={t('AdviserProfile.CONTACT')}
      footer={<PoweredBy />}
    >
      <ContactField
        icon={
          <RoundButton
            size={contactButtonSize}
            color={mainColor}
            component="box"
            iconBuilder={sx => <PhoneIcon sx={sx} />}
          />
        }
        text={formatPhoneNumber(phoneNumber) || '-'}
        href={phoneNumber ? `tel:${phoneNumber}` : undefined}
      />
      {phoneNumber2 && (
        <>
          <VSpace size={2} />
          <ContactField
            icon={
              <RoundButton
                size={contactButtonSize}
                color={mainColor}
                component="box"
                iconBuilder={sx => <PhoneIcon sx={sx} />}
              />
            }
            text={formatPhoneNumber(phoneNumber2) || '-'}
            href={phoneNumber2 ? `tel:${phoneNumber2}` : undefined}
          />
        </>
      )}
      <VSpace size={2} />
      <ContactField
        icon={
          <RoundButton
            size={contactButtonSize}
            color={mainColor}
            component="box"
            iconBuilder={sx => <WhatsAppIcon sx={sx} />}
          />
        }
        text={
          shouldShowLinkerProFeatures ? formatPhoneNumber(whatsapp) || '-' : '-'
        }
        trailing={
          <DiamondTrailing focusingAdviserId={advisor.adviserInfo._id} />
        }
        href={shouldShowLinkerProFeatures && whatsapp ? whatsAppUrl : undefined}
      />
      <VSpace size={2} />
      <ContactField
        icon={
          <RoundButton
            size={contactButtonSize}
            color={mainColor}
            component="box"
            iconBuilder={sx => <EmailIcon sx={sx} />}
          />
        }
        text={workEmail || '-'}
        href={workEmail ? `mailto:${workEmail}` : undefined}
      />
      <VSpace size={2} />

      <ContactWechatField
        icon={
          <RoundButton
            size={contactButtonSize}
            color={mainColor}
            component="box"
            iconBuilder={sx => <WeChatIcon style={sx} />}
          />
        }
        wechatId={shouldShowLinkerProFeatures ? wechatId : ''}
        wechatUrl={shouldShowLinkerProFeatures ? wechatUrl : ''}
        copyWechatId={copyWechatId}
        openWechatMiniProgram={openWechatMiniProgram}
        trailing={
          <DiamondTrailing focusingAdviserId={advisor.adviserInfo._id} />
        }
      />

      <VSpace size={2} />
      <ContactField
        icon={
          <RoundButton
            size={contactButtonSize}
            color={mainColor}
            component="box"
            iconBuilder={sx => <LineIcon style={sx} />}
          />
        }
        text={lineId || '-'}
        onClick={lineId ? copyLineId : undefined}
      />
      {(facebookLink || instagram || realLinkedinLink || linkedinLink) && (
        <>
          <VSpace size={3} />
          <Typography variant="subtitle2" fontWeight={500}>
            📱 {t('AdviserProfile.SocialMedia')}
          </Typography>
          {facebookLink && (
            <>
              <VSpace size={2} />
              <ContactField
                icon={
                  <RoundButton
                    size={contactButtonSize}
                    color={mainColor}
                    component="box"
                    iconBuilder={sx => <FacebookIcon sx={sx} />}
                  />
                }
                text={facebookLink}
                href={formatOpenUrl(facebookLink)}
              />
            </>
          )}
          {instagram && (
            <>
              <VSpace size={2} />
              <ContactField
                icon={
                  <RoundButton
                    size={contactButtonSize}
                    color={mainColor}
                    component="box"
                    iconBuilder={sx => <InstagramIcon sx={sx} />}
                  />
                }
                text={instagram}
                href={`https://www.instagram.com/${instagram}`}
              />
            </>
          )}
          {realLinkedinLink && (
            <>
              <VSpace size={2} />
              <ContactField
                icon={
                  <RoundButton
                    size={contactButtonSize}
                    color={mainColor}
                    component="box"
                    iconBuilder={sx => <LinkedInIcon sx={sx} />}
                  />
                }
                text={realLinkedinLink}
                href={formatOpenUrl(realLinkedinLink)}
              />
            </>
          )}
          {linkedinLink && (
            <>
              <VSpace size={2} />
              <ContactField
                icon={
                  <RoundButton
                    size={contactButtonSize}
                    color={mainColor}
                    component="box"
                    iconBuilder={sx => <LanguageIcon sx={sx} />}
                  />
                }
                text={linkedinLink}
                href={formatOpenUrl(linkedinLink)}
              />
            </>
          )}
        </>
      )}
      <VSpace size={5} />
    </AdvisorProfileCard>
  );
}

export default function WrappedAdvisorProfileContactCard({
  advisor,
  isDemo,
  isFreeUser,
}: AdvisorProfileCardProps) {
  if (!advisor) {
    return (
      <AdvisorProfileCard loading isDemo={isDemo} isFreeUser={isFreeUser} />
    );
  }
  return (
    <AdvisorProfileContactCard
      advisor={advisor}
      isDemo={isDemo}
      isFreeUser={isFreeUser}
    />
  );
}
