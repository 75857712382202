import { useRef } from 'react';
import ProfilePicture from '@dsalvagni/react-profile-picture';
import '@dsalvagni/react-profile-picture/dist/ProfilePicture.css';
import './UploadProfilePicture.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Compressor from 'compressorjs';
import styles from './AvatarUploadDialog.module.css';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  useLazyGetMediaUploadConfigQuery,
  useUploadMediaMutation,
} from 'services/api';
import { getDownloadUrl } from 'services/utils';

interface UploadAvatarDialogProps {
  advisorId?: string;
  open: boolean;
  setSelectedValue: (value: string) => void;
  onClose: () => void;
  closeSelectAvatarModal: () => void;
}

const UploadAvatarDialog = ({
  advisorId,
  open,
  onClose,
  setSelectedValue,
  closeSelectAvatarModal,
}: UploadAvatarDialogProps) => {
  const profilePictureRef = useRef<any>(null);
  const [uploadMedia] = useUploadMediaMutation();
  const [getMediaUploadConfig] = useLazyGetMediaUploadConfigQuery();

  const closeAllModal = () => {
    onClose();
    closeSelectAvatarModal();
  };

  const submit = async () => {
    const profilePicture = profilePictureRef.current;
    if (profilePicture) {
      const blob = await (
        await fetch(profilePicture.getImageAsDataUrl(1))
      ).blob();

      const result: string = await new Promise(resolve => {
        new Compressor(blob, {
          // convertSize: 0.2 * 1024 * 1024, // max size = 200 KB,
          quality: 1,
          maxWidth: 380,
          maxHeight: 2048,
          mimeType: 'image/jpeg',
          success: async compressedFile => {
            if (!advisorId) return;
            const mediaUploadConfig = await getMediaUploadConfig({
              type: 'avatar',
              resourceId: advisorId,
              contentType: 'image/jpeg',
              contentLength: compressedFile.size.toString(),
              filename: 'avatar',
            }).unwrap();

            await uploadMedia({
              file: compressedFile,
              mediaConfig: mediaUploadConfig,
            });

            const downloadUrl = getDownloadUrl(
              mediaUploadConfig.policy.conditions[1].key,
            );
            resolve(downloadUrl);
          },
          error(compressError) {
            alert('An error occurred in compressing the image');
            console.error(compressError.message);
            resolve('');
          },
        });
      });

      if (result) {
        setSelectedValue(result);
      } else {
        setSelectedValue('');
      }

      closeAllModal();
    }
  };

  return (
    <Dialog
      className={styles.uploadAvatarDialog}
      open={open}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: { xs: '328px', md: '600px' },
          boxShadow: 'var(--shadow-l7)',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        <CloudUploadOutlinedIcon
          sx={{
            mr: 1,
          }}
        />
        Upload
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '8px 4px',
        }}
      >
        <ProfilePicture
          ref={profilePictureRef}
          frameFormat="circle"
          useHelper={true}
          cropSize={300}
          messages={{
            DEFAULT: 'Drop photo here or Tap to select',
            DRAGOVER: 'Drop your photo',
            INVALID_FILE_TYPE: 'Only images allowed',
            INVALID_IMAGE_SIZE: 'Invalid image size',
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: '42px 24px 16px',
        }}
      >
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={submit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadAvatarDialog;
