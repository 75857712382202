import { EditorState, SelectionState } from 'draft-js';

export function getLastSelection(editorState: EditorState): SelectionState {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const focusKey = selectionState.getFocusKey();
  const anchorOffset = selectionState.getAnchorOffset();
  const focusOffset = selectionState.getFocusOffset();
  return SelectionState.createEmpty(anchorKey).merge({
    anchorOffset,
    focusKey,
    focusOffset,
  }) as SelectionState;
}
