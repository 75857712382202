import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./DraftEditorToolBarButtonWrapper.module.css";

function DraftEditorToolBarButtonWrapper({
    active = false,
    children }: {
        children: ReactNode,
        active?: false | true | undefined
    }) {
    let classes;
    if (active) {
        classes = clsx(styles['draft-js-toolbar-button'], styles['active']);
    }
    else if (active === false) {
        classes = clsx(styles['draft-js-toolbar-button'], styles['inactive']);
    } else {
        classes = clsx(styles['draft-js-toolbar-button'])
    }
    return (
        <div className={classes}>
            {children}
        </div>
    )

}



export default DraftEditorToolBarButtonWrapper;