import { Box, CircularProgress } from '@mui/material';
import { LoaderProps } from './Loader.types';

function Loader(props: LoaderProps) {
  const {
    container,
    loader = <CircularProgress size={24} />,
    children,
    loading,
    builder,
  } = props;
  const _sx = container?.sx ?? {
    display: 'block',
    width: '100%',
  };

  if (loading) {
    return (
      <Box className={container?.className} sx={_sx}>
        {builder?.(loader) ?? loader}
      </Box>
    );
  } else {
    return (
      <Box className={container?.className} sx={_sx}>
        {builder?.(children) ?? children}
      </Box>
    );
  }
}

export default Loader;
