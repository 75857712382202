import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useDown } from 'hooks/useBreakpoints/useBreakpoints';
export function PaidFeatureAlertDialogHeadDraft({
  onClose,
}: {
  onClose?: () => void;
}) {
  const isDonwSM = useDown('sm');
  if (isDonwSM) {
    return (
      <Box width="100%">
        <Box
          sx={{
            margin: 'auto',
            backgroundColor: '#3C3C43',
            opacity: 0.3,
            height: '5px',
            width: '62px',
            borderRadius: '2.5px',
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        ></Box>
      </Box>
    );
  } else if (onClose) {
    return (
      <Box textAlign={'right'} marginRight={'20px'}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
    );
  } else {
    return <></>;
  }
}

export default PaidFeatureAlertDialogHeadDraft;
