import { ReactElement } from 'react';
import { Box } from '@mui/material';

export type ContainerdProps = {
  children: ReactElement | string;
  sx?: object;
  style?: string;
};

export default function Container({ children, sx, style }: ContainerdProps) {
  return (
    <Box
      className={style}
      sx={{
        backgroundColor: 'var(--background-paper-light)',
        borderRadius: '16px',
        boxShadow: 'var(--shadow-l3)',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
