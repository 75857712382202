import React, { useContext, useEffect, useState } from 'react';
import PageLayout from 'app/components/PageLayout/PageLayout';
import { Box, Button, Typography } from '@mui/material';

import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
// import { redirectTo } from 'app/route/utils/redirectTo';
import { AuthenticatedUserContext } from 'hooks/hooks';
import Loading from 'app/components/Loading/Loading';
import ResponsiveWrapper from 'app/components/PageLayout/ResponsiveWrapper/ResponsiveWrapper';
import { PlaceholderMd } from './Placeholder/Placeholder';
import { PlaceholderSM } from './Placeholder/sm';
import ProfileForm from 'app/components/RegistrationFlow/ProfileForm/ProfileForm';
import { FormProvider, useForm } from 'react-hook-form';
import {
  RegistrationFormData,
  RegistrationFormSchema,
} from 'services/signup/signup';
import { yupResolver } from '@hookform/resolvers/yup';
import VSpace from 'app/components/VSpace';
import { api, subscriptionAPI } from 'services/api';
// import { toast } from 'react-toastify';
import { ConvertionStep } from './index.types';
import SeminarForm from 'app/components/RegistrationFlow/SeminarForm/SeminarForm';
import { TimeslotSelectorPanelLayout } from 'app/components/TimeslotSelectorPanel/TimeslotSelectorPanel.types';
import { redirectTo } from 'app/route/route';
import { useTranslation } from 'react-i18next';
import { trackMilestoneEvent } from 'hooks/useTracking/useTracking';
import { formatTrackingProps } from 'utils/validators/formator/trackingProps';

function ConvertionPage({ next }: { next?: string | React.ReactElement }) {
  const [step, setStep] = useState<ConvertionStep>(ConvertionStep.profile);
  const navigate = useNavigate();
  const [logoutUser] = api.endpoints.logoutUser.useMutation();

  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const methods = useForm<any>({
    resolver: yupResolver(RegistrationFormSchema),
    defaultValues: RegistrationFormSchema.getDefault(),
  });

  const [updateAdviserProfile] = api.useUpdateAdviserProfileMutation();
  const [subscribeLinkerFree] =
    subscriptionAPI.useSubscribeLinkerFreeMutation();
  const [t] = useTranslation();

  useEffect(() => {
    methods.setValue('email', AuthenticatedUser.user?.email);
    methods.setValue('password', '1xxxxxxxx');
    methods.setValue('name', AuthenticatedUser.user?.name);
    methods.setValue(
      'phoneNumber',
      AuthenticatedUser.user?.phoneNumber ?? '12345678',
    );
    methods.setValue('gender', AuthenticatedUser.profile?.adviserInfo?.gender);
    methods.setValue(
      'ageGroup',
      AuthenticatedUser.profile?.adviserInfo?.ageGroup,
    );
    methods.setValue(
      'company',
      AuthenticatedUser.profile?.adviserInfo?.company,
    );
    methods.setValue(
      'position',
      AuthenticatedUser.profile?.adviserInfo?.jobTitle,
    );
    methods.setValue(
      'seniority',
      AuthenticatedUser.profile?.adviserInfo?.seniority,
    );
  }, [AuthenticatedUser, methods]);

  if (AuthenticatedUser.isLoading.profile || AuthenticatedUser.isLoading.user) {
    return <Loading />;
  }

  async function goNext() {
    await logoutUser();
    redirectTo(navigate, next || '/get-portfoplus');
  }

  async function onReg(values: RegistrationFormData) {
    const v = Object(values);
    v._id = AuthenticatedUser.user?._id;
    delete v.password;
    const regRes = await updateAdviserProfile(v).unwrap();
    trackMilestoneEvent({
      type: 'Linker Converted',
      event_properties: {
        Success: regRes.success,
        ...formatTrackingProps(v),
      },
    });
    if (regRes.success) {
      await subscribeLinkerFree();
      AuthenticatedUser.updateProfile(regRes.data);
      redirectTo(navigate, '/get-portfoplus');
    }
  }

  const getElement = () => {
    switch (step) {
      case ConvertionStep.profile:
        return (
          <Box padding="24px">
            <Typography variant="h5" textAlign="left">
              {t("ConvertionPage.title")}
            </Typography>
            <VSpace size={2} />
            <FormProvider {...methods}>
              {<ProfileForm onReg={onReg} />}
            </FormProvider>
          </Box>
        );

      case ConvertionStep.seminar:
        return (
          <Box maxWidth="100vw">
            <SeminarForm
              layout={TimeslotSelectorPanelLayout.swiper}
              onSuccess={appointments => {
                goNext();
              }}
            />
            <VSpace size={2} />
            <Box className={styles.paddingX} sx={{ textAlign: 'center' }}>
              <Button sx={{ color: '#999', opacity: 0.5 }} onClick={goNext}>
                {t('Appointment.skip-collecting-card-for-now')}
              </Button>
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box className={styles.Registration}>
      <PageLayout
        options={{
          helmet: true,
          fullWidth: true,
          fullHeight: true,
          noPadding: true,
          maxWidth: 'xl',
        }}
      >
        <ResponsiveWrapper>
          {{
            sm: [<PlaceholderSM />, getElement()],
            lg: [<PlaceholderMd />, getElement()],
          }}
        </ResponsiveWrapper>
      </PageLayout>
    </Box>
  );
}

export default ConvertionPage;
