import { TimeslotType } from 'services/timeslot/types';

export interface Appointment {
  _id: string;
  user: string;
  timeslot: {
    eventName?: { [key: string]: string };
    eventDescription?: { [key: string]: string };
    address: { [key: string]: string };
    datetime: Date;
    status: string;
    type: TimeslotType;
    _id: string;
  };
  feedback: boolean;
  attended: boolean;
  status: string;
  updatedBy: string;
  updatedAt: Date;
  createdBy: string;
  createDate: Date;
}

export enum AppointmentUpdateMode {
  new = 'new',
  rearrange = 'rearrange',
  cancel = 'cancel',
}
