import { SelectOption } from './FormField';
import Tag, { TagProps } from './Tag';
import styles from './Tags.module.css';

export default function Tags({
  tags,
  options,
  onChange,
  tagProps,
  randomizeColor = false,
}: {
  tags: string[];
  options?: SelectOption<any>[];
  onChange?: (tags: string[]) => void;
  tagProps?: TagProps;
  randomizeColor?: boolean;
}) {
  // Display only tags
  if (!options || !onChange) {
    return (
      <div className={styles.tagsContainer}>
        {tags?.map((value, index) => (
          value && value.trim() &&
          <Tag
            key={`${index}_${value}`}
            label={value}
            randomizeColor={randomizeColor}
            {...tagProps}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.tagsContainer}>
      {options?.map(({ label, value }, index) => (
        <Tag
          key={`${index}_${value}`}
          label={label}
          active={tags.includes(value)}
          onClick={() => {
            if (tags.includes(value)) {
              onChange(tags.filter((v: string) => v !== value));
            } else {
              onChange([...tags, value]);
            }
          }}
          randomizeColor={randomizeColor}
          {...tagProps}
        />
      ))}
    </div>
  );
}
