export interface BottomOrDialogProps {
  children: React.ReactNode;
  open: boolean;
  onClose: (event?) => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const StandardBottomSheetPaper = {
  elevation: 0,
  style: { borderRadius: '12px 12px 0px 0px' },
};

export const StandardDialogPaper = {
  elevation: 0,
  style: { borderRadius: '12px', maxHeight: '80vh' },
};
