import { Box, Typography } from '@mui/material';
import AdvisorProfileCard, {
  AdvisorProfileCardProps,
} from './AdvisorProfileCard';
import VSpace from '../VSpace';
import Tags from '../Tags';
import { useTranslation } from 'react-i18next';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';

export default function AdvisorProfileAboutMeCard({
  advisor,
  isDemo,
  isFreeUser
}: AdvisorProfileCardProps) {
  const { t } = useTranslation();
  const { mainColor, tagStyle } = useAdvisorProfileTheme();

  if (!advisor) {
    return <AdvisorProfileCard loading isDemo={isDemo} isFreeUser={isFreeUser} />;
  }
  const {
    adviserInfo: {
      hobbies,
      customizedHobby,
      familiarPlaces,
      secondarySchool,
      university,
      mainLanguages,
      otherHashtags,
    },
  } = advisor;

  let translatedHobbies = hobbies?.map(hobby => t(`AdviserProfile.Hobby.${hobby}`)) ?? [];
  if (customizedHobby) {
    translatedHobbies.push(customizedHobby);
  }
  return (
    <AdvisorProfileCard heading={t('AdviserProfile.SOCIAL')} isDemo={isDemo} isFreeUser={isFreeUser}>
      <Typography variant="subtitle2" fontWeight={500}>
        ⚽ {t('AdviserProfile.Hobbies')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={translatedHobbies}
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      <VSpace size={3} />
      <Typography variant="subtitle2" fontWeight={500}>
        ✈️ {t('AdviserProfile.FamiliarPlaces')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={familiarPlaces ?? []}
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      <VSpace size={3} />
      <Typography variant="subtitle2" fontWeight={500}>
        🎓 {t('AdviserProfile.School')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={[...secondarySchool ?? [], ...university ?? []]}
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      <VSpace size={3} />
      <Typography variant="subtitle2" fontWeight={500}>
        📣 {t('AdviserProfile.MainLanguages')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={
          mainLanguages?.map(language =>
            t(`AdviserProfile.Language.${language}`),
          ) ?? []
        }
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      <VSpace size={3} />
      <Box gap={1}>
        <Typography variant="subtitle2" fontWeight={500}>
          #️⃣
        </Typography>
        <VSpace size={1} />
        <Tags
          tags={otherHashtags ?? []}
          tagProps={{ sx: tagStyle, variant: 'outlined' }}
        />
      </Box>
      <VSpace size={5} />
    </AdvisorProfileCard>
  );
}
