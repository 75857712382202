import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { isFrameCheck } from 'utils/Frame/isFrameCheck';
import { postMessageToParent } from 'utils/Frame/postMessageToParent';
import { postNextToParent } from 'utils/Frame/postNextToParent';
import { url, replaceDoubleSlashes } from 'utils/validators/validators';

function redirect(
  n: NavigateFunction,
  component: string,
  state?: Record<string, any>,
): void {
  if (component.startsWith('http://') || component.startsWith('https://')) {
    if (url.isSelfhosted(component)) {
      n(component, { state: { complete: true, ...state } });
    } else {
      window.location.href = component;
    }
    return;
  } else {
    n(component, { state: { complete: true, ...state } });
  }
}

export function redirectTo(
  navigate: NavigateFunction,
  component?: string | React.ReactElement,
  state?: Record<string, any>,
  forceRedirect?: boolean,
): React.ReactElement | void {
  if (!component) return;
  if (typeof component === 'string') {
    const isFrame = isFrameCheck();
    if (forceRedirect) {
      redirect(navigate, component, state);
    } else if (isFrame) {
      const saerch = new URLSearchParams(window.location.search);
      if (saerch.get('nextAction') && saerch.get('nextData')) {
        postNextToParent();
      } else {
        postMessageToParent({
          success: true,
          code: 'REDIRECT',
          message: 'Redirecting to the specified component',
          data: component,
        });
      }
    } else {
      redirect(navigate, component, state);
    }
  } else if (typeof redirectTo === 'object') {
    return component;
  } else {
    return;
  }
}

export function redirectToPFP(navigate: NavigateFunction, path: string): void {
  const PFP_URL = process.env.REACT_APP_PFPLUS_FRONTEND_URL;
  const endpoint = replaceDoubleSlashes(`${PFP_URL}/${path}`);

  if (url.validate(endpoint)) {
    redirectTo(navigate, endpoint);
  }
}
