import { Box, IconButton } from '@mui/material';
import style from './RoundButton.module.css';
import React from 'react';
import clsx from 'clsx';
export default function RoundButton(props: {
  onClick?: (e) => void;
  size?: number;
  color?: string;
  component?: 'button' | 'box';
  href?: string;
  iconBuilder: (sx) => React.ReactNode;
}) {
  const _size = props.size || 26;
  const backgroundColor = props.color || 'blue';
  const icon = props.iconBuilder({
    color: 'white',
    fontSize: _size * 0.9,
    width: _size * 0.9,
    height: _size * 0.9,
  });
  const boxsize = `${_size + 8}px`;

  if (props.component === 'button') {
    if (props.href) {
      return (
        <IconButton
          className={style.roundButton}
          style={{
            width: _size,
            height: _size,
            backgroundColor: backgroundColor,
          }}
          href={props.href}
        >
          <Box style={{ width: boxsize, height: boxsize }}>
            <Box className={clsx(style.iconWrapper)}>{icon}</Box>
          </Box>
        </IconButton>
      );
    }
    return (
      <IconButton
        className={style.roundButton}
        style={{
          width: _size,
          height: _size,
          backgroundColor: backgroundColor,
        }}
        onClick={props.onClick}
      >
        <Box style={{ width: boxsize, height: boxsize }}>
          <Box className={clsx(style.iconWrapper)}>{icon}</Box>
        </Box>
      </IconButton>
    );
  } else {
    return (
      <Box
        className={style.roundButton}
        style={{
          width: boxsize,
          height: boxsize,
          backgroundColor: backgroundColor,
          textAlign: 'center',
          borderRadius: '50%',
        }}
        onClick={props.onClick}
      >
        <Box className={clsx(style.iconWrapper)}>{icon}</Box>
      </Box>
    );
  }
}
