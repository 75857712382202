import LikeAnimation from 'app/components/LikeAnimation/LikeAnimation';
import { Grid } from '@mui/material';
import HSpace from 'app/components/HSpace';
import ShareButton from './ShareButton';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import { Advisor } from 'types';

function AdvisorProfilePlusBottom({
  likeCount,
  advisor,
}: {
  likeCount: number;
  advisor: Advisor;
}) {
  const { mainColor, buttonSx } = useAdvisorProfileTheme();

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid item>
        <LikeAnimation likes={likeCount} color={mainColor} />
      </Grid>
      <HSpace size={2} />
      <Grid item flexGrow={1}>
        <ShareButton advisor={advisor} role="advisor" sx={buttonSx} />{' '}
      </Grid>
    </Grid>
  );
}

export default AdvisorProfilePlusBottom;
