import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

const WechatQrCodeDescriptionDialog = props => {
  const { open, onClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {t('AdviserProfile.WechatQrCodeInstruction.how-to-find-wechat-qr-code')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 16,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Swiper
          initialSlide={0}
          slidesPerView="auto"
          loop={false}
          centeredSlides={true}
          autoHeight={true}
          effect="coverflow"
          autoplay={{
            delay: 2400,
          }}
          coverflowEffect={{
            rotate: 0,
          }}
        >
          <SwiperSlide style={{ width: 220, height: 400 }}>
            <img src="/images/wechatQrCodeInstruction/wechat_qr_code_step_1.png" width={220} height={400} />
          </SwiperSlide>
          <SwiperSlide style={{ width: 220, height: 400 }}>
            <img src="/images/wechatQrCodeInstruction/wechat_qr_code_step_2.png" width={220} height={400} />
          </SwiperSlide>
          <SwiperSlide style={{ width: 220, height: 400 }}>
            <img src="/images/wechatQrCodeInstruction/wechat_qr_code_step_3.png" width={220} height={400} />
          </SwiperSlide>
        </Swiper>
      </DialogContent>
    </Dialog>
  );
};

export default WechatQrCodeDescriptionDialog;
