import { trackRawEvent } from '../trackRawEvent';
import { AdvisorContactStartedProps, TrackableEventType } from '../types/types';
export function trackAdvisorContactStarted(props: AdvisorContactStartedProps) {
  trackRawEvent({
    event: TrackableEventType.AdvisorContactStarted.valueOf(),
    props: {
      Channel: props.Channel,
    },
  });
}
