import { COLORS } from 'constants/editor';
import { EditorState } from 'draft-js';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import { BlockPicker } from 'react-color';
import { Button, Popover, Zoom } from '@mui/material';
import { useState, useRef } from 'react';
import './ColorButton.css';
import { getCurrentColor } from 'utils/Draft-js/getCurrentColor';


type ColorButtonProps = {
  editorState: EditorState;
  colorStyles: any;
  onChange: (val: any) => void;
  onClose?: () => void;
  editorRef?: any;
  disabled: boolean;
};

function ColorButton({
  editorState,
  onChange,
  colorStyles,
  onClose,
  disabled,
}: ColorButtonProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const color = colorStyles.current(editorState);

  const open = Boolean(anchorEl);
  const currentColor = getCurrentColor(editorState);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleColorOnClick = color => {
    setAnchorEl(null);
    const coloredEditorState = colorStyles.toggle(editorState, color.hex);
    return onChange(coloredEditorState);
  };

  return (
    <div>
      <Button
        type="button"
        disabled={disabled}
        onClick={handleClick}
        ref={buttonRef}
        sx={{
          color: `${currentColor} !important`,
          backgroundColor: 'transparent',
        }}
      >
        <ColorLensOutlinedIcon />
      </Button>
      <Popover
        TransitionComponent={Zoom}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          onClose?.();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <BlockPicker
          width="276px"
          color={color}
          onChange={selectedColor => handleColorOnClick(selectedColor)}
          colors={COLORS}
        />
      </Popover>
    </div>
  );
}

export default ColorButton;
