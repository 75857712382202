import { useContext } from 'react';
// import IFrameContainer from 'app/components/IFrameContainer/IFrameContainer';
// import { GenericIFrameMessage } from 'app/components/IFrameContainer/IFrameContainer.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedUserContext } from 'hooks/hooks';
import { Box } from '@mui/material';
import { toURL } from 'utils';
import { redirectToPFP } from 'app/route/route';

function Login({ redirectTo }: { redirectTo?: string }) {
  // const src = process.env.REACT_APP_PFPLUS_FRONTEND_URL + '/login';
  // const ref = useRef<HTMLIFrameElement | null>(null);
  const location = useLocation();
  const destination =
    new URLSearchParams(location.search).get('redirect_to') ||
    redirectTo ||
    '/';
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  // const onMessage = async (event: MessageEvent<GenericIFrameMessage>) => {
  //   if (event.data.success) {
  //     await AuthenticatedUser.reAuth(destination);
  //   }
  // };
  const n = useNavigate();

  if (AuthenticatedUser.isLoading.user || AuthenticatedUser.isLoading.profile) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <h1> Loading</h1>
      </Box>
    );
  } else if (AuthenticatedUser.authenticated) {
    window.location.href = toURL(destination);
    return <></>;
  } else {
    redirectToPFP(n, '/login');
    return (
      <></>
      // <IFrameContainer
      //   src={src}
      //   title="Login"
      //   ref={ref}
      //   onMessage={onMessage}
      //   origin={process.env.REACT_APP_PFPLUS_FRONTEND_URL ?? '*'}
      // />
    );
  }
}

export default Login;
