import { Backdrop, Box, Container, Grid } from '@mui/material';
import AdvisorProfileSummaryCard from './AdvisorProfileSummaryCard';
import LikeButton from './LikeButton';
import ShareButton from './ShareButton';
import styles from './AdvisorProfile.module.css';

import { useDownXl, useIsTablet } from 'hooks/useBreakpoints/useBreakpoints';
import AdvisorProfileCardSlides from './AdvisorProfileCardSlides';
import { Advisor } from 'types';
import {
  AdvisorProfileThemeProvider,
  useAdvisorProfileTheme,
} from './AdvisorProfileThemeProvider';
import VSpace from '../VSpace';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdvisorProfileNav from './AdvisorProfileNav';
// import AdvisorProfileNavModal from './AdvisorProfileNavModal';
import { useContext, useMemo, useState } from 'react';
import { useAdviserProfileOwner } from 'hooks/useAdviserProfileOwner';
import { PaidFeatureAlertType } from '../PaidFeatureAlert/PaidFeatureAlert.types';
import { ProfilePlusTopNav } from '../ProfilePlusTopNav/ProfilePlusTopNav';
import { AuthenticatedUserContext } from 'hooks/hooks';
import AdvisorProfilePlusBottom from './AdvisorProfilePlusBottom';
import HSpace from '../HSpace';
import Loading from '../Loading/Loading';
import AdviserActionFAB from '../AdviserActionFAB/AdviserActionFAB';

export function AdvisorProfilePageContent({
  advisor,
  onShowShortcut,
  isDemo,
  isFreeUser,
  paidFeatureAlertType,
}: {
  advisor: Advisor;
  onShowShortcut?: () => void;
  isDemo: boolean;
  isFreeUser: boolean;
  paidFeatureAlertType: PaidFeatureAlertType;
}) {
  const { buttonSx } = useAdvisorProfileTheme();
  const isTablet = useIsTablet();
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const isAdvisorProfileOwnedByUser = AuthenticatedUser.isAdviserProfileOwner(
    advisor.userId,
  );

  return useMemo(() => {
    if (AuthenticatedUser.isAnyPartLoading) {
      return <Loading />;
    } else if (isTablet) {
      return (
        <div className={styles.layout}>
          <ProfilePlusTopNav isSelfCard={isAdvisorProfileOwnedByUser} />
          <AdvisorProfileCardSlides
            isDemo={!!isDemo}
            advisor={advisor}
            onShowShortcut={onShowShortcut}
            isFreeUser={isFreeUser}
            paidFeatureAlertType={paidFeatureAlertType}
          >
            <Box className={styles.footer}>
              {isAdvisorProfileOwnedByUser ? (
                <Box
                  sx={{
                    marginTop: '-12px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '88%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <AdvisorProfilePlusBottom
                      likeCount={advisor.likes}
                      advisor={advisor}
                    />
                  </Box>
                </Box>
              ) : (
                <Box className={styles.fab}>
                  <LikeButton
                    shouldShowLikeForm={isDemo || !isFreeUser}
                    sx={{ ...buttonSx, width: 'calc(-120px + 100vw)' }}
                    advisor={advisor}
                  />
                  <ShareButton
                    sx={{ ...buttonSx, width: '39px', height: '39px' }}
                    advisor={advisor}
                  />
                </Box>
              )}
            </Box>
          </AdvisorProfileCardSlides>
        </div>
      );
    }

    return (
      <Grid container>
        <ProfilePlusTopNav isSelfCard={isAdvisorProfileOwnedByUser} />

        <Grid
          item
          md={5}
          justifyContent="center"
          alignItems="flex-start"
          margin="auto"
        >
          <AdvisorProfileSummaryCard
            isDemo={isDemo}
            advisor={advisor}
            onShowShortcut={onShowShortcut}
            isFreeUser={isFreeUser}
            paidFeatureAlertType={paidFeatureAlertType}
          />
          {!isAdvisorProfileOwnedByUser && (
            <Box className={styles['xl-footer']}>
              <VSpace size={4} />
              <Grid
                container
                gap={1}
                maxWidth={300}
                wrap="nowrap"
                margin="auto"
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Grid container>
                    <Grid item flexGrow={1}>
                      <LikeButton
                        sx={{ ...buttonSx }}
                        advisor={advisor}
                        shouldShowLikeForm={isDemo || !isFreeUser}
                      />
                    </Grid>
                    <HSpace size={1} />
                    <Grid item>
                      <ShareButton
                        sx={{
                          ...buttonSx,
                          width: '39px',
                          height: '39px',
                          borderRadius: '50%',
                        }}
                        advisor={advisor}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <AdvisorProfileCardSlides
            isDemo={isDemo}
            advisor={advisor}
            onShowShortcut={onShowShortcut}
            isFreeUser={isFreeUser}
            paidFeatureAlertType={paidFeatureAlertType}
          />
        </Grid>
      </Grid>
    );
  }, [
    AuthenticatedUser.isAnyPartLoading,
    isTablet,
    isAdvisorProfileOwnedByUser,
    isDemo,
    advisor,
    onShowShortcut,
    isFreeUser,
    paidFeatureAlertType,
    buttonSx,
  ]);
}

function WrappedAdvisorProfile({
  advisor,
  onShowShortcut,
  isDemo,
  isFreeUser,
  paidFeatureAlertType,
}: {
  advisor: Advisor;
  onShowShortcut?: () => void;
  isDemo: boolean;
  isFreeUser: boolean;
  paidFeatureAlertType: PaidFeatureAlertType;
}) {
  const isAdvisorProfileOwnedByUser = useAdviserProfileOwner(advisor);
  const isDownXl = useDownXl();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Backdrop open={open} sx={{ zIndex: 1000 }} />
      <Box className={styles.background}>
        <Box className={styles.container}>
          {!isDownXl && isAdvisorProfileOwnedByUser && (
            <div className={styles.navContainer}>
              <AdvisorProfileNav advisor={advisor} elevation={3} />
            </div>
          )}
          <Container
            maxWidth="md"
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <AdvisorProfilePageContent
              advisor={advisor}
              onShowShortcut={onShowShortcut}
              isDemo={isDemo}
              isFreeUser={isFreeUser}
              paidFeatureAlertType={paidFeatureAlertType}
            ></AdvisorProfilePageContent>
          </Container>
        </Box>
      </Box>
      {isDownXl && isAdvisorProfileOwnedByUser && (
        <Box
          sx={{
            position: 'fixed',
            right: 24,
            bottom: 24,
            zIndex: 1100,
          }}
        >
          <AdviserActionFAB
            plan={isFreeUser}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            advisor={advisor}
          />
        </Box>

        /* <Fab
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: mainColor,
              color: 'white',
              ':hover': {
                backgroundColor: color3,
              },
            }}
            onClick={() => {
              setIsNavModalOpen(true);
            }}
          >
            <MoreVertIcon />
          </Fab>
          <AdvisorProfileNavModal
            advisor={advisor}
            show={isNavModalOpen}
            onClose={() => {
              setIsNavModalOpen(false);
            }}
          /> */
      )}
    </>
  );
}

export const getMainColorFromThemeColor = (themeColor: string) => {
  const computedStyle = getComputedStyle(document.body);
  switch (themeColor) {
    case 'blue':
      return computedStyle.getPropertyValue('--biz-page-blue-1');
    case 'green':
      return computedStyle.getPropertyValue('--biz-page-green-1');
    case 'orange':
      return computedStyle.getPropertyValue('--biz-page-orange-1');
    case 'violet':
      return computedStyle.getPropertyValue('--biz-page-violet-1');
    case 'earth':
      return computedStyle.getPropertyValue('--biz-page-earth-1');
  }
};

export const getMainGridentBackground = (themeColor: string) => {
  const computedStyle = getComputedStyle(document.body);
  switch (themeColor) {
    case 'blue':
      return computedStyle.getPropertyValue('--biz-page-blue-background');
    case 'green':
      return computedStyle.getPropertyValue('--biz-page-green-background');
    case 'orange':
      return computedStyle.getPropertyValue('--biz-page-orange-background');
    case 'violet':
      return computedStyle.getPropertyValue('--biz-page-violet-background');
    case 'earth':
      return computedStyle.getPropertyValue('--biz-page-earth-background');
  }
};

export default function AdvisorProfilePage({
  advisor,
  onShowShortcut,
  isDemo = false,
  isFreeUser,
  paidFeatureAlertType,
}: {
  advisor: Advisor;
  onShowShortcut?: () => void;
  isDemo?: boolean;
  isFreeUser: boolean;
  paidFeatureAlertType: PaidFeatureAlertType;
}) {
  const mainColor =
    advisor?.adviserInfo?.customThemeColor ||
    getMainColorFromThemeColor(advisor?.adviserInfo.themeColor || 'blue');

  // const advisorThemeColor = advisor?.adviserInfo.themeColor || 'blue';
  // const bg = getAdviserThemeValue(
  //   advisorThemeColor,
  //   AdviserThemeValueType.GridentBG,
  // );

  return (
    <AdvisorProfileThemeProvider mainColor={mainColor}>
      <WrappedAdvisorProfile
        advisor={advisor}
        onShowShortcut={onShowShortcut}
        isDemo={isDemo}
        isFreeUser={isFreeUser}
        paidFeatureAlertType={paidFeatureAlertType}
      />
    </AdvisorProfileThemeProvider>
  );
}
