import { Box } from '@mui/material';

export function PlaceholderMd(): JSX.Element {
  return (
    <Box>
      <img
        src="/images/backgrounds/linker-illustration.jpg"
        alt="Linker Device"
        style={{
          maxWidth: '100%',
          height: 'auto',
          width: '500px',
        }}
      />
    </Box>
  );
}
