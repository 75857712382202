import { SxProps } from '@mui/material';
import { Channel } from '../../../hooks/hooks';

const TrHeader = 'ContactPortfoplus';

function getCardId(): string | undefined {
  if (window.location.pathname.startsWith('/ca')) {
    return window.location.pathname.split('/')[2];
  } else {
    return undefined;
  }
}

export interface ContactPortfoplusMessageFields {
  platform?: string;
  cardId?: string | undefined;
  name?: string;
  email?: string;
}

function getContactPortfoplusMessageFields(
  props: ContactPortfoplusMessageFields,
): { [key: string]: string } {
  return {
    platform: props.platform || 'web',
    cardId: props.cardId || getCardId() || '',
    name: props.name || '',
    email: props.email || '',
  };
}

export interface ContactPortfoplusProps {
  channel?: Channel;
  sx?: SxProps;
  cta?: string;
  message?: string;
  onContact?: () => void;
  builder?: (
    onContact: () => void,
    cta: string,
    defaultMessage: string,
  ) => JSX.Element;
}

function getChannelApiEndpoint(
  channel: Channel,
  message: string,
): string | undefined {
  switch (channel) {
    case Channel.WhatsApp:
      const phoneNumber = process.env.REACT_APP_CS_PHONE_NUMBER;
      return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    case Channel.EMail:
      return 'mailto:';
    default:
      return undefined;
  }
}

export {
  TrHeader,
  Channel,
  getContactPortfoplusMessageFields,
  getChannelApiEndpoint,
};
