import {
  // Modal,
  // Drawer,
  Box,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSubmitAdvisorReferralMutation } from 'services/api';
import { SubmitReferral } from 'services/apiTypes';
import { Advisor } from 'types';
import FormField from '../FormField';
import Tags from '../Tags';
import VSpace from '../VSpace';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import CloseIcon from '@mui/icons-material/Close';
import LikeAnimation from '../LikeAnimation/LikeAnimation';
import styles from './ClientReplyFormModal.module.css';
import SubmitReferralSuccessModal from './SubmitReferralSuccessModal';
import { SelectOption } from '../FormField';
import { useDownXl } from 'hooks/useBreakpoints/useBreakpoints';
import BottomOrDialog from '../BottomOrDialog/BottomOrDialog';

const LIKE_MODAL_STYLE = {
  // position: 'absolute',
  // top: {
  //   md: '50%',
  // },
  // bottom: {
  //   xs: 0,
  // },
  // left: '50%',
  // transform: {
  //   xs: 'translate(-50%, 0)',
  //   md: 'translate(-50%, -50%)',
  // },
  width: {
    xs: '100%',
    md: 400,
  },
  // minHeight: 'max-content',
  maxHeight: '75vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
  },
};

export default function ClientReplyFormModal({
  advisor,
  show,
  onClose,
}: {
  advisor?: Advisor;
  show: boolean;
  onClose: () => void;
}) {
  const [submitReferral] = useSubmitAdvisorReferralMutation();
  const { mainColor, buttonSx } = useAdvisorProfileTheme();

  const { t, i18n } = useTranslation();

  const hobbyOptions =
    advisor?.adviserInfo.hobbies?.map(hobby => ({
      label: t(`AdviserProfile.Hobby.${hobby}`),
      value: hobby,
    })) || [];
  const familiarPlaceOptions =
    advisor?.adviserInfo.familiarPlaces?.map(familiarPlace => ({
      label: familiarPlace,
      value: familiarPlace,
    })) || [];

  const personalityTagOptions =
    advisor?.adviserInfo.personalityTags?.map(personalityTag => ({
      label: t(`AdviserProfile.PersonalityTag.${personalityTag}`),
      value: personalityTag,
    })) || [];

  const tagOptions = [...hobbyOptions, ...familiarPlaceOptions];

  const serviceOptions = advisor?.adviserInfo.mainProductsServices?.map(
    service => ({
      label: t(`AdviserProfile.MainProductsAndServices.${service}`),
      value: service,
    }),
  );

  const handleTagsChange = (tags: string[]) => {
    setSelectedTags(tags);
  };

  const handleServicesChange = (services: string[]) => {
    setSelectedServices(services);
  };

  const [answerTags, setAnswerTags] = useState<{ [key: string]: string[] }>({});

  const handleAnswerTagsChange = (question: string, tags: string[]) => {
    setAnswerTags({ ...answerTags, [question]: tags });
  };

  const resetForm = () => {
    setSelectedTags([]);
    setSelectedServices([]);
    setAnswerTags({});
    reset();
  };

  const {
    getValues,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm<
    Pick<SubmitReferral, 'name' | 'email' | 'remark' | 'phoneNumber'>
  >({
    defaultValues: {
      name: '',
      email: '',
      remark: '',
      phoneNumber: '',
    },
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [
    isShowSubmitReferralSuccessModal,
    setIsShowSubmitReferralSuccessModal,
  ] = useState(false);

  const handleSubmit = async () => {
    if (!advisor || !isValid) return;
    const { phoneNumber } = getValues();
    const submitReferralInput: SubmitReferral = {
      ...getValues(),
      phoneNumber,
      likedTags: selectedTags,
      helpItems: selectedServices,
      answerTags: answerTags,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: i18n.language,
      advisorId: advisor?.adviserInfo._id,
      customQuestions: advisor?.adviserInfo?.customQuestions ?? [],
    };
    if (
      submitReferralInput.name === '' ||
      submitReferralInput.phoneNumber === ''
    ) {
      const fiels = [
        t('AdviserProfile.ClientName'),
        t('AdviserProfile.ClientPhoneNumber'),
      ];
      toast.error(
        t('Global.RequiredFieldEmptyError').replace('{0}', fiels.join('，')),
      );
      return;
    }

    const response = await submitReferral(submitReferralInput).unwrap();
    if (response.success) {
      // toast.success(response.message);
      setIsShowSubmitReferralSuccessModal(true);
      resetForm();
    } else {
      toast.error(response.message);
    }
  };

  const handleSubmitReferralSuccessModalClose = () => {
    setIsShowSubmitReferralSuccessModal(false);
    onClose();
  };

  const handleReferMore = () => {
    setIsShowSubmitReferralSuccessModal(false);
  };

  const customQuestions = advisor?.adviserInfo.customQuestions || [];
  const shouldHideCustomQuestions =
    advisor?.adviserInfo?.shouldHideProfilePlusCustomQuestions;

  const child = (
    <Box sx={LIKE_MODAL_STYLE}>
      <LikeAnimation
        color={mainColor}
        likes={advisor?.likes}
        render={anim => (
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <Typography fontWeight={600} color={mainColor}>
                Like
              </Typography>
              {anim}
              <Typography fontWeight={600} color={mainColor}>
                {' '}
                {advisor?.likes}
              </Typography>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      />

      {/* Custom Question */}
      {customQuestions &&
        !shouldHideCustomQuestions &&
        customQuestions.map((question, index) => (
          <div key={`qn_${index}`}>
            <VSpace size={2} />
            <Typography variant="subtitle2" fontWeight={500}>
              {question.question}
            </Typography>
            <VSpace size={1} />
            <Tags
              tags={answerTags[question.question] || []}
              options={question?.answers
                ?.split('#')
                .filter(e => e != '')
                .map<SelectOption>(e => {
                  return { label: e, value: e };
                })}
              onChange={tags => handleAnswerTagsChange(question.question, tags)}
              randomizeColor
            />
          </div>
        ))}
      {/* Custom Question */}

      <VSpace size={2} />
      <Typography variant="subtitle2" fontWeight={500}>
        👍🏻 {t('AdviserProfile.LikeTagsQuestion')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={selectedTags}
        options={tagOptions}
        onChange={handleTagsChange}
        randomizeColor
      />
      <VSpace size={2} />
      <Typography variant="subtitle2" fontWeight={500}>
        ☕ {t('AdviserProfile.ServiceQuestion')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={selectedServices}
        options={serviceOptions}
        onChange={handleServicesChange}
        randomizeColor
      />
      {/* TODO: Yup validation and phoneNumber field after merge */}
      <VSpace size={2} />
      <Typography variant="subtitle2" color="var(--color-text-primary)">
        {t('AdviserProfile.ClientRemarkTitle')}
      </Typography>
      <FormField
        type="text"
        label={t('AdviserProfile.ClientRemark')}
        control={control}
        name="remark"
      />
      <FormField
        type="text"
        label={t('AdviserProfile.ClientName')}
        control={control}
        name="name"
        required
      />
      <FormField
        type="phoneNumber"
        label={t('AdviserProfile.ClientPhoneNumber')}
        control={control}
        name="phoneNumber"
        required
      />
      <FormField
        type="text"
        label={t('AdviserProfile.ClientEmail')}
        control={control}
        name="email"
      />
      <Button
        variant="contained"
        sx={{ width: '100%', borderRadius: '100px', ...buttonSx }}
        onClick={handleSubmit}
      >
        {t('Global.Submit')}
      </Button>
      <VSpace size={1} />

      <Box style={{ textAlign: 'center' }}>
        <Typography variant="caption">
          {t('AdviserProfile.PrivacyStatement1')}
        </Typography>
        <VSpace size="0px" />
        <Typography variant="caption">
          {t('AdviserProfile.PrivacyStatement2')}&nbsp;
          <a href="https://www.portfoplus.com/privacy.html">
            {t('Global.PrivacyPolicy')}
          </a>
        </Typography>
      </Box>
    </Box>
  );

  const isDownXL = useDownXl();

  return (
    <BottomOrDialog open={show} onClose={onClose}>
      <>
        {child}
        {advisor && (
          <SubmitReferralSuccessModal
            advisor={advisor}
            show={isShowSubmitReferralSuccessModal}
            onClose={handleSubmitReferralSuccessModalClose}
            onReferMore={handleReferMore}
          />
        )}
      </>
    </BottomOrDialog>
  );
}
