import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { PERSONALITY_DIALOG_OPTIONS } from 'constants/editProfile';
import styles from './PersonalityTypeDialog.module.css';
import clsx from 'clsx';
import { PersonalityType } from 'types';

interface PersonalityTypeDialogProps {
  open: boolean;
  onClose: () => void;
  selectedValue: PersonalityType;
  setSelectedValue: (val: PersonalityType) => void;
  setPersonalityTypeValue: (val: PersonalityType) => void;
}

function PersonalityTypeDialog({
  open,
  onClose,
  selectedValue,
  setSelectedValue,
  setPersonalityTypeValue,
}: PersonalityTypeDialogProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    setPersonalityTypeValue(selectedValue);
    onClose();
  };

  const handlePersonalityTypeOnClick = (val: PersonalityType) => {
    setSelectedValue(val);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: { xs: '328px', md: '600px' },
          boxShadow: 'var(--shadow-l7)',
          borderRadius: '16px',
          margin: { xs: 'auto' },
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        <PersonOutlineOutlinedIcon
          sx={{
            mr: 1,
          }}
        />
        {t("AdviserProfile.PersonalityType")}
      </DialogTitle>
      <DialogContent
        sx={{
          pb: 0,
        }}
      >
        <Typography>
          {t("AdviserProfile.PersonalityTips")}
        </Typography>
        <List
          sx={{
            maxWidth: '274px',
            margin: 'auto',
            pb: 0,
          }}
        >
          {PERSONALITY_DIALOG_OPTIONS.map(option => (
            <ListItem
              className={clsx(
                styles.personalityTypeOptions,
                option.value === selectedValue ? styles.active : undefined,
                styles[option.value],
              )}
              onClick={() => handlePersonalityTypeOnClick(option.value)}
              key={option.value}
            >
              {option.icon}
              <Typography variant="subtitle1" fontWeight={500}>
                {t(`Personality.${option.value}`)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '22px 24px 16px',
        }}
      >
        <Button
          variant="text"
          sx={{
            p: 0,
          }}
          onClick={() => onClose()}
        >
          {t("Global.Cancel")}
        </Button>
        <Button
          variant="text"
          sx={{
            p: 0,
          }}
          onClick={() => handleSubmit()}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PersonalityTypeDialog;
