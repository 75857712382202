import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';
import type { Advisor } from 'types';
import {
  PeopleAlt as PeopleAltIcon,
  Edit as EditIcon,
  ListAlt as ListAltIcon,
} from '@mui/icons-material';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import { useContext, useState } from 'react';
import ShareButton from './ShareButton';
import ClientReplyFormModal from './ClientReplyFormModal';
import { redirectToPFP } from 'app/route/route';
import PaidFeatureAlert from '../PaidFeatureAlert/PaidFeatureAlert';
import { PaidFeatureAlertType } from '../PaidFeatureAlert/PaidFeatureAlert.types';
import { AuthenticatedUserContext } from 'hooks/hooks';
import DiamondTrailing from '../DiamondTrailing/DiamondTrailing';
import Lottie from '../Lottie/Lottie';

const LottieContainerStyle = {
  position: 'relative',
  width: 24,
  height: 24,
};

const LottieStyle = {
  width: 54,
  height: 54,
  position: 'absolute',
  top: -22,
  left: -16,
};

type MenuItem = {
  icon: React.ReactElement;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
  isPro?: boolean;
  paidFeature?: boolean;
};

function useAdvisorProfileNavMenuItems({
  setIsShowClientReplyForm,
  navigate,
}: {
  navigate: NavigateFunction;
  setIsShowClientReplyForm: (isShow: boolean) => void;
}) {
  const { t } = useTranslation();
  const showClientReplyForm = () => {
    setIsShowClientReplyForm(true);
  };
  const menuItems: MenuItem[] = [
    {
      icon: <EditIcon />,
      title: t('Global.Edit'),
      onClick: () => {
        redirectToPFP(navigate, '/linker/my-details');
      },
      isPro: false,
    },
    {
      icon: <PeopleAltIcon />,
      title: t('AdviserProfile.NewLeads'),
      onClick: () => {
        redirectToPFP(navigate, 'referral_records');
      },
      paidFeature: true,
    },
    {
      icon: <ListAltIcon />,
      title: t('AdviserProfile.ClientReplyForm'),
      onClick: () => {
        showClientReplyForm();
      },
      paidFeature: true,
    },
  ];

  return menuItems;
}

export default function AdvisorProfileNav({
  advisor,
  elevation = 0,
}: {
  elevation?: number;
  advisor?: Advisor;
}) {
  const navigate = useNavigate();
  const { mainColor, color1, buttonSx } = useAdvisorProfileTheme();
  const [isShowClientReplyForm, setIsShowClientReplyForm] = useState(false);
  const menuItems = useAdvisorProfileNavMenuItems({
    setIsShowClientReplyForm,
    navigate,
  });

  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const handleCloseLikeForm = () => {
    setIsShowClientReplyForm(false);
  };

  return (
    <>
      <Box>
        <Paper elevation={elevation} sx={{ borderRadius: '12px', p: 2 }}>
          {advisor ? (
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                backgroundColor: color1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h4" sx={{ color: mainColor }}>
                  {advisor?.likes}
                </Typography>
                <Box sx={LottieContainerStyle}>
                  <Lottie
                    style={LottieStyle}
                    options={{
                      animationData: '/lottie/likeanimation.json',
                      loop: false,
                      autoplay: true,
                      color: mainColor,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={74}
              sx={{ borderRadius: '12px' }}
            />
          )}
          <List>
            {menuItems.map(item => {
              const showPaymentAlert =
                item.paidFeature && AuthenticatedUser.subscriptionNickname
                  ? AuthenticatedUser.subscriptionNickname
                  : false;

              return (
                <PaidFeatureAlert
                  key={item.title}
                  type={PaidFeatureAlertType.info}
                  plan={showPaymentAlert}
                  render={(onAlert: () => void, shouldShowAlert: boolean) => {
                    return (
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={e => {
                            if (shouldShowAlert) {
                              onAlert();
                            } else {
                              item.onClick?.();
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                          <DiamondTrailing
                            focusingAdviserId={!!(item.paidFeature && true)}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }}
                ></PaidFeatureAlert>
              );
            })}
          </List>
          <ShareButton advisor={advisor} role="advisor" sx={buttonSx} />
        </Paper>
      </Box>
      <ClientReplyFormModal
        advisor={advisor}
        show={isShowClientReplyForm}
        onClose={handleCloseLikeForm}
      />
    </>
  );
}
