import { EditorState, Modifier } from 'draft-js';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Box, Button, Popover, Zoom } from '@mui/material';
import { useState, useRef } from 'react';
import Picker from '@emoji-mart/react';
import { getLastSelection } from 'utils/Draft-js/getLastSelection';
// import 'emoji-mart/css/emoji-mart.css';
import data from '@emoji-mart/data';

import styles from './Editor';
type EmojiButtonProps = {
  editorState: EditorState;
  onChange: (val: any) => void;
  onClose?: () => void;
  disabled: boolean;
};

function EmojiButton({
  editorState,
  onChange,
  onClose,
  disabled,
}: EmojiButtonProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const open = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const handleEmojiButtonOnClick = emoji => {
    setAnchorEl(null);
    const selectionState = getLastSelection(editorState);
    let newEditorState = editorState;
    const contentState = newEditorState.getCurrentContent();

    if (emoji) {
      const newContent = Modifier.replaceText(
        contentState,
        selectionState,
        emoji,
        newEditorState.getCurrentInlineStyle(),
      );
      newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters',
      );
    }
    onChange(newEditorState);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        onClick={handleClick}
        ref={buttonRef}
        sx={{
          padding: 0,
          minWidth: '24px',
          marginRight: '20px',
          color: 'black',
        }}
      >
        <InsertEmoticonIcon />
      </Button>
      <Popover
        className={styles['emoji-picker']}
        TransitionComponent={Zoom}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box
          sx={{
            maxWidth: '340px',
            width: 'calc(100vw - 32px)',
          }}>
          <Picker
            data={data}
            native={true}
            onEmojiSelect={emoji => handleEmojiButtonOnClick(emoji.native)}
            showPreview={false}
            showSkinTones={false}
            title=""
            darkMode={false}
          />
        </Box>
      </Popover>
      {/* Preload the Popover */}
    </div>
  );
}

export default EmojiButton;
