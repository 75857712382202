import { RGBColor } from 'types';

function setKColor(
  animationData: object,
  color: RGBColor,
  colorKey: {
    r: string;
    g: string;
    b: string;
  } = {
    r: '__DYNAMIC_COLOR_R__',
    g: '__DYNAMIC_COLOR_G__',
    b: '__DYNAMIC_COLOR_B__',
  },
): object {
  const replaceColor = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceColor(obj[key]);
      } else if (Array.isArray(obj)) {
        obj.forEach((item, index) => {
          if (item === colorKey.r) obj[index] = color.r;
          if (item === colorKey.g) obj[index] = color.g;
          if (item === colorKey.b) obj[index] = color.b;
        });
      }
    }
  };
  const newAnimationData = animationData;
  replaceColor(newAnimationData);
  return newAnimationData;
}

export default setKColor;
