const HOSTNAME = process.env.REACT_APP_PFPLUS_BACKEND_URL;

export enum ReferralMedium {
  LINK = 'link',
  WHATSAPP = 'whatsapp',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  EMAIL = 'email',
  SMS = 'sms',
  CARD = 'card',
  CARDQR = 'card-qr',
  OTHER = 'other',
}

type GetReferralLinkProps = { target: string } & (
  | (GetReferralLinkPropsReferrerProps & GetReferralLinkRedirectProps)
  | GetReferralLinkRedirectProps
);

interface GetReferralLinkRedirectProps {
  search?: string | URLSearchParams;
  medium: ReferralMedium;
}

interface GetReferralLinkPropsReferrerProps {
  referrer: string;
  isAdvisor: boolean;
  self: boolean;
}

export const getReferralLink = (props: GetReferralLinkProps) => {
  const { search, medium, target } = props;
  let isAdvisor: boolean | undefined,
    self: boolean | undefined,
    referrer: string | undefined;
  if ('isAdvisor' in props && 'self' in props && 'referrer' in props) {
    ({ isAdvisor, self, referrer } = props);
  }

  const _search =
    search instanceof URLSearchParams ? search : new URLSearchParams(search);
  _search.set('m', medium);
  _search.set('o', 'lk');
  if (isAdvisor !== undefined) {
    _search.set('isAdvisor', isAdvisor.toString());
  }

  if (referrer !== undefined) {
    _search.set('referById', referrer);
  }
  if (self !== undefined) {
    _search.set('self', self.toString());
  }

  const searchString = _search.toString();

  return `${HOSTNAME}/adviser/${target}${
    searchString ? `?${searchString}` : ''
  }`;
};
