import { memo, ReactElement } from 'react';
import { EditorState } from 'draft-js';
import StyleButton from './StyleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
// import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

type BlockTypesButtonType = {
  label: string | ReactElement;
  style: string;
  separating?: boolean;
};

const BLOCK_TYPES: BlockTypesButtonType[] = [
  { label: <FormatAlignLeftIcon />, style: 'align-left' },
  { label: <FormatAlignCenterIcon />, style: 'align-center' }
];

type BlockStyleControlsProps = {
  editorState: EditorState;
  onToggle: (bockType: string) => void;
  disabled: boolean;
};

const BlockStyleControls = ({
  editorState,
  onToggle,
  disabled,
}: BlockStyleControlsProps) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <>
      {BLOCK_TYPES.map(type => (
        <StyleButton
          disabled={disabled}
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          separating={type?.separating}
        />
      ))}
    </>
  );
};

export default memo(BlockStyleControls);
