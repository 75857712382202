import { useEffect } from 'react';
import type { EditProfileFormProps } from './formPorps';
import { Box, Button, Chip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormField from 'app/components/FormField';
import { useFormContext } from 'react-hook-form';
import styles from './EditProfileForm.module.css';
import {
  SENIORITY_OPTIONS,
  MY_FOCUS_OPTIONS,
  MAIN_SERVICES_OPTIONS,
  QUALIFICATIONS_OPTIONS,
} from 'constants/editProfile';
import Container from 'app/components/Container';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import VSpace from 'app/components/VSpace';

function Business({ back, next }: EditProfileFormProps) {
  const { t } = useTranslation();
  const { control, trigger, getValues, setValue, watch } = useFormContext();

  // 為了 Rerender Chip
  const watchMainProductsServices = watch('mainProductsServices', []);
  const watchIsMDRT = watch('isMDRT', []);
  const watchSeniority = watch('seniority', '');

  const handleNextOnClick = async () => {
    const validateResult = await trigger([
      'company',
      'jobTitle',
      'seniority',
      'shouldShowLicense',
      'license',
      'isMDRT',
      'otherAwards',
      'jobExperience',
    ]);
    const watchMainProductsServices = watch('mainProductsServices', []);
    const watchCustomizedProductsOrServices = watch(
      'customizedProductsOrServices',
      '',
    );

    if (
      validateResult &&
      (watchMainProductsServices.length > 0 ||
        watchCustomizedProductsOrServices !== '')
    ) {
      next!();
    } else {
      const error = t('AdviserProfile.Error.Required');
      toast.error(error);
    }
  };

  const handleSeniorityChipOnClick = val => {
    setValue('seniority', val);
  };

  const handleMainProductsServicesChipOnClick = val => {
    const mainProductsServices = getValues('mainProductsServices');
    if (mainProductsServices.includes(val)) {
      setValue(
        'mainProductsServices',
        mainProductsServices.filter(tag => tag !== val),
      );
    } else {
      if (mainProductsServices.length > 5) {
        return;
      }
      setValue('mainProductsServices', [...mainProductsServices, val]);
    }
  };

  const handleIsMDRTChipOnClick = val => {
    const isMDRTArr = getValues('isMDRT');
    if (isMDRTArr.includes(val)) {
      setValue(
        'isMDRT',
        isMDRTArr.filter(language => language !== val),
      );
    } else {
      setValue('isMDRT', [...isMDRTArr, val]);
    }
  };

  const license = watch('license');
  useEffect(() => {
    if (!license || license.replaceAll('\n', '').replaceAll(' ', '') === '') {
      setValue('license', t('AdviserProfile.DefaultLicense'));
    }
  }, [license]);

  return (
    <div className={styles.container}>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          marginBottom: '16px',
        }}
      >
        <>
          <Box className={styles.fieldsGroup}>
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.Workplace')}
                name="company"
                control={control}
              />
            </Box>
            <Box>
              <FormField
                type="text"
                label={t('AdviserProfile.JobTitle')}
                name="jobTitle"
                control={control}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              marginBottom: '16px',
              fontSize: 14,
            }}
          >
            {t('AdviserProfile.Seniority')}
          </Typography>
          <Box>
            {SENIORITY_OPTIONS.map(option => (
              <Chip
                key={option.label}
                className={clsx(
                  watchSeniority && watchSeniority.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.SeniorityTag.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handleSeniorityChipOnClick(option.value)}
              />
            ))}
          </Box>

          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="center"
            >
              <Box display="flex" alignContent="center" flexWrap="wrap">
                {t('AdviserProfile.License')}
              </Box>
              <FormField
                type="switch"
                label={t('AdviserProfile.ShouldShowLicense')}
                name="shouldShowLicense"
                control={control}
                hideSpacing
                customFieldStyle={{
                  fontSize: 14,
                }}
              />
            </Box>
            <FormField
              type="text"
              label=""
              name="license"
              placeholder="License"
              control={control}
              multiline
              minRows={2}
            />
          </Box>
        </>
      </Container>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          marginBottom: '16px',
        }}
      >
        <>
          <Typography variant="subtitle2" color="var(--color-text-primary)">
            {t('AdviserProfile.MyFocus')}{' '}
            <span className={styles.required}>*</span>
            <span className={styles.remark}>
              ({t('AdviserProfile.Select')}{' '}
              <span className={styles.required}>
                {t('AdviserProfile.UpTo6InTotal')}
              </span>
            </span>
            )
          </Typography>
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            🛒 {t('AdviserProfile.MainProducts')}
          </Typography>
          {MY_FOCUS_OPTIONS.map(option => (
            <Chip
              key={option.value}
              className={clsx(
                watchMainProductsServices &&
                  watchMainProductsServices.includes(option.value)
                  ? styles.inactive
                  : undefined,
              )}
              label={t(
                `AdviserProfile.MainProductsAndServices.${option.value}`,
              )}
              variant="outlined"
              sx={{
                margin: '0 8px 8px 0',
              }}
              onClick={() =>
                handleMainProductsServicesChipOnClick(option.value)
              }
            />
          ))}
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            💻 {t('AdviserProfile.MainServices')}
          </Typography>
          {MAIN_SERVICES_OPTIONS.map(option => (
            <Chip
              key={option.value}
              className={clsx(
                watchMainProductsServices &&
                  watchMainProductsServices.includes(option.value)
                  ? styles.inactive
                  : undefined,
              )}
              label={t(
                `AdviserProfile.MainProductsAndServices.${option.value}`,
              )}
              variant="outlined"
              sx={{
                margin: '0 8px 8px 0',
              }}
              onClick={() =>
                handleMainProductsServicesChipOnClick(option.value)
              }
            />
          ))}
          <VSpace size={3} />
          <Box>
            <FormField
              type="text"
              label={t('AdviserProfile.CustomizedProductsOrServices')}
              name="customizedProductsOrServices"
              placeholder={t(
                'AdviserProfile.Placeholder.CustomizedProductsOrServices',
              )}
              control={control}
            />
          </Box>
        </>
      </Container>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
        }}
      >
        <>
          <Typography
            variant="subtitle2"
            color="var(--color-text-primary)"
            sx={{
              marginBottom: '16px',
            }}
          >
            {t('AdviserProfile.Qualifications')}
          </Typography>
          <Box
            sx={{
              marginBottom: '16px',
            }}
          >
            {QUALIFICATIONS_OPTIONS.map(option => (
              <Chip
                key={option.value}
                className={clsx(
                  watchIsMDRT && watchIsMDRT.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.Qualification.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handleIsMDRTChipOnClick(option.value)}
              />
            ))}
          </Box>
          <FormField
            type="text"
            label={t('AdviserProfile.Achievement')}
            name="otherAwards"
            placeholder={t('AdviserProfile.Placeholder.Achievement')}
            control={control}
            multiline
            minRows={2}
          />
          <FormField
            type="text"
            label={t('AdviserProfile.JobExperience')}
            name="jobExperience"
            placeholder={t('AdviserProfile.Placeholder.JobExperience')}
            control={control}
            multiline
            minRows={2}
          />
          <Box
            className={styles.footer}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={back}
            >
              {t('Global.Back')}
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={handleNextOnClick}
            >
              {t('Global.Next')}
            </Button>
          </Box>
        </>
      </Container>
    </div>
  );
}

export default Business;
