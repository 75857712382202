import { Box, Typography } from '@mui/material';
import Portfoplus, { LogoType } from 'app/components/Portfoplus';
import VSpace from 'app/components/VSpace';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.css';

export function PlaceholderSM() {
  const [t] = useTranslation();
  return (
    <Box className={styles.paddingX}>
      <VSpace size={5} />
      <Box maxWidth="sm" margin="auto">
        <Portfoplus
          type={LogoType.Fit}
          classNames={{
            container: 'p0',
          }}
        />
      </Box>
      <Typography variant="caption" sx={{ textAlign: 'justify' }}>
        {t('Registration.mainHeadline')}
      </Typography>
      <VSpace size={5} />
    </Box>
  );
}
