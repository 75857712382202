import TimeslotSelectorPanel from 'app/components/TimeslotSelectorPanel/TimeslotSelectorPanel';
import { TimeslotSelectorPanelLayout } from 'app/components/TimeslotSelectorPanel/TimeslotSelectorPanel.types';
import { Appointment } from 'services/appointment/types';

function SeminarForm(props: {
  onSuccess: (res: Appointment[]) => void;
  layout?: TimeslotSelectorPanelLayout;
}) {
  return (
    <TimeslotSelectorPanel
      size={{ xs: 12, md: 6, xl: 6 }}
      onAppointmentUpdate={appointment => props.onSuccess(appointment)}
      layout={props.layout ?? TimeslotSelectorPanelLayout.vScroll}
    />
  );
}

export default SeminarForm;
