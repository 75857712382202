import { Subscription } from 'services/apiTypes';

export const paidSubscription = ['LinkerPro', 'Pro'];

export function getIsFreeUser(subscription?: string | Subscription[]): boolean {
  if (!subscription) {
    return true;
  } else if (typeof subscription === 'string') {
    return !paidSubscription.includes(subscription);
  }
  // else if (subscription) {
  //   const checkers = subscription.map(sub => {
  //     return (
  //       paidSubscription.includes(sub.nickname) &&
  //       Date.parse(sub.expiredAt) > Date.now()
  //     );
  //   });
  //   return !checkers.some(value => value);
  // }
  else {
    return true;
  }
}
