import { InfoAlertProps } from './AlertComponent/Components/InfoAlert/InfoAlert.types';

export const TrHeader = 'PaidFeatureAlert';

export enum PaidFeatureAlertType {
  hide = 'hide',
  info = 'info',
  team = 'team',
}

interface PaidFeatureMixinProps {
  plan: string | boolean;
  type: PaidFeatureAlertType | string;
}

type PaidFeatureAlertBuilderOrChildren =
  | { render: (onAlert: () => void, shouldShowAlert: boolean) => JSX.Element }
  | { children: React.ReactNode };

export type PaidFeatureAlertProps = (
  | (PaidFeatureMixinProps & InfoAlertProps & { shouldShowAler?: boolean })
  | PaidFeatureMixinProps
) &
  PaidFeatureAlertBuilderOrChildren;
