import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography } from '@mui/material';
import styles from './Navigation.module.css';

type NavigationPropsType = {
  link?: string;
  label: string;
  back?: () => void;
};

export default function Navigation({ link, label, back }: NavigationPropsType) {
  const navigate = useNavigate();
  const handleLinkOnClick = () => {
    if (back) {
      back();
    } else {
      navigate(link as string, { replace: true });
    }
  };
  return (
    <Box className={styles.linkContainer} onClick={handleLinkOnClick}>
      <ArrowBackIosNewIcon
        sx={{
          fontSize: '12px',
          color: 'var(--text-primary-light)',
          marginRight: '16px',
        }}
      />
      <Typography variant="body1" sx={{ color: 'var(--text-primary-light)' }}>
        {label}
      </Typography>
    </Box>
  );
}
