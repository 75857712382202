import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Advisor } from 'types';
import { formatPhoneNumber } from 'utils/formatters';
import { getReferralLink, ReferralMedium } from 'utils/referral';
import VSpace from '../VSpace';
import { ContactField } from './AdvisorProfileContactCard';
import BottomOrDialog from '../BottomOrDialog/BottomOrDialog';
import ShareButton from './ShareButton';

const SUCCESS_MODAL_STYLE = {
  // position: 'absolute',
  // top: {
  //   md: '50%',
  // },
  // bottom: {
  //   xs: 0,
  // },
  // left: '50%',
  // transform: {
  //   xs: 'translate(-50%, 0)',
  //   md: 'translate(-50%, -50%)',
  // },
  // width: {
  //   xs: '100%',
  //   md: 400,
  // },

  // bgcolor: 'background.paper',
  // borderRadius: '16px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function SubmitReferralSuccessModal({
  advisor,
  show,
  onClose,
  onReferMore,
}: {
  advisor: Advisor;
  show: boolean;
  onClose: () => void;
  onReferMore?: () => void;
}) {
  const { t } = useTranslation();
  const {
    adviserInfo: { whatsapp },
  } = advisor;
  const whatsAppUrl = `https://api.whatsapp.com/send?phone=${whatsapp}&text=${encodeURIComponent(
    t('AdviserProfile.WhatsAppMessage', {
      link: getReferralLink({
        medium: ReferralMedium.LINK,
        target: advisor.adviserInfo._id,
      }),
    }),
  )}`;
  return (
    <BottomOrDialog open={show} onClose={onClose}>
      <Box sx={SUCCESS_MODAL_STYLE}>
        {/* <SubmitReferralSuccess /> */}
        <img
          style={{ width: '125px', height: '125px' }}
          src="/images/illustrations/referral_success.svg"
          alt="referral_success"
        />
        <VSpace size={2} />
        <Typography variant="h5" align="center" color="primary">
          {t('AdviserProfile.SubmitReferralSuccessModal.Title')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('AdviserProfile.SubmitReferralSuccessModal.Message')}
        </Typography>
        <VSpace size={2} />
        <ContactField
          icon={<WhatsAppIcon />}
          text={formatPhoneNumber(whatsapp) || '-'}
          href={whatsAppUrl}
        />
        <VSpace size={2} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            width: '100%',
          }}
        >
          <Button variant="outlined" color="primary" onClick={onClose}>
            OK
          </Button>
          <ShareButton
            advisor={advisor}
            role="user"
            sx={{ width: undefined }}
            buttonText={t(
              'AdviserProfile.SubmitReferralSuccessModal.ReferMore',
            )}
          />
        </Box>
      </Box>
    </BottomOrDialog>
  );
}
