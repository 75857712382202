import { trackRawEvent } from '../trackRawEvent';
import {
  InteractionEventType,
  InteractionEventProps,
} from '../types/InteractionEvent';

export function trackInteractionEvent({
  type,
  event_properties,
}: {
  type: InteractionEventType;
  event_properties: InteractionEventProps;
  [key: string]: any;
}) {
  const event = {
    event: 'Interaction Event: ' + type.valueOf(),
    props: {
      ...event_properties,
    },
  };
  trackRawEvent(event);
}
