import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Typography,
} from '@mui/material';
import { useState } from 'react';
// import styles from './PersonalityTypeDialog.module.css';
// import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import './ColorPickerDialog.css';

interface ColorPickerDialogProps {
  open: boolean;
  onClose: () => void;
  handleColorChange: (color: string) => void;
}

function ColorPickerDialog({
  open,
  onClose,
  handleColorChange,
}: ColorPickerDialogProps) {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('');
  const handleSubmit = () => {
    onClose();
    handleColorChange(color);
  };

  const handleChange = (color: any) => {
    setColor(color.hex);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: { xs: '328px', md: '600px' },
          boxShadow: 'var(--shadow-l7)',
          borderRadius: '16px',
          margin: { xs: 'auto' },
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        {t("Color.ChooseColor")}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: '16px',
        }}
      >
        <ChromePicker disableAlpha color={color} onChange={handleChange} />
      </DialogContent>
      <DialogActions
        sx={{
          padding: '22px 24px 16px',
        }}
      >
        <Button
          variant="text"
          sx={{ minWidth: '96px' }}
          onClick={() => onClose()}
        >
          {t("Global.Cancel")}
        </Button>
        <Button
          variant="contained"
          sx={{ minWidth: '96px' }}
          onClick={() => handleSubmit()}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ColorPickerDialog;
