import { useState } from 'react';
import type { EditProfileFormProps } from './formPorps';
import { Box, Button, Chip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import FormField from 'app/components/FormField';
import { useFormContext } from 'react-hook-form';
import styles from './EditProfileForm.module.css';
import { HOBBIES_OPTIONS, THEME_OPTIONS } from 'constants/editProfile';
import Container from 'app/components/Container';
import clsx from 'clsx';
import ColorPickerDialog from '../ColorPickerDialog';
import CircleIcon from '@mui/icons-material/Circle';
import { AdvisorProfileThemeProvider } from 'app/components/AdvisorProfile/AdvisorProfileThemeProvider';
import { ReactComponent as AdvisorProfileBottomGraphics } from 'app/assets/images/advisor_profile/main_bottom.svg';
import { getMainColorFromThemeColor } from 'app/components/AdvisorProfile/AdvisorProfile';
import { getReferralLink, ReferralMedium } from 'utils/referral';
import VSpace from 'app/components/VSpace';
import { toast } from 'react-toastify';

function Social({ back, next, userId }: EditProfileFormProps) {
  const { t } = useTranslation();
  const { control, trigger, getValues, setValue, watch } = useFormContext();
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  // 為了 Rerender Chip
  const watchHobbies = watch('hobbies', []);
  const watchThemeColor = watch('themeColor', 'blue');
  const watchCustomeThemeColor = watch('customThemeColor', null);

  const handleNextOnClick = async () => {
    const validateResult = await trigger([
      'hobbies',
      'customizedHobby',
      'familiarPlaces',
      'university',
      'secondarySchool',
      'otherHashtags',
      'instagram',
      'facebookLink',
      'linkedinLink',
      'realLinkedinLink',
      'themeColor',
    ]);
    if (validateResult) {
      next!();
    } else {
      const error = t('AdviserProfile.Error.Required');
      toast.error(error);
    }
  };

  const handleHobbiesChipOnClick = val => {
    const hobbiesArr = getValues('hobbies');
    if (hobbiesArr.includes(val)) {
      setValue(
        'hobbies',
        hobbiesArr.filter(tag => tag !== val),
      );
    } else {
      if (hobbiesArr.length > 5) {
        return;
      }
      setValue('hobbies', [...hobbiesArr, val]);
    }
  };

  const handlePFOnClick = () => {
    setValue(
      'linkedinLink',
      getReferralLink({
        medium: ReferralMedium.LINK,
        target: userId || '',
      }),
    );
  };

  const handleThemeColorChange = () => {
    setValue('themeColor', watchCustomeThemeColor);
  };

  const handleColorPick = color => {
    setValue('themeColor', color);
    setValue('customThemeColor', color);
  };

  const profileMainColor =
    getMainColorFromThemeColor(watchThemeColor) || watchCustomeThemeColor;
  return (
    <>
      <div className={styles.container}>
        <Container
          sx={{
            padding: { xs: '16px', md: '24px' },
            marginBottom: '16px',
          }}
        >
          <>
            <Typography variant="subtitle2" color="var(--color-text-primary)">
              {t('AdviserProfile.Hobbies')}{' '}
              <span className={styles.required}>*</span>
              <span className={styles.remark}>
                ({t('AdviserProfile.Select')}{' '}
                <span className={styles.required}>
                  {t('AdviserProfile.UpTo6InTotal')}
                </span>
              </span>
              )
            </Typography>
            <Box
              sx={{
                marginTop: '16px',
              }}
            >
              {HOBBIES_OPTIONS.map(option => (
                <Chip
                  key={option.value}
                  className={clsx(
                    watchHobbies.includes(option.value)
                      ? styles.inactive
                      : undefined,
                  )}
                  label={t(`AdviserProfile.Hobby.${option.value}`)}
                  variant="outlined"
                  sx={{
                    margin: '0 8px 8px 0',
                  }}
                  onClick={() => handleHobbiesChipOnClick(option.value)}
                />
              ))}
            </Box>
            <Box style={{ maxWidth: 200 }}>
              <FormField
                type="text"
                label={t('AdviserProfile.CustomizedHobby')}
                name="customizedHobby"
                control={control}
              />
            </Box>
          </>
        </Container>
        <Container
          sx={{
            padding: { xs: '16px', md: '24px' },
            marginBottom: '16px',
          }}
        >
          <>
            <Typography
              variant="subtitle2"
              color="var(--color-text-primary)"
              sx={{
                marginBottom: '16px',
              }}
            >
              🙌🏻 {t('AdviserProfile.Resonance')}
            </Typography>
            <Box className={styles.fieldsGroup}>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.FamiliarPlaces')}
                  name="familiarPlaces"
                  placeholder={t('AdviserProfile.Placeholder.FamiliarPlaces')}
                  control={control}
                />
              </Box>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.University')}
                  name="university"
                  placeholder={t('AdviserProfile.Placeholder.University')}
                  control={control}
                />
              </Box>
            </Box>
            <Box className={styles.fieldsGroup}>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.SecondaryPrimarySchool')}
                  name="secondarySchool"
                  placeholder={t(
                    'AdviserProfile.Placeholder.SecondaryPrimarySchool',
                  )}
                  control={control}
                />
              </Box>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.OtherHashtags')}
                  name="otherHashtags"
                  placeholder={t('AdviserProfile.Placeholder.OtherHashtags')}
                  control={control}
                />
              </Box>
            </Box>

            <Typography
              variant="subtitle2"
              color="var(--color-text-primary)"
              sx={{
                marginBottom: '16px',
              }}
            >
              📱 {t('AdviserProfile.SocialPlatforms')}
            </Typography>
            <Box className={styles.fieldsGroup}>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.InstagramAccount')}
                  name="instagram"
                  placeholder="portfoplus"
                  control={control}
                />
              </Box>
              <Box>
                <FormField
                  type="text"
                  label={t('AdviserProfile.FacebookLink')}
                  name="facebookLink"
                  placeholder="www.facebook.com/portfoplus"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={styles.hasBtnFieldsContainer}>
              <FormField
                type="text"
                label={t('AdviserProfile.MyWebpage')}
                name="linkedinLink"
                placeholder="www.mywebsite.com"
                control={control}
              />
            </Box>
            <FormField
              type="text"
              label={t('AdviserProfile.LinkedinLink')}
              name="realLinkedinLink"
              placeholder="www.linkedin.com/company/portfoplus"
              control={control}
            />
          </>
        </Container>
        <Container
          sx={{
            padding: { xs: '16px', md: '24px' },
          }}
        >
          <>
            <Typography
              variant="subtitle2"
              color="var(--color-text-primary)"
              sx={{
                marginBottom: '8px',
              }}
            >
              {t('AdviserProfile.ThemeColor')}
            </Typography>
            <Typography
              variant="body1"
              color="var(--color-text-primary)"
              sx={{
                marginBottom: '24px',
              }}
            >
              {t('AdviserProfile.ThemeColorDescription')}
            </Typography>
            <Box
              sx={{
                maxWidth: { xs: '296px', md: '498px' },
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <AdvisorProfileThemeProvider mainColor={profileMainColor}>
                <AdvisorProfileBottomGraphics style={{ width: '100%' }} />
              </AdvisorProfileThemeProvider>
              <VSpace size={2} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: { md: '224px', xs: '260px' },
                  margin: 'auto',
                }}
              >
                <FormField
                  type="radioCard"
                  label=""
                  name="themeColor"
                  noBorder
                  control={control}
                  options={THEME_OPTIONS}
                />
                {watchCustomeThemeColor && (
                  <Box
                    className={clsx(
                      watchThemeColor === watchCustomeThemeColor
                        ? styles.selected
                        : undefined,
                    )}
                    sx={{
                      marginLeft: '10px',
                    }}
                  >
                    <CircleIcon
                      sx={{
                        width: '28px',
                        height: '28px',
                        color: watchCustomeThemeColor,
                      }}
                      onClick={handleThemeColorChange}
                    />
                  </Box>
                )}
                {/* <AddCircleOutlineIcon
                  sx={{
                    ml: { md: 1, xs: '10px' },
                    width: 28,
                    height: 28,
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(true)}
                /> */}
              </Box>
            </Box>
            <VSpace size={2} />
            <Box
              className={styles.footer}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                sx={{
                  minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                  boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
                }}
                onClick={back}
              >
                {t('Global.Back')}
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                  boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
                }}
                onClick={handleNextOnClick}
              >
                {t('Global.Next')}
              </Button>
            </Box>
          </>
        </Container>
      </div>
      <ColorPickerDialog
        handleColorChange={handleColorPick}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

export default Social;
