import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import {
  MoreVertRounded,
  CloseRounded,
  EditRounded,
  GroupRounded,
  ListAltRounded,
  ShareRounded,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useAdvisorProfileTheme } from 'app/components/AdvisorProfile/AdvisorProfileThemeProvider';
import { useTranslation } from 'react-i18next';
import { AdviserActionFABProps } from './AdviserActionFAB.types';
import { redirectToPFP } from 'app/route/route';
import { useNavigate } from 'react-router-dom';
import { AdvisorShareModal } from '../AdvisorProfile/ShareButton';
import ClientReplyFormModal from '../../components/AdvisorProfile/ClientReplyFormModal';
import PaidFeatureAlert from '../PaidFeatureAlert/PaidFeatureAlert';
import { PaidFeatureAlertType } from '../PaidFeatureAlert/PaidFeatureAlert.types';
import DiamondTrailing from '../DiamondTrailing/DiamondTrailing';
import LikeAnimation from '../LikeAnimation/LikeAnimation';

const useTooltipClass = makeStyles(() => ({
  staticTooltipLabel: {
    backgroundColor: 'transparent !important',
    padding: '0px !important',
  },
}));

function AdviserActionFAB({
  open,
  onOpen,
  onClose,
  handlers,
  advisor,
  plan,
}: AdviserActionFABProps) {
  const { mainColor } = useAdvisorProfileTheme();
  const TooltipClass = useTooltipClass();
  const { t } = useTranslation();
  const n = useNavigate();
  const [advisorShareModalOpen, setAdvisorShareModalOpen] =
    React.useState(false);
  const [clientReplyFormModalOpen, setClientReplyFormModalOpen] =
    React.useState(false);

  const FilledSpeedDialAction = styled(SpeedDialAction)(() => ({
    backgroundColor: mainColor,
    '&:hover': {
      backgroundColor: mainColor,
    },
  }));

  const FilledTooltipText = styled('div')(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.getContrastText(mainColor || '#000000'),
    padding: '4px 8px',
    margin: '0px',
    borderRadius: '4px',
    maxWidth: 'fit-content',
    backgroundColor: mainColor,
  }));
  const OutlinedTooltipText = styled('div')(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '4px 8px',
    margin: '0px',
    borderRadius: '4px',
    maxWidth: 'fit-content',
    backgroundColor: theme.palette.background.default,
  }));

  return (
    <LikeAnimation
      color="#ffffff"
      render={anim => (
        <PaidFeatureAlert
          plan={plan}
          type={PaidFeatureAlertType.info}
          render={(onAlert, shouldShowAlert) => {
            const actions = [
              {
                icon: (
                  <ShareRounded
                    sx={{
                      color: mainColor,
                    }}
                  />
                ),
                name: 'share',
                handler:
                  handlers?.share || (() => setAdvisorShareModalOpen(true)),
                label: t('AdviserActionFAB.label.share'),
                filled: false,
              },
              {
                icon: <EditRounded sx={{ color: mainColor }} />,
                name: 'edit',
                handler:
                  handlers?.edit ||
                  (() => {
                    redirectToPFP(n, '/linker/my-details');
                  }),
                label: t('AdviserActionFAB.label.edit'),
                filled: false,
              },
              {
                icon: <ListAltRounded sx={{ color: mainColor }} />,
                name: 'reply-form',
                handler:
                  handlers?.replyForm ||
                  (() => {
                    if (shouldShowAlert) {
                      onAlert();
                    } else {
                      setClientReplyFormModalOpen(true);
                    }
                  }),
                label: t('AdviserActionFAB.label.reply-form'),
                filled: false,
                paid: true,
              },

              {
                icon: <GroupRounded sx={{ color: mainColor }} />,
                name: 'leads',
                handler:
                  handlers?.leads ||
                  (() => {
                    if (shouldShowAlert) {
                      onAlert();
                    } else {
                      redirectToPFP(n, '/referral_records');
                    }
                  }),
                label: t('AdviserActionFAB.label.leads'),
                filled: false,
                paid: true,
              },
              {
                icon: anim,
                name: 'likes',
                handler:
                  handlers?.likes ||
                  (() => {
                    if (shouldShowAlert) {
                      onAlert();
                    } else {
                      setClientReplyFormModalOpen(true);
                    }
                  }),
                label: t('AdviserActionFAB.label.likes', {
                  count: advisor.likes,
                }),
                filled: true,
                paid: true,
              },
            ];

            return (
              <>
                <AdvisorShareModal
                  open={advisorShareModalOpen}
                  onClose={() => setAdvisorShareModalOpen(false)}
                  advisor={advisor}
                />
                <ClientReplyFormModal
                  advisor={advisor}
                  show={clientReplyFormModalOpen}
                  onClose={() => setClientReplyFormModalOpen(false)}
                />

                <SpeedDial
                  open={open}
                  ariaLabel="Adviser Info Page Actions"
                  sx={{
                    '& .MuiSpeedDial-fab': {
                      backgroundColor: mainColor,
                      '&:hover': {
                        backgroundColor: mainColor,
                      },
                    },
                  }}
                  icon={
                    <SpeedDialIcon
                      icon={<MoreVertRounded />}
                      openIcon={<CloseRounded />}
                    />
                  }
                  onOpen={onOpen}
                  onClose={onClose}
                >
                  {actions.map(action => {
                    const props = {
                      classes: TooltipClass,
                      key: action.name,
                      icon: action.icon,
                      tooltipTitle: action.filled ? (
                        <FilledTooltipText
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white',
                          }}
                        >
                          <DiamondTrailing
                            focusingAdviserId={!!action.paid}
                            sx={{ marginRight: '12px' }}
                          />
                          {action.label}
                        </FilledTooltipText>
                      ) : (
                        <OutlinedTooltipText
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <DiamondTrailing
                            sx={{ marginRight: '12px' }}
                            focusingAdviserId={!!action.paid}
                          />
                          {action.label}
                        </OutlinedTooltipText>
                      ),
                      onClick: action.handler,
                      tooltipOpen: true,
                    };
                    if (action.filled) {
                      return <FilledSpeedDialAction {...props} />;
                    } else {
                      return <SpeedDialAction {...props} />;
                    }
                  })}
                </SpeedDial>
              </>
            );
          }}
        />
      )}
    />
  );
}

export default AdviserActionFAB;
