import React, { Fragment, useRef, forwardRef } from 'react';
import { BoldButton, ItalicButton, UnderlineButton } from 'draft-js-buttons';
import FontSizeButton from '../FontSizeButton/FontSizeButton';
import ColorButton from '../ColorButton';
import EmojiButton from '../EmojiButton';
import BlockStyleControls from '../BlockStyleControls';
import { RichUtils } from 'draft-js';
import { Box } from '@mui/material';
import editorStyles from './EditorToolBar.module.css';
import DraftEditorToolBarButtonWrapper from '../DraftEditorToolBarButtonWrapper/DraftEditorToolBarButtonWrapper';

interface MyToolbarProps {
    styles: any;
    editorState: any;
    onToggle: (blockType: string) => void;
    onChange: (editorState: any) => void;
    textAlign?: string;
    image?: any;
    list?: any;
    undo?: any;
    editorRef?: React.RefObject<any>;
    plugins?: any;
    [key: string]: any;
}

const EditorToolsBar = forwardRef((props: MyToolbarProps, ref: any) => {
    const {
        editorState,
        onToggle,
        onChange,
        textAlign,
        image,
        list,
        undo,
        editorRef,
        plugins,
        readOnly,
        ...rest
    } = props;

    const toolbarRef = useRef(null);

    const { toolbar: toolbarPlugin, image: imagePlugin } = plugins;
    const Toolbar = (toolbarPlugin && toolbarPlugin.Toolbar) || (() => null);

    function toggleBlockType(blockType: string) {
        const nextState = RichUtils.toggleBlockType(editorState, blockType);
        onChange(nextState);
    }

    return (
        <div ref={toolbarRef} className={editorStyles['toolbar-wrapper']}>
            <Toolbar {...rest}>
                {externalProps => {
                    externalProps.setEditorState = onChange;
                    externalProps.getEditorState = () => editorState;
                    return (
                        <Fragment>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <DraftEditorToolBarButtonWrapper active={editorState.getCurrentInlineStyle().has('BOLD')}>
                                    <BoldButton {...externalProps} />
                                </DraftEditorToolBarButtonWrapper>
                                <DraftEditorToolBarButtonWrapper active={editorState.getCurrentInlineStyle().has('ITALIC')}>
                                    <ItalicButton
                                        {...externalProps} />
                                </DraftEditorToolBarButtonWrapper>
                                <DraftEditorToolBarButtonWrapper active={editorState.getCurrentInlineStyle().has('UNDERLINE')}>
                                    <UnderlineButton {...externalProps} />
                                </DraftEditorToolBarButtonWrapper>
                                <DraftEditorToolBarButtonWrapper active={true}>
                                    <FontSizeButton
                                        editorState={editorState}
                                        onChange={onChange}
                                        fontSizeStyles={props.styles.fontSize}
                                    />
                                </DraftEditorToolBarButtonWrapper>
                                <DraftEditorToolBarButtonWrapper active={undefined}>
                                    <ColorButton
                                        editorRef={editorRef}
                                        editorState={editorState}
                                        colorStyles={props.styles.color}
                                        onChange={onChange}
                                        disabled={readOnly || false}
                                        {...externalProps}
                                    />
                                </DraftEditorToolBarButtonWrapper>
                                <DraftEditorToolBarButtonWrapper active={true}>
                                    <EmojiButton
                                        editorState={editorState}
                                        onChange={onChange}
                                        disabled={readOnly || false}
                                        {...externalProps}
                                    />
                                </DraftEditorToolBarButtonWrapper>
                                <BlockStyleControls
                                    disabled={readOnly || false}
                                    editorState={editorState}
                                    onToggle={toggleBlockType}
                                    {...externalProps}
                                />
                            </Box>
                        </Fragment>
                    );
                }}
            </Toolbar>
        </div>
    );
});

export default EditorToolsBar;
