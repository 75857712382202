import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Appointment } from './types';
import { TimeslotType } from 'services/timeslot/types';
import { GenericResponse } from 'services/apiTypes';

export * from './types';
export const appointmentAPI = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    myAppointment: builder.query<GenericResponse<Appointment[]>, TimeslotType>({
      query: type => `/appointment?status=Active`,
    }),
    newAppointment: builder.mutation<GenericResponse<Appointment[]>, String>({
      query: timeslot => {
        return {
          url: '/appointment/next',
          method: 'POST',
          body: {
            timeslot: timeslot,
          },
        };
      },
    }),
    rearrangeAppointment: builder.mutation<
      GenericResponse<Appointment[]>,
      { appointment: string; timeslot: string }
    >({
      query: ({ appointment, timeslot }) => {
        return {
          url: `/appointment/${appointment}/rearrange`,
          method: 'POST',
          body: {
            timeslot: timeslot,
          },
        };
      },
    }),
    cancelAppointment: builder.mutation<GenericResponse<Appointment[]>, string>(
      {
        query: appointment => {
          return {
            url: `/appointment/${appointment}/cancel`,
            method: 'POST',
          };
        },
      },
    ),
  };
};
