import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { SelectOption } from './FormField';
import HSpace from './HSpace';
import styles from './RadioCards.module.css';

export interface RadioCardsProps {
  options: SelectOption[];
  value?: string;
  onChange?: (value: string) => void;
  noBorder?: boolean;
}

export default function RadioCards({
  value,
  options,
  onChange,
  noBorder,
}: RadioCardsProps) {
  const currentValue = value;
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.radioCardsContainer}>
      {options.map(({ icon, label, value }) => (
        <div
          key={value.toString()}
          className={clsx(
            noBorder ? styles.themeOptions : styles.radioCard,
            currentValue === value ? styles.selectedCard : undefined,
          )}
          onClick={() => onChange && onChange(value)}
        >
          {icon}
          <HSpace size={1} />
          <Typography variant="body2">
            {(label && i18n.exists(label) && t(label)) || label}
          </Typography>
        </div>
      ))}
    </div>
  );
}
