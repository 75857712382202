import { motion } from 'framer-motion';
import { useRef } from 'react';
import { AnimatableProps } from './Animatable.types';

export * from './Animatable.types';
export * from './Animatable.animations/Animatable.animations';
export * from './Animatable.component/AnimatableSelect/AnimatableSelect';

function Animatable(props: AnimatableProps) {
  const { animations, builder, initialStage, currentStage } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const animation = animations[currentStage]!;
  return (
    <motion.div
      ref={elementRef}
      animate={animation}
      initial={initialStage}
      onClick={props.onClick}
    >
      {builder?.({
        animation: animations[currentStage ?? initialStage]!,
        ref: elementRef,
      })}
    </motion.div>
  );
}

export default Animatable;
