/**
 * Asynchronously loads the component for CreateSamplePage
 */

import { lazyLoad } from 'utils/loadable';

export const AvailabeTimeslotFrame = lazyLoad(
  () => import('./index'),
  module => module.default,
);
