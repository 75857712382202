import {
    Button,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TrHeader } from '../../../PaidFeatureAlert.types';
import VSpace from 'app/components/VSpace';
import BottomOrDialog from 'app/components/BottomOrDialog/BottomOrDialog';
import { ArrowRightAlt, InfoOutlined } from '@mui/icons-material';
import HSpace from 'app/components/HSpace';
import styles from './InfoAlert.module.css';
import HeadDraft from './HeadDraft';
import { trackMilestoneEvent } from 'hooks/useTracking/services/trackEvent/trackMilestoneEvent';
import { getChannelApiEndpoint } from 'app/components/ContactPortfoplus/ContactPortfoplus.types';
import { Channel, trackAdvisorContactStarted } from 'hooks/hooks';

function TeamFeatureAlertDialog({
    open,
    onClose,
    onCTA,
    alertMessage,
    alertTitle,
}: {
    open: boolean;
    onCTA?: () => void;
    onClose: () => void;
    alertMessage?: string;
    alertTitle?: string;
}) {
    const [t] = useTranslation();

    return (
        <BottomOrDialog open={open} onClose={onClose}>
            <VSpace size={2} />
            <Box className={styles.paidFeatureAlert} alignContent={'center'}>
                <HeadDraft onClose={onClose} />
                <DialogTitle className={styles.heading}>
                    {alertTitle ||
                        t([TrHeader, 'TeamFeatureAlertDialog', 'heading'].join('.'))}
                </DialogTitle>
                <DialogContent>
                    <Box display="flex">
                        <InfoOutlined />
                        <HSpace size={1} />
                        <Typography variant="body1" className={styles.description}>
                            {alertMessage ||
                                t(
                                    [TrHeader, 'TeamFeatureAlertDialog', 'description'].join('.'),
                                )}
                        </Typography>
                    </Box>
                    <VSpace size={2} />
                    <Box style={{ textAlign: "center" }}>
                        {/* <iframe
                            src="https://www.youtube.com/embed/cOTqaXrC4uU"
                            style={{
                                aspectRatio: "9 / 16",
                                width: "100%",
                                height: isMobile ? "60vh" : "40vh",
                                border: "none",
                            }}>
                        </iframe> */}
                        <img src="/images/illustrations/team_success.svg" alt="team-aff" width="100%" />
                    </Box>
                    <VSpace size={2} />
                    <Button
                        variant="contained"
                        onClick={
                            onCTA ||
                            (() => {
                                trackMilestoneEvent({
                                    type: 'Team Upgrade Started',
                                    event_properties: {
                                        Success: true,
                                    },
                                });
                                onClose();
                                // if (isFrameCheck()) {
                                //     postMessageToParent({
                                //         success: true,
                                //         code: 'SHOW_UPGRADE_MODAL',
                                //         message: 'Show Upgrade Modal',
                                //     });
                                // } else {
                                //     redirectToPFP(n, '/home?upgrade=true');
                                // }
                                const _message = "Hi, I'm interested in upgrading to the Team plan.";
                                const api = getChannelApiEndpoint(Channel.WhatsApp, _message);
                                console.log('api: ', api);
                                if (api) {
                                    trackAdvisorContactStarted({
                                        Channel: Channel.WhatsApp,
                                    });
                                    window.open(api, '_blank');
                                }
                            })
                        }
                        fullWidth
                        sx={{ minHeight: '48px' }}
                    >
                        <Typography variant="button" className={styles.firstCTA}>
                            {t([TrHeader, 'TeamFeatureAlertDialog', 'cta'].join('.'))}
                        </Typography>
                        <ArrowRightAlt />
                    </Button>
                </DialogContent>
            </Box>
        </BottomOrDialog>
    );
}

export default TeamFeatureAlertDialog;
