import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Advisor } from 'types';
import {
  MediaConfig,
  MediaUploadConfigParams,
  SubmitReferral,
  SubmitReferralResponse,
  TeamLogoNews,
  UpdateAdviserProfileResponse,
  UploadImageResponse,
  UploadMediaParams,
  User,
  GenericResponse,
} from './apiTypes';
import { getUploadMediaFormData } from './utils';
import { signupAPI } from './signup/signup';
import { cardAPI } from './card/card';
import { timeslotAPI } from './timeslot/timeslot';
import { appointmentAPI } from './appointment/appointment';
import { subscription } from './subscription/subscription';
import { teams } from './teams/teams';
import { track } from './track/track';

export * from './signup/signup';
export * from './card/card';
export * from './timeslot/timeslot';
export * from './appointment/appointment';
export * from './teams/teams';
export * from './track/track';

const apiBaseUrl = process.env.REACT_APP_PFPLUS_BACKEND_URL as string;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    credentials: 'include',
  }),
  tagTypes: ['Advisor'],
  endpoints: builder => ({
    ...appointmentAPI(builder),
    ...timeslotAPI(builder),
    ...signupAPI(builder),
    ...cardAPI(builder),
    ...teams(builder),
    ...track(builder),
    authenticateUser: builder.query<User, void>({
      query: () => ({
        url: 'auth',
        method: 'POST',
      }),
    }),
    logoutUser: builder.mutation<GenericResponse<undefined>, void>({
      query: () => ({
        url: 'logoutUser',
        method: 'POST',
      }),
    }),
    getAdviserProfilePlusById: builder.query<
      Advisor | null,
      { id: string; search?: { [key: string]: any } }
    >({
      query: props => {
        const { id, search } = props;
        const _search = {
          ...search,
        };

        return `adviser-profile-plus/${id}${
          _search ? `?${new URLSearchParams(_search).toString()}` : ''
        }`;
      },
      providesTags: ['Advisor'],
    }),
    getAdviserTeamLogoNews: builder.query<TeamLogoNews, void>({
      query: () => `getUserTeamLogoNews`,
    }),
    likeAdviserProfilePlusById: builder.mutation<Advisor | null, string>({
      query: id => `adviser-profile-plus/like/${id}`,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getAdviserProfilePlusById',
            { id: id },
            advisor => {
              if (advisor) {
                Object.assign(advisor, {
                  likes: advisor.likes + 1,
                });
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    submitAdvisorReferral: builder.mutation<
      SubmitReferralResponse,
      SubmitReferral
    >({
      query: body => ({
        url: 'submitReferral',
        method: 'POST',
        body,
      }),
    }),
    getWalletPass: builder.query<
      any,
      { platform: string; language: string }
    >({
      query: props => {
        return `wallet-pass/${props.platform}?language=${props.language}`;
      },
    }),
    // Other endpoints
    uploadImage: builder.mutation<UploadImageResponse, FormData>({
      query: body => ({
        url: 'uploadImage',
        method: 'POST',
        body,
      }),
    }),
    getMediaUploadConfig: builder.query<MediaConfig, MediaUploadConfigParams>({
      query: params => ({
        url:
          'media/upload-config?' +
          new URLSearchParams({
            type: params.type,
            resource_id: params.resourceId,
            content_type: params.contentType,
            content_length: params.contentLength,
            filename: params.filename,
          }),
      }),
    }),
    uploadMedia: builder.mutation<void, UploadMediaParams>({
      query: uploadMediaParams => ({
        url: uploadMediaParams.mediaConfig.url,
        method: 'POST',
        body: getUploadMediaFormData(uploadMediaParams),
      }),
    }),
    updateAdviserProfile: builder.mutation<UpdateAdviserProfileResponse, any>({
      query: body => {
        console.log('updateAdviserProfile body: ', body);
        return {
          url: 'updateUserDetails',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Advisor'],
    }),
  }),
});

export const subscriptionAPI = createApi({
  reducerPath: 'subscriptionAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PFPLUS_SUBSCRIPTION_SERVICE_URL,
    credentials: 'include',
  }),
  endpoints: builder => ({
    ...subscription(builder),
  }),
});

export const {
  useAuthenticateUserQuery,
  useGetAdviserProfilePlusByIdQuery,
  useLikeAdviserProfilePlusByIdMutation,
  useSubmitAdvisorReferralMutation,
  useGetAdviserTeamLogoNewsQuery,
  useUploadImageMutation,
  useUpdateAdviserProfileMutation,
  useUploadMediaMutation,
  useLazyGetMediaUploadConfigQuery,
  useLazyGetWalletPassQuery
} = api;
