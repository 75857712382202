import { Box, Typography, Card, Button } from '@mui/material';
import Portfoplus, { LogoType } from '../Portfoplus';
import PageLayout from '../PageLayout/PageLayout';
import { useTranslation } from 'react-i18next';
import VSpace from '../VSpace';
import style from './Landing.module.css';
import { useNavigate } from 'react-router-dom';
import { redirectTo } from 'app/route/route';

function LandingCarditem({ title, content }) {
  return (
    <Card
      sx={{
        backgroundColor: '#f5f5f5',
        borderRadius: '16px',
        boxShadow: '2px 5px 10px 10px rgba(0,0,0,0.2)',
        maxWidth: 'calc(100vw - 48px)',
        margin: 'auto',
      }}
    >
      <Box padding={'12px'}>
        <Typography
          variant="h5"
          color={'#333'}
          sx={{
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontSize={'16px'}
          color={'#333'}
          padding={'5px 24px'}
        >
          {content}
        </Typography>
      </Box>
    </Card>
  );
}

function Landing({ onNext }: { onNext?: () => void }) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <PageLayout
        options={{
          fullHeight: true,
          fullWidth: true,
          noPadding: true,
        }}
      >
        <Box sx={{ overflowX: 'hidden' }}>
          <VSpace size={5} />
          <Box sx={{ padding: '0px 24px' }} maxWidth="50vw">
            <Portfoplus type={LogoType.Fit} />
          </Box>
          <VSpace size={5} />
          {/* 全新數位名片，讓專業形象更出色！ */}
          <Box>
            <Typography variant="h4" color={'#333'}>
              {/* {t('Landing.subHeadline')} */}
              {'讓專業'}
            </Typography>
            <Typography
              variant="h1"
              color="primary"
              sx={{
                fontWeight: 400,
                textDecoration: 'underline 28px #eee',
              }}
            >
              {/* {t('Landing.subHeadline')} */}
              {'更出色'}
            </Typography>
          </Box>
          <Box>
            <img
              src="/images/backgrounds/how_to_use_NFC_business_cards_on_iPhone-1-removebg.png"
              className={style.image1}
              alt="placeholder"
            />
          </Box>
          <Typography
            variant="h4"
            color={'#333'}
            sx={{
              textAlign: 'center',
            }}
          >
            {/* {t('Landing.subHeadline')} */}
            {' Linker 數位名片 '}
          </Typography>
          <VSpace size={2} />
          <Typography
            variant="body2"
            fontSize={'16px'}
            color={'#333'}
            padding={'5px 24px'}
          >
            {t('Landing.what-is-linker.content')}
          </Typography>
          <Typography></Typography>
          <VSpace size={5} />
          <Typography
            variant="h4"
            color={'#333'}
            sx={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            {t('Landing.how-linker-works.title')}
          </Typography>
          <VSpace size={3} />
          <LandingCarditem
            title={t('Landing.how-linker-works.contents.0.title')}
            content={t('Landing.how-linker-works.contents.0.content')}
          />
          <VSpace size={3} />
          <LandingCarditem
            title={t('Landing.how-linker-works.contents.1.title')}
            content={t('Landing.how-linker-works.contents.1.content')}
          />
          <VSpace size={3} />
          <LandingCarditem
            title={t('Landing.how-linker-works.contents.2.title')}
            content={t('Landing.how-linker-works.contents.2.content')}
          />
          <VSpace size={5} />
        </Box>

        <Box
          sx={{
            padding: '24px',
          }}
        >
          <Typography variant="h4" color={'#333'} sx={{ textAlign: 'center' }}>
            {t('Landing.why-linker.title')}
          </Typography>
          <VSpace size={2} />
          <LandingCarditem
            title={t('Landing.why-linker.contents.0.title')}
            content={t('Landing.why-linker.contents.0.content')}
          />
          <VSpace size={3} />
          <LandingCarditem
            title={t('Landing.why-linker.contents.1.title')}
            content={t('Landing.why-linker.contents.1.content')}
          />
          <VSpace size={3} />
          <LandingCarditem
            title={t('Landing.why-linker.contents.2.title')}
            content={t('Landing.why-linker.contents.2.content')}
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            if (onNext) {
              onNext();
            } else {
              redirectTo(navigate, '/register');
            }
          }}
          sx={{
            position: 'fixed',
            bottom: '48px',
            width: 'calc(100% - 48px)',
            margin: 'auto',
            left: '24px',
          }}
        >
          {t('Landing.cta')}
        </Button>
        <VSpace size="96px" />
      </PageLayout>
    </>
  );
}

function LandingRedirect() {
  const REACT_APP_LINKER_LANDING_PAGE_URL = process.env.REACT_APP_LINKER_LANDING_PAGE_URL;
  redirectTo(useNavigate(), REACT_APP_LINKER_LANDING_PAGE_URL);
  return <></>;
}
export default LandingRedirect;
