import { Channel } from './events_properties/Channel';

export enum TrackableEventType {
  AdvisorContactSaved = 'Advisor Contact Saved',
  AdvisorContactStarted = 'Advisor Contact Started',
  ReferralStarted = 'Referral Started',
  FormSubmitted = 'Form Submitted',
}

export interface TrackableEventProps {
  Channel?: Channel;
  Feature?: String;
  [key: string]: any;
}
export type TrackableEvent = {
  event: TrackableEventType | String;
  props?: TrackableEventProps;
};
