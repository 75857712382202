import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import { enrichPageUrlPlugin } from '../useTracking/plugins/amp/enrichPageUrlPlugin';

// Advisor Contact Started {Channel="WhatsApp", Page Path, Feature=Linker Demo}
// Advisor Contact Started {Channel="Phone", Page Path, Feature=Linker Demo}
// Advisor Contact Started {Channel="Email", Page Path, Feature=Linker Demo}
// Advisor Contact Saved {Page Path, Feature=Linker Demo}
// Form Submitted {Name="Like", Page Path, Feature=Linker Demo}
// Referral Started {Channel="Whatsapp", Page Path, Feature=Linker Demo}

export * from './services/services';

export interface TrackingContext {
  amplitude: any | undefined;
  mixpanel: any | undefined;
  tagManager: any | undefined;
}

// const TrackingContextState = createContext<TrackingContext>({
//   amplitude: undefined,
//   mixpanel: undefined,
//   tagManager: undefined,
// });

const amplitudeDefaultTrackingSetting = {
  defaultTracking: {
    attribution: true,
    pageViews: true,
    sessions: true,
    formInteractions: true,
    fileDownloads: true,
  },
};

// const TrackingProvider = ({ children }) => {
//   const [clients, setTrackingClients] = useState<TrackingContext>({
//     amplitude: undefined,
//     mixpanel: undefined,
//     tagManager: undefined,
//   });
//   useEffect(() => {
//     const clients: TrackingContext = {
//       amplitude: undefined,
//       mixpanel: undefined,
//       tagManager: undefined,
//     };
//     const amp = process.env.REACT_APP_AMPLITUDE_API_KEY;
//     const mix = process.env.REACT_APP_MIXPANEL_API_KEY;
//     const tag = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

//     if (amp) {
//       amplitude.add(enrichPageUrlPlugin());
//       amplitude.init(amp, amplitudeDefaultTrackingSetting);
//     }
//     if (mix) {
//       clients.mixpanel = mixpanel.init(mix);
//     }
//     if (tag) {
//       TagManager.initialize({ gtmId: tag });
//       clients.tagManager = TagManager;
//     }
//     setTrackingClients(clients);
//   }, []);

//   return (
//     <TrackingContextState.Provider value={clients}>
//       {children}
//     </TrackingContextState.Provider>
//   );
// };

export function initTracking() {
  const amp = process.env.REACT_APP_AMPLITUDE_API_KEY;
  const mix = process.env.REACT_APP_MIXPANEL_API_KEY;
  const tag = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  if (amp) {
    amplitude.add(enrichPageUrlPlugin());
    amplitude.init(amp, amplitudeDefaultTrackingSetting);
  }
  if (mix) {
    mixpanel.init(mix);
  }
  if (tag) {
    TagManager.initialize({ gtmId: tag });
  }
}

function trackEvent(event: any) {
  if (event) {
    if (amplitude) {
      amplitude.logEvent(event);
    }
    if (mixpanel) {
      mixpanel.track(event);
    }
    if (TagManager) {
      TagManager.dataLayer({ dataLayer: event });
    }
  }
}

export function useTracking() {
  return trackEvent;
}

// export default TrackingProvider;
