export const featureMap = {
  'Linker Demo': ['/demo', '/debug'],
  Linker: ['/register', '/ca/:id', '/get-portfoplus'],
};

export const fallbackFeature = 'Linker';

export function getFeature(path: string): string {
  return (
    Object.entries(featureMap).find(([, value]) => {
      return value.includes(path);
    })?.[0] ?? fallbackFeature
  );
}
