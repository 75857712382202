import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  // InputAdornment,
  MenuItem,
  Select,
  Slider,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { type Control, Controller, type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import CustomThumbComponent from './CustomThumbComponent';
import styles from './FormField.module.css';
import RadioCards from './RadioCards';
import Tags from './Tags';
import VSpace from './VSpace';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { countryList } from 'utils/countryList';
import { COUNTRY_LABELS } from 'constants/countryLabels';
import 'react-phone-number-input/style.css';
import { forwardRef } from 'react';
import './FormField.css';
import { useIsMobile } from 'hooks/useBreakpoints/useBreakpoints';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
// import HSpace from './HSpace';

type FormFieldType =
  | 'select'
  | 'date'
  | 'text'
  | 'checkbox'
  | 'invertedCheckbox'
  | 'radioCard'
  | 'range'
  | 'tags'
  | 'numberSlider'
  | 'switch'
  | 'phoneNumber'
  | 'password';

const defaultInputProps = {
  // disableUnderline: true,
  style: {
    transform: 'none',
    transition: 'none', // Disable transition to prevent scaling effect
  },
};
export function FormFieldLabel({
  label,
  required,
  tips,
  tipsMethod,
  error,
}: {
  label: string;
  required?: boolean | undefined;
  tips?: string | undefined;
  tipsMethod?: Function;
  error?: FieldError;
}) {
  return (
    <div className={styles.formField}>
      <Typography
        variant="body1"
        sx={
          error && {
            color: 'var(--color-error-main)',
          }
        }
      >
        {label}{' '}
        {required ? (
          <Box
            component="span"
            sx={{
              fontSize: '16px',
              color: 'var(--color-error-main)',
              lineHeight: 1,
            }}
          >
            *
          </Box>
        ) : null}
      </Typography>
      {tips ? (
        tipsMethod ? (
          <InfoOutlinedIcon
            sx={{
              ml: 1,
              cursor: 'pointer',
            }}
            onClick={() => tipsMethod()}
          />
        ) : (
          <Tooltip title={tips} sx={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </Tooltip>
        )
      ) : null}
    </div>
  );
}

function FormErrorMessage({ message }: { message: string }) {
  return (
    <Typography
      component="p"
      sx={{
        fontSize: 'var(--fontSize-error-main)',
        color: 'var(--color-error-main)',
        paddingTop: '6px',
      }}
    >
      {message}
    </Typography>
  );
}

type SliderValue = number | number[];

export interface SelectOption<T = string> {
  icon?: string | JSX.Element;
  label?: string;
  labeli18nKey?: string;
  value: T;
}

const StyledSlider = styled(Slider)(() => ({
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid grey',
    borderRadius: 10,
    '& .custom-thumb-bar': {
      height: 9,
      width: 2,
      borderRadius: 1,
      backgroundColor: 'grey',
      marginLeft: 1,
      marginRight: 1,
    },
  },
}));

const PhoneInputField = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="standard"
      sx={{
        '& .MuiInput-root': { paddingLeft: '44px' },
      }}
    />
  );
});

export interface FormFieldProps {
  type: FormFieldType;
  label: string;
  placeholder?: string | React.ReactNode;
  options?: SelectOption<any>[];
  name?: string | undefined;
  required?: boolean | undefined;
  tips?: string | undefined;
  tipsMethod?: Function;
  control?: Control<any>;
  numberPrefix?: '<' | '>';
  min?: number;
  max?: number;
  step?: number;
  pickRandom?: boolean;
  customFieldButtonLabel?: string;
  customFieldButtonFunction?: Function;
  customFieldStyle?: object;
  hideLabel?: boolean;
  hideSpacing?: boolean;
  noBorder?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
  minRows?: number;
  language?: string;
  trailing?: React.ReactNode;
}

export default function FormField({
  type,
  label,
  placeholder,
  options,
  name,
  required,
  tips,
  tipsMethod,
  control,
  numberPrefix,
  min,
  max,
  step,
  pickRandom,
  customFieldButtonLabel,
  customFieldButtonFunction,
  customFieldStyle,
  hideLabel,
  trailing,
  hideSpacing,
  noBorder,
  readOnly,
  multiline,
  minRows,
  language,
}: FormFieldProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  if (type === 'text') {
    return (
      <Controller
        control={control}
        defaultValue={placeholder}
        name={name as string}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div className={styles.textFieldContainer}>
              {/* {!hideLabel && (
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
              />
            )} */}
              <TextField
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder as string}
                label={label}
                required={required}
                className={styles.textField}
                value={value}
                onChange={onChange}
                variant="standard"
                error={!!error?.type}
                helperText={error?.message ?? ''}
                size={isMobile ? 'small' : 'medium'}
                InputProps={{
                  inputProps: {
                    ...defaultInputProps,
                    readOnly,
                  },
                }}
                multiline={multiline}
                minRows={minRows}
                sx={{
                  '.MuiInput-root': {
                    paddingLeft: '0',
                  },
                  '& .MuiInputLabel-asterisk': {
                    color: 'var(--color-error-main)',
                  },
                }}
              />
              {!hideSpacing && <VSpace size={2} />}
            </div>
          );
        }}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <>
        <Box className={styles.fieldContainer} sx={customFieldStyle}>
          <Box sx={{ display: 'flex' }}>
            <FormFieldLabel
              label={label}
              required={required}
              tips={tips}
              tipsMethod={tipsMethod}
            />
            {trailing && <Box sx={{ paddingLeft: '8px' }}>{trailing}</Box>}
          </Box>
          <Controller
            control={control}
            name={name as string}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                onChange={readOnly ? undefined : onChange}
              />
            )}
          />
        </Box>
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'invertedCheckbox') {
    return (
      <>
        <Box className={styles.fieldContainer} sx={customFieldStyle}>
          <Grid container>
            <Grid item>
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
              />
            </Grid>
            {
              trailing && <Grid item sx={{ paddingLeft: '8px' }}>
                {trailing}
              </Grid>
            }

          </Grid>
          <Controller
            control={control}
            name={name as string}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={!value}
                onChange={event => {
                  if (!readOnly) {
                    event.target.checked = !event.target.checked;
                    onChange(event);
                  }
                }}
              />
            )}
          />
        </Box>
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'select') {
    if (!options)
      throw new Error('[FormField] Select type must have props: options');
    return (
      <Controller
        control={control}
        defaultValue={''}
        name={name as string}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <div className={styles.fieldLabelContainer}>
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
                error={error}
              />
              {customFieldButtonLabel && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() =>
                    customFieldButtonFunction && customFieldButtonFunction()
                  }
                >
                  {customFieldButtonLabel}
                </Button>
              )}
            </div>
            <Select
              variant="standard"
              label={placeholder}
              className={styles.selectField}
              error={!!error?.type}
              value={value}
              onChange={onChange}
              displayEmpty
              renderValue={value => {
                if (value === '') return placeholder;
                const option = options.find(o => o.value === value);
                if (!option) return value;
                return option.label || option.value;
              }}
            >
              {options.map(({ icon, label, value }) => (
                <MenuItem key={value as string} value={value as string}>
                  {icon}
                  {label}
                </MenuItem>
              ))}
            </Select>
            {error && <FormErrorMessage message={error?.message ?? ''} />}
            {!hideSpacing && <VSpace size={2} />}
          </>
        )}
      />
    );
  }

  if (type === 'date') {
    const sPerYear = 1000 * 60 * 60 * 24 * 365;

    return (
      <>
        {/* <FormFieldLabel
          label={label}
          required={required}
          tips={tips}
          tipsMethod={tipsMethod}
        /> */}
        <Controller
          control={control}
          name={name as string}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const now = Date.now();
            return (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={
                  language === 'zh-Hant-HK'
                    ? 'zh-hk'
                    : language === 'zh'
                      ? 'zh-cn'
                      : 'en'
                }
              >
                <DatePicker
                  className={styles.datePicker}
                  value={value}
                  onChange={onChange}
                  minDate={now - sPerYear * 100}
                  maxDate={now}
                  label={label}
                  inputFormat="YYYY-MM-DD"
                  disableMaskedInput
                  disableFuture
                  openTo="year"
                  renderInput={params => {
                    return (
                      <TextField
                        error={!!error?.type}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            ...defaultInputProps,
                          },
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiInput-root': { pl: 0 },
                          '& .Mui-error:after': { borderColor: 'transparent' },
                          '& .MuiInputLabel-asterisk': {
                            color: 'var(--color-error-main)',
                          },
                        }}
                        variant="standard"
                        {...params}
                      />
                    );
                  }}
                />
              </LocalizationProvider>)
          }}
        />
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'radioCard') {
    if (!options)
      throw new Error('[FormField] Radio card type must have props: options');
    return (
      <Controller
        control={control}
        name={name as string}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <div className={styles.fieldLabelContainer}>
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
              />
              {pickRandom && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    const randomIndex = Math.floor(
                      Math.random() * options.length,
                    );
                    onChange(options[randomIndex].value);
                  }}
                >
                  {t('Global.Random')}
                </Button>
              )}
            </div>
            <VSpace size={1} />
            <RadioCards
              options={options}
              value={value}
              onChange={onChange}
              noBorder={noBorder}
            />
            {error?.type && <FormErrorMessage message={error?.message ?? ''} />}
            {!hideSpacing && <VSpace size={2} />}
          </>
        )}
      />
    );
  }

  if (type === 'range') {
    return (
      <>
        <FormFieldLabel
          label={label}
          required={required}
          tips={tips}
          tipsMethod={tipsMethod}
        />
        <VSpace size={1} />
        <div className={styles.sliderContainer}>
          <Controller
            control={control}
            name={name as string}
            render={({ field: { onChange, value } }) => (
              <StyledSlider
                value={value as SliderValue}
                marks={(value as number[]).map(mark => ({
                  value: mark,
                  label: mark,
                }))}
                min={min}
                max={max}
                step={step}
                onChange={onChange}
                components={{
                  Thumb: CustomThumbComponent,
                }}
              />
            )}
          />
        </div>
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'numberSlider') {
    return (
      <>
        <Controller
          control={control}
          name={name as string}
          defaultValue={''}
          render={({ field }) => (
            <>
              <div className={styles.numberFormField}>
                <FormFieldLabel
                  label={label}
                  required={required}
                  tips={tips}
                  tipsMethod={tipsMethod}
                />
                <div className={styles.numberField}>
                  {!!numberPrefix && numberPrefix}
                  <NumericFormat
                    className={styles.number}
                    thousandSeparator={true}
                    inputMode="decimal"
                    pattern="[0-9]*"
                    value={field.value}
                  />
                </div>
              </div>
              <VSpace size={1} />
              <div className={styles.sliderContainer}>
                <StyledSlider
                  min={min}
                  max={max}
                  step={step}
                  components={{
                    Thumb: CustomThumbComponent,
                  }}
                  {...field}
                />
              </div>
            </>
          )}
        />
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'tags') {
    return (
      <>
        <Controller
          control={control}
          name={name as string}
          defaultValue={''}
          render={({ field }) => (
            <div>
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
              />
              <VSpace size={1} />
              <Tags
                tags={field.value}
                options={options}
                onChange={field.onChange}
              />
            </div>
          )}
        />
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'switch') {
    return (
      <>
        <Controller
          control={control}
          name={name as string}
          defaultValue={''}
          render={({ field: { onChange, value } }) => (
            <Box className={styles.switchField}>
              <Switch color="primary" checked={value} onChange={onChange} />
              <Typography variant="body1" color="var(--color-text-primary)">
                {label}
              </Typography>
            </Box>
          )}
        />
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'phoneNumber') {
    return (
      <>
        <Controller
          control={control}
          name={name as string}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Grid container>
                  <Grid item>
                    <FormFieldLabel
                      label={label}
                      required={required}
                      tips={tips}
                      tipsMethod={tipsMethod}
                      error={error}
                    />
                  </Grid>
                  <Grid >
                  </Grid>
                  {trailing && <Grid item sx={{ paddingLeft: '8px' }}>
                    {trailing}
                  </Grid>}
                </Grid>
                <PhoneInputWithCountry
                  defaultCountry="HK"
                  international
                  countries={countryList.labels}
                  control={control as any}
                  name={name as string}
                  value={value}
                  onChange={onChange}
                  error={!!error?.type}
                  inputComponent={PhoneInputField}
                  labels={COUNTRY_LABELS}
                />
                {error && <FormErrorMessage message={error?.message ?? ''} />}
              </>
            );
          }}
        />
        {!hideSpacing && <VSpace size={2} />}
      </>
    );
  }

  if (type === 'password') {
    return (
      <Controller
        control={control}
        name={name as string}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={styles.textFieldContainer}>
            {/* {!hideLabel && (
              <FormFieldLabel
                label={label}
                required={required}
                tips={tips}
                tipsMethod={tipsMethod}
              />
            )} */}

            <TextField
              InputLabelProps={{ shrink: true }}
              type="password"
              placeholder={(placeholder as string) || label}
              label={label}
              required={required}
              className={styles.textField}
              value={value}
              onChange={onChange}
              variant="standard"
              error={!!error?.type}
              helperText={error?.message ?? ''}
              size={isMobile ? 'small' : 'medium'}
              InputProps={{
                inputProps: {
                  ...defaultInputProps,
                  readOnly,
                },
              }}
              sx={{
                '.MuiInput-root': {
                  paddingLeft: '0',
                },
                '& .MuiInputLabel-asterisk': {
                  color: 'var(--color-error-main)',
                },
              }}
            />
            {!hideSpacing && <VSpace size={2} />}
          </div>
        )}
      />
    );
  }

  return (
    <>
      <FormFieldLabel
        label={label}
        required={required}
        tips={tips}
        tipsMethod={tipsMethod}
      />
      <TextField
        InputProps={{
          inputProps: {
            ...defaultInputProps,
            transform: 'none',
          },
        }}
        variant="standard"
      />
    </>
  );
}
