import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { TrackDirectUserProps, TrackDirectAnonymousProps } from './track.types';

export * from './track.types';

export const track = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    trackDirect: builder.mutation<any, TrackDirectUserProps>({
      query: ({ type, detail }) => {
        return {
          url: '/track',
          method: 'POST',
          body: {
            type: type,
            detail: detail,
          },
        };
      },
    }),
    trackDirectAnonymous: builder.mutation<any, TrackDirectAnonymousProps>({
      query: ({ target, type, detail }) => {
        return {
          url: '/trackAnonymous',
          method: 'POST',
          body: {
            adviserId: target,
            type: type,
            detail: detail,
          },
        };
      },
    }),
  };
};
