import { EditorState, ContentState, convertFromRaw } from 'draft-js';
export const formatEditorStateValue = value => {
  if (typeof value === 'object') {
    // if it is EditorState already
    if (value.getCurrentContent) {
      return value;
    } else if (value.getEntityMap) {
      return EditorState.createWithContent(value);
    } else {
      return EditorState.createWithContent(convertFromRaw(value));
    }
  } else if (typeof value === 'string') {
    try {
      // assume it is json-stringified object
      return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    } catch (error) {
      // if json parse error, it should be a plain text
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
  }
};
