export function HideFeature({
  render,
  shouldShowAlert,
}: {
  shouldShowAlert: boolean;
  render: (onAlert: () => void, shouldShowAlert: boolean) => JSX.Element;
}) {
  if (shouldShowAlert) {
    return <></>;
  } else {
    return render(() => {}, shouldShowAlert);
  }
}

export default HideFeature;
