import { IconButton, SxProps } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { redirectTo } from 'app/route/route';

export function Backbutton(props: { sx?: SxProps; target?: string }) {
  const navigate = useNavigate();
  return (
    <IconButton
      sx={{
        position: 'fixed',
        top: '12px',
        left: '12px',
        color: 'var(--color-gray-500)',
        '&:hover': {
          color: 'var(--color-gray-700)',
        },
        ...props.sx,
      }}
      onClick={
        props.target
          ? () => {
              redirectTo(navigate, props.target);
            }
          : () => {
              window.history.back();
            }
      }
    >
      <ArrowBackIcon />
    </IconButton>
  );
}

export default Backbutton;
