import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from 'react-i18next';
import { getAvatarList } from 'utils/getAvatarList';
import clsx from 'clsx';
import styles from './SelectAvatarDialog.module.css';

interface SelectAvatarDialogProps {
  userName: string;
  open: boolean;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  openUploadDialog: () => void;
  onClose: (value: string) => void;
}

function SelectAvatarDialog({
  userName,
  open,
  selectedValue,
  setSelectedValue,
  openUploadDialog,
  onClose,
}: SelectAvatarDialogProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleAvartarSelect = val => {
    setSelectedValue(val);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: { xs: '328px', md: '600px' },
          boxShadow: 'var(--shadow-l7)',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        <PersonOutlineOutlinedIcon
          sx={{
            mr: 1,
          }}
        />
        {t('Avatar.SelectAvatar')}
      </DialogTitle>
      <DialogContent
        sx={{
          width: { xs: '100%', md: '73%' },
          margin: 'auto',
          p: 0,
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            p: { xs: '16px 24px', md: 0 },
            justifyContent: 'center',
          }}
        >
          {getAvatarList().map(avatar =>
            avatar.selectable ? (
              <ListItem
                sx={{
                  display: 'inline-flex',
                  width: { xs: '25%', md: '20%' },
                  flex: { xs: '0 0 25%', md: '0 0 20%' },
                  p: 0,
                  mb: 2,
                  cursor: 'pointer',
                  justifyContent: 'center',
                }}
                key={avatar.val}
                onClick={() => handleAvartarSelect(avatar.val)}
              >
                <ListItemAvatar
                  className={clsx(
                    styles.avatarContainer,
                    selectedValue === avatar.val ? styles.active : null,
                  )}
                >
                  {avatar.content}
                </ListItemAvatar>
              </ListItem>
            ) : <Box key={avatar.val} />
          )}
        </List>
        <Divider
          sx={{ color: 'var(--color-grey-transparent)', marginBottom: '20px' }}
        >
          {t('Avatar.OrUpload')}
        </Divider>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CloudUploadOutlinedIcon
            sx={{
              fontSize: '36px',
              cursor: 'pointer',
            }}
            onClick={() => openUploadDialog()}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '42px 24px 16px',
        }}
      >
        <Button
          variant="text"
          sx={{
            p: 0,
          }}
          onClick={() => onClose(selectedValue)}
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          variant="text"
          sx={{
            p: 0,
          }}
          onClick={() => onClose(selectedValue)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectAvatarDialog;
