import { EditProfileFormProps } from './formPorps';
import { Box, Button, Chip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './EditProfileForm.module.css';

import {
  MAIN_LANGUAGES_OPTIONS,
  MY_TYPE_OPTIONS,
  WORK_ATTIUDE_OPTIONS,
  TREAT_PEOPLE_OPTIONS,
  LIFE_ATTITUDE_OPTIONS,
} from 'constants/editProfile';
import Container from 'app/components/Container';
import clsx from 'clsx';
import Editor from 'app/components/Editor/Editor';
import { toast } from 'react-toastify';

function AboutMe({ back, next }: EditProfileFormProps) {
  const { t } = useTranslation();
  const { trigger, getValues, setValue, watch } = useFormContext();
  // 為了 Rerender Chip
  const watchMainLanguages = watch('mainLanguages');
  const watchPersonalityTags = watch('personalityTags');

  const handleNextOnClick = async () => {
    const validateResult = await trigger(['mainLanguages', 'personalityTags']);
    if (validateResult) {
      next!();
    } else {
      const error = t('AdviserProfile.Error.Required');
      toast.error(error);
    }
  };

  const handleMainLanguagesChipOnClick = val => {
    const mainLanguagesArr = getValues('mainLanguages');
    if (mainLanguagesArr.includes(val)) {
      setValue(
        'mainLanguages',
        mainLanguagesArr.filter(language => language !== val),
      );
    } else {
      setValue('mainLanguages', [...mainLanguagesArr, val]);
    }
  };

  const handlePersonalityTagsChipOnClick = val => {
    const personalityTagsArr = getValues('personalityTags');
    if (personalityTagsArr.includes(val)) {
      setValue(
        'personalityTags',
        personalityTagsArr.filter(tag => tag !== val),
      );
    } else {
      if (personalityTagsArr.length > 5) {
        return;
      }
      setValue('personalityTags', [...personalityTagsArr, val]);
    }
  };

  const getEditorValue = () => getValues('adviserMessage');

  const setEditorValue = val => {
    setValue('adviserMessage', JSON.stringify(val));
  };
  return (
    <div className={styles.container}>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          marginBottom: '16px',
        }}
      >
        <>
          <Typography
            variant="subtitle2"
            color="var(--color-text-primary)"
            sx={{
              marginBottom: '16px',
            }}
          >
            {t('AdviserProfile.MainLanguages')}{' '}
            <span className={styles.required}>*</span>
            <span className={styles.remark}>
              ({t('AdviserProfile.MultipleChoices')})
            </span>
          </Typography>
          <Box>
            {watchMainLanguages &&
              MAIN_LANGUAGES_OPTIONS.map(option => (
                <Chip
                  key={option.value}
                  className={clsx(
                    watchMainLanguages.includes(option.value)
                      ? styles.inactive
                      : undefined,
                  )}
                  label={t(`AdviserProfile.Language.${option.value}`)}
                  variant="outlined"
                  sx={{
                    margin: '0 8px 8px 0',
                  }}
                  onClick={() => handleMainLanguagesChipOnClick(option.value)}
                />
              ))}
          </Box>
        </>
      </Container>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          marginBottom: '16px',
        }}
      >
        <>
          <Typography variant="subtitle2" color="var(--color-text-primary)">
            {t('AdviserProfile.MyPersonality')}{' '}
            <span className={styles.required}>*</span>
            <span className={styles.remark}>
              ({t('AdviserProfile.Select')}{' '}
              <span className={styles.required}>
                {t('AdviserProfile.UpTo6InTotal')}
              </span>
              )
            </span>
          </Typography>
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            👚 {t('AdviserProfile.MyType')}
          </Typography>
          {watchPersonalityTags &&
            MY_TYPE_OPTIONS.map(option => (
              <Chip
                key={option.value}
                className={clsx(
                  watchPersonalityTags.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.PersonalityTag.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handlePersonalityTagsChipOnClick(option.value)}
              />
            ))}
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            💼 {t('AdviserProfile.WorkAttitude')}
          </Typography>
          {watchPersonalityTags &&
            WORK_ATTIUDE_OPTIONS.map(option => (
              <Chip
                key={option.value}
                className={clsx(
                  watchPersonalityTags.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.PersonalityTag.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handlePersonalityTagsChipOnClick(option.value)}
              />
            ))}
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            🤝 {t('AdviserProfile.TreatPeople')}
          </Typography>
          {watchPersonalityTags &&
            TREAT_PEOPLE_OPTIONS.map(option => (
              <Chip
                key={option.value}
                className={clsx(
                  watchPersonalityTags.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.PersonalityTag.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handlePersonalityTagsChipOnClick(option.value)}
              />
            ))}
          <Typography
            className={styles.subtitle}
            variant="caption"
            fontWeight="500"
          >
            🏠 {t('AdviserProfile.LifeAttitude')}
          </Typography>
          {watchPersonalityTags &&
            LIFE_ATTITUDE_OPTIONS.map(option => (
              <Chip
                key={option.value}
                className={clsx(
                  watchPersonalityTags.includes(option.value)
                    ? styles.inactive
                    : undefined,
                )}
                label={t(`AdviserProfile.PersonalityTag.${option.value}`)}
                variant="outlined"
                sx={{
                  margin: '0 8px 8px 0',
                }}
                onClick={() => handlePersonalityTagsChipOnClick(option.value)}
              />
            ))}
        </>
      </Container>
      <Container
        sx={{
          padding: { xs: '16px', md: '24px' },
          maxWidth: '100%',
          width: '100%',
        }}
      >
        <>
          <Editor
            title={t('AdviserProfile.MyIntroduction')}
            description={t('AdviserProfile.MyIntroductionDetails')}
            type="aboutMe"
            defaultValue={getEditorValue}
            setContent={setEditorValue}
          />
          <Box
            className={styles.footer}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={back}
            >
              {t('Global.Back')}
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              sx={{
                minWidth: { xs: 'calc(50% - 12px)', md: '120px' },
                boxShadow: { xs: 'var(--shadow-l6)', md: 'unset' },
              }}
              onClick={handleNextOnClick}
            >
              {t('Global.Next')}
            </Button>
          </Box>
        </>
      </Container>
    </div>
  );
}

export default AboutMe;
