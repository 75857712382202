/**
 * Asynchronously loads the component for CreateSamplePage
 */

import { lazyLoad } from 'utils/loadable';

export const DebugPage = lazyLoad(
  () => import('./index'),
  module => module.DebugPage,
);
