import { Box, SxProps, Theme } from '@mui/material';
import { DiamondOutlined } from '@mui/icons-material';
import PaidFeatureAlert from '../PaidFeatureAlert/PaidFeatureAlert';
import { useContext, useMemo } from 'react';
import { AuthenticatedUserContext } from 'hooks/hooks';
import { PaidFeatureAlertType } from '../PaidFeatureAlert/PaidFeatureAlert.types';
import { getIsFreeUser } from 'utils/validators/validator/subscription';

function DiamondTrailing({
    onClick, sx, focusingAdviserId, diamondSize = 18, preventDefault = false
}: {
    onClick?: () => void;
    focusingAdviserId: string | boolean // Focusing adviser id
    diamondSize?: number;
    sx?: SxProps<Theme>;
    preventDefault?: boolean;
}) {
    const AuthenticatedUser = useContext(AuthenticatedUserContext);
    const planNickName = AuthenticatedUser.subscriptionNickname;
    const isOwner = typeof focusingAdviserId === 'boolean' ? focusingAdviserId : AuthenticatedUser.isAdviserProfileOwner(focusingAdviserId);
    const showAlert = getIsFreeUser(planNickName);

    return useMemo(() => {
        if (isOwner && showAlert) {
            return (
                <PaidFeatureAlert
                    type={PaidFeatureAlertType.info} plan={showAlert}
                    render={(onAlert) => {
                        return <Box
                            onClick={preventDefault ? onClick : onAlert}
                            sx={{
                                height: '24px',
                                width: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#EFF3FD',
                                color: '#3966F8',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                ...sx,
                            }}
                        >
                            <DiamondOutlined
                                sx={{ width: `${diamondSize}px`, height: `${diamondSize}px` }}
                            />
                        </Box>

                    }}
                >
                </PaidFeatureAlert>
            );
        }
        else {
            return <></>
        }
    }, [isOwner, showAlert, sx, diamondSize, preventDefault, onClick]);
}

export default DiamondTrailing;
