import { getDeviceOS } from 'utils/Device/getDeviceOS';

export function getAllowedParent(parent?: string) {
  const regex =
    /^https:\/\/([a-zA-Z0-9-]+\.)*portfoplus\.com\/?$|^capacitor:\/\/([a-zA-Z0-9-]+\.)*portfoplus\.com\/?$/;
  const referrer =
    parent ||
    decodeURIComponent(
      new URLSearchParams(window.location.search).get('referrer') ||
        document.referrer,
    );

  const isDev = process.env.NODE_ENV === 'development';
  if (isDev && referrer && referrer !== 'reject-test') {
    return referrer;
  } else if (referrer.match(regex)) {
    if (getDeviceOS() === 'Android') {
      return referrer.replace('capacitor://', 'https://');
    } else {
      return referrer;
    }
  }
  // This is temporary solution for the case when the referrer is not set
  else if (getDeviceOS() === 'Android') {
    return process.env.REACT_APP_PFPLUS_FRONTEND_URL;
  } else if (getDeviceOS() === 'iOS') {
    return process.env.REACT_APP_PFPLUS_FRONTEND_URL?.replaceAll(
      'https',
      'capacitor',
    );
  }
  // This is temporary solution for the case when the referrer is not set
  // The proper solution is to always set the referrer from the parent
  // EG: capacitor://app.portfoplus.com
  else {
    return;
  }
}
