// import { AnimatableProps } from '../../Animatable.types';
import {
  AnimationControls,
  TargetAndTransition,
  VariantLabels,
} from 'framer-motion';
import React from 'react';

export enum SelectableStatus {
  DISPLAYING = 'DISPLAYING',
  SELECTED = 'SELECTED',
  NOTSELECTED = 'NOTSELECTED',
  DISABLED = 'DISABLED',
  INIT = 'INIT',
}

export type SelectableProps = {
  status: SelectableStatus;
  animations?: {
    [key in SelectableStatus]?:
      | AnimationControls
      | TargetAndTransition
      | VariantLabels
      | boolean;
  };
  builder?: (props: {
    status: SelectableStatus;
    animation:
      | AnimationControls
      | TargetAndTransition
      | VariantLabels
      | boolean;
    ref: React.RefObject<HTMLDivElement>;
    children: React.ReactNode;
  }) => JSX.Element;
  onSelected?: () => void;
  children: React.ReactNode;
  mergeDefault?: boolean;
  initialStage?: string;
  currentStage?: string;
};
