import { Chip, ChipProps } from '@mui/material';
import { useMemo } from 'react';

export type TagProps = {
  active?: boolean;
  label?: string;
  type?: 'NA' | 'Low' | 'Medium' | 'High' | 'Normal' | 'VIP' | 'VVIP';
  randomizeColor?: boolean;
} & ChipProps;

type TagMuiColor =
  | 'error'
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | undefined;

const Tag = ({
  active = true,
  label,
  type,
  randomizeColor = false,
  ...otherProps
}: TagProps) => {
  const color: TagMuiColor = useMemo(() => {
    const colors: TagMuiColor[] = [
      'primary',
      'secondary',
      'error',
      'info',
      'success',
      'warning',
    ];
    return randomizeColor
      ? colors[Math.floor(Math.random() * colors.length)]
      : 'default';
  }, [label]);

  return (
    <Chip
      variant={active ? 'filled' : 'outlined'}
      label={label}
      size="small"
      color={color}
      style={{ padding: '0px 3px' }}
      {...otherProps}
    />
  );
};

export default Tag;
