import { Typography } from '@mui/material';
import AdvisorProfileCard, {
  AdvisorProfileCardProps,
} from './AdvisorProfileCard';
import VSpace from '../VSpace';
import Tags from '../Tags';
import { useTranslation } from 'react-i18next';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import TextLineBreaker from '../TextLineBreaker/TextLineBreaker';

export default function AdvisorProfileBusinessCard({
  advisor,
  isDemo,
  isFreeUser,
}: AdvisorProfileCardProps) {
  const { t } = useTranslation();
  const { mainColor, tagStyle } = useAdvisorProfileTheme();

  if (!advisor) {
    return (
      <AdvisorProfileCard loading isDemo={isDemo} isFreeUser={isFreeUser} />
    );
  }
  const {
    adviserInfo: {
      company,
      seniority,
      license,
      shouldShowLicense,
      mainProductsServices,
      isMDRT,
      jobExperience,
      otherAwards,
      customizedProductsOrServices,
    },
  } = advisor;

  return (
    <AdvisorProfileCard
      heading={t('AdviserProfile.BUSINESS')}
      isDemo={isDemo}
      isFreeUser={isFreeUser}
    >
      {company && (
        <>
          <Typography variant="subtitle2" fontWeight={500}>
            💻 {t('AdviserProfile.Workplace')}
          </Typography>
          <VSpace size={1} />
          <Tags
            tags={[company]}
            tagProps={{ sx: tagStyle, variant: 'outlined' }}
          />
          <VSpace size={3} />
        </>
      )}

      <Typography variant="subtitle2" fontWeight={500}>
        🕐 {t('AdviserProfile.Seniority')}
      </Typography>
      <VSpace size={1} />
      {seniority && (
        <Tags
          tags={[t(`AdviserProfile.SeniorityTag.${seniority}`, seniority)]}
          tagProps={{ sx: tagStyle, variant: 'outlined' }}
        />
      )}
      <VSpace size={3} />

      {shouldShowLicense && license && (
        <>
          <Typography variant="subtitle2" fontWeight={500}>
            🪪 {t('AdviserProfile.License')}
          </Typography>
          <TextLineBreaker
            prefix={<VSpace size={1} />}
            text={license}
            builder={(line, index) => {
              return (
                <Typography variant="subtitle2" fontWeight={400} align="left">
                  {line}
                </Typography>
              );
            }}
          />
          <VSpace size={3} />
        </>
      )}
      <Typography variant="subtitle2" fontWeight={500}>
        📁 {t('AdviserProfile.MyFocus')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={[
          ...(mainProductsServices?.map(tag =>
            t(`AdviserProfile.MainProductsAndServices.${tag}`),
          ) ?? []),
          ...(customizedProductsOrServices?.split('#') || []).filter(t => !!t),
        ]}
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      <VSpace size={3} />
      <Typography variant="subtitle2" fontWeight={500}>
        🏆 {t('AdviserProfile.Achievement')}
      </Typography>
      <VSpace size={1} />
      <Tags
        tags={
          isMDRT?.map(tag => t(`AdviserProfile.Qualification.${tag}`)) ?? []
        }
        tagProps={{ sx: tagStyle, variant: 'outlined' }}
      />
      {otherAwards && (
        <TextLineBreaker
          prefix={<VSpace size={1} />}
          text={otherAwards}
          builder={(line, index) => {
            return (
              <Typography variant="subtitle2" fontWeight={400} align="left">
                {line}
              </Typography>
            );
          }}
        />
      )}
      <VSpace size={3} />
      <Typography variant="subtitle2" fontWeight={500}>
        💼 {t('AdviserProfile.JobExperience')}
      </Typography>
      {jobExperience && (
        <TextLineBreaker
          prefix={<VSpace size={1} />}
          text={jobExperience}
          builder={(line, index) => {
            return (
              <Typography variant="subtitle2" fontWeight={400} align="left">
                {line}
              </Typography>
            );
          }}
        />
      )}
      <VSpace size={5} />
    </AdvisorProfileCard>
  );
}
