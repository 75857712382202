import styles from 'app/components/Clients/ClientFilters/ColorCircleIcon.module.css';
import CircleIcon from '@mui/icons-material/Circle';
import { SelectOption } from 'app/components/FormField';
import monthsForLocale from 'utils/monthsForLocale';
import { VIPType, RelationsType, GenderType, PersonalityType } from 'types';

export type DobMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const getDobMonthOptions = (
  locale: string,
): SelectOption<DobMonth>[] => {
  const monthNames = monthsForLocale(locale);
  return monthNames.map((month, index) => ({
    label: month,
    value: (index + 1) as DobMonth,
  }));
};

export const EXISTING_CLIENT_OPTIONS: SelectOption<boolean>[] = [
  {
    icon: '😆',
    label: 'Global.Yes',
    value: true,
  },
  {
    icon: '😵',
    label: 'Global.No',
    value: false,
  },
];

export const VIP_OPTIONS: SelectOption<VIPType>[] = [
  {
    icon: '💎',
    label: 'VVIP',
    value: 'VVIP',
  },
  {
    icon: '🌟',
    label: 'VIP',
    value: 'VIP',
  },
  {
    icon: '⭐',
    label: 'ClientFilters.Normal',
    value: 'NORMAL',
  },
];

export const RELATIONS_OPTIONS: SelectOption<RelationsType>[] = [
  {
    icon: '🤝',
    label: 'ClientFilters.Warm',
    value: 'WARM',
  },
  {
    icon: '🙋',
    label: 'ClientFilters.Refer',
    value: 'REFER',
  },
  {
    icon: '😶',
    label: 'ClientFilters.Cold',
    value: 'COLD',
  },
];

export const GENDER_OPTIONS: SelectOption<GenderType>[] = [
  {
    icon: '🙋',
    label: 'ClientFilters.Male',
    value: 'Male',
  },
  {
    icon: '🙋‍♀️',
    label: 'ClientFilters.Female',
    value: 'Female',
  },
  {
    icon: '😶',
    label: 'ClientFilters.Unknown',
    value: undefined,
  },
];

export const PERSONALITY_OPTIONS: SelectOption<PersonalityType>[] = [
  {
    icon: <CircleIcon className={styles.colorCircleIcon} color="error" />,
    label: 'D',
    value: 'D',
  },
  {
    icon: <CircleIcon className={styles.colorCircleIcon} color="warning" />,
    label: 'I',
    value: 'I',
  },
  {
    icon: <CircleIcon className={styles.colorCircleIcon} color="success" />,
    label: 'S',
    value: 'S',
  },
  {
    icon: <CircleIcon className={styles.colorCircleIcon} color="info" />,
    label: 'C',
    value: 'C',
  },
];

export const TAGS = [
  {
    label: 'USA',
    value: 'USA',
  },
  {
    label: 'Gold',
    value: 'Gold',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    label: 'Voyage',
    value: 'Voyage',
  },
  {
    label: 'Voyage #2',
    value: 'Voyage #2',
  },
  {
    label: 'Aggressive',
    value: 'Aggressive',
  },
  {
    label: 'Cute',
    value: 'Cute',
  },
];

export const MONTH_SAVING_MIN = 0;
export const MONTH_SAVING_MAX = 3 * 10 * 1000;
export const MONTH_SAVING_SLIDER_STEP = 500;

export const LIFE_INSURED_MIN = 0;
export const LIFE_INSURED_MAX = 3 * 1000 * 1000;
export const LIFE_INSURED_SLIDER_STEP = 100 * 1000;

export const CI_INSURED_MIN = 0;
export const CI_INSURED_MAX = 2 * 1000 * 1000;
export const CI_INSURED_SLIDER_STEP = 100 * 1000;

export const MONTH_INCOME_MIN = 0;
export const MONTH_INCOME_MAX = 6 * 10 * 1000;
export const MONTH_INCOME_SLIDER_STEP = 1000;
