import { useState } from 'react';

// import PaidFeatureAlertDialog from './PaidFeatureAlertDialog';
import TeamFeatureAlertDialog from './TeamFeatureAlertDialog';
import { InfoAlertProps } from './InfoAlert.types';
import PaidFeatureAlertDialog from './PaidFeatureAlertDialog';

export function InfoAlert({
  alertMessage,
  alertTitle,
  onShow,
  onClose,
  render,
  shouldShowAlert,
  type,
}: InfoAlertProps & {
  shouldShowAlert: boolean;
  render: (onAlert: () => void, shouldShowAlert: boolean) => JSX.Element;
}) {
  const [showAlert, setShowAlert] = useState(false);
  const openAlert = () => {
    onShow && onShow();
    setShowAlert(true);
  };
  const closeAlert = () => {
    onClose && onClose();
    setShowAlert(false);
  };

  if (type === 'team') {
    return (
      <>
        <TeamFeatureAlertDialog
          alertMessage={alertMessage}
          alertTitle={alertTitle}
          open={showAlert}
          onClose={closeAlert} />
        {render(openAlert, shouldShowAlert)}
      </>
    );
  } else {
    return (
      <>
        <PaidFeatureAlertDialog
          alertMessage={alertMessage}
          alertTitle={alertTitle}
          open={showAlert}
          onClose={closeAlert}
        />
        {render(openAlert, shouldShowAlert)}
      </>
    );
  }
}
export default InfoAlert;
