import clsx from 'clsx';
import styles from './Portfoplus.module.css';

export enum LogoType {
  Fit = 'Fit',
  TaglineFit = 'TaglineFit',
  Normal = 'Normal',
}

export function getLogoPath(type: LogoType): string {
  switch (type) {
    case LogoType.Fit:
      return '/images/vi/pfp-logo-2024-fit.png';
    case LogoType.TaglineFit:
      return '/images/vi/pfp-logo-tagline-fit-2024.png';
    default:
      return '/images/logo-portfoplus.png';
  }
}

export default function Portfoplus({
  href,
  classNames,
  type = LogoType.Normal,
  style
}: {
  href?: string;
  classNames?: {
    container?: string;
    title?: string;
    image?: string;
  };
  type?: LogoType;
  style?: any;
}) {
  const Logo = () => (
    <div className={clsx(styles.container, styles[type])} style={style}>
      <img
        alt="portfoplus-logo"
        className={clsx(styles.image, styles[type])}
        src={getLogoPath(type)}
      />
    </div>
  );

  if (href) {
    return (
      <a href={href}>
        <Logo />
      </a>
    );
  }

  return <Logo />;
}
