import {
  Button,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TrHeader } from '../../../PaidFeatureAlert.types';
import VSpace from 'app/components/VSpace';
import BottomOrDialog from 'app/components/BottomOrDialog/BottomOrDialog';
import { ArrowRightAlt, InfoOutlined } from '@mui/icons-material';
import HSpace from 'app/components/HSpace';
import styles from './InfoAlert.module.css';
import HeadDraft from './HeadDraft';
import { isFrameCheck } from 'utils/Frame/isFrameCheck';
import { postMessageToParent } from 'utils/Frame/postMessageToParent';
import { redirectToPFP } from 'app/route/route';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from 'hooks/useBreakpoints/useBreakpoints';
import { trackMilestoneEvent } from 'hooks/useTracking/services/trackEvent/trackMilestoneEvent';

function PaidFeatureAlertDialog({
  open,
  onClose,
  onCTA,
  alertMessage,
  alertTitle,
}: {
  open: boolean;
  onCTA?: () => void;
  onClose: () => void;
  alertMessage?: string;
  alertTitle?: string;
}) {
  const [t] = useTranslation();
  const n = useNavigate();
  const isMobile = useIsMobile();

  return (
    <BottomOrDialog open={open} onClose={onClose}>
      <VSpace size={2} />
      <Box className={styles.paidFeatureAlert} alignContent={'center'}>
        <HeadDraft onClose={onClose} />
        <DialogTitle className={styles.heading}>
          {alertTitle ||
            t([TrHeader, 'PaidFeatureAlertDialog', 'heading'].join('.'))}
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <InfoOutlined />
            <HSpace size={1} />
            <Typography variant="body1" className={styles.description}>
              {alertMessage ||
                t(
                  [TrHeader, 'PaidFeatureAlertDialog', 'description'].join('.'),
                )}
            </Typography>
          </Box>
          <VSpace size={2} />
          <Box style={{ textAlign: "center" }}>
            <iframe
              src="https://www.youtube.com/embed/cOTqaXrC4uU"
              style={{
                aspectRatio: "9 / 16",
                width: "100%",
                height: isMobile ? "60vh" : "40vh",
                border: "none",
              }}>

            </iframe>
          </Box>
          <VSpace size={2} />
          <Button
            variant="contained"
            onClick={
              onCTA ||
              (() => {
                trackMilestoneEvent({
                  type: 'Subscription Upgrade Started',
                  event_properties: {
                    Success: true,
                  },
                });
                onClose();
                if (isFrameCheck()) {
                  postMessageToParent({
                    success: true,
                    code: 'SHOW_UPGRADE_MODAL',
                    message: 'Show Upgrade Modal',
                  });
                } else {
                  redirectToPFP(n, '/home?upgrade=true');
                }
              })
            }
            fullWidth
            sx={{ minHeight: '48px' }}
          >
            <Typography variant="button" className={styles.firstCTA}>
              {t([TrHeader, 'PaidFeatureAlertDialog', 'cta'].join('.'))}
            </Typography>
            <ArrowRightAlt />
          </Button>
        </DialogContent>
      </Box>
    </BottomOrDialog>
  );
}

export default PaidFeatureAlertDialog;
