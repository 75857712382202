import React from 'react';

interface LineBreakerProps {
  text?: string;
  builder: LineBreakerBuilder;
  separator?: string;
  prefix?: React.ReactNode;
}
type LineBreakerBuilder = (line: string, index: number) => React.ReactNode;

export default function TextLineBreaker(props: LineBreakerProps) {
  if (!props.text) return <></>;
  const lines = props.text.split(props.separator || '\n');
  return (
    <>
      {props.prefix && props.prefix}
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {props.builder(line, index)}
        </React.Fragment>
      ))}
    </>
  );
}
