import {
  AnimationControls,
  TargetAndTransition,
  VariantLabels,
} from 'framer-motion';
import { SelectableStatus } from '../../Animatable';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

const defaultSx = {
  // border: '4px solid rgba(142, 88, 255, 0)',
  backgroundColor: 'rgba(142, 88, 255, 0)',
  boxShadow: '0 2px 5px 0 rgba(91, 53, 181, 0.5)',
  padding: '4px 12px',
  borderRadius: '12px',
  opacity: 1,
  transition: {
    duration: 0.35,
    delayChildren: 0.3,
    staggerChildren: 0.2,
  },
};

export const Selectable: Record<
  SelectableStatus,
  | AnimationControls
  | TargetAndTransition
  | VariantLabels
  | SxProps<Theme>
  | boolean
> = {
  INIT: false,
  DISPLAYING: defaultSx,
  SELECTED: {
    ...defaultSx,
    // border: '4px solid rgba(91, 53, 181, 1)',
    backgroundColor: 'rgba(142, 88, 255,1)',
    color: 'white',
    opacity: 1,
  },
  DISABLED: {
    ...defaultSx,
    opacity: 0.1,
    color: 'inherit',
  },
  NOTSELECTED: {
    ...defaultSx,
    opacity: 0.5,
    color: '#999',
  },
};
