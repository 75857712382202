import { CircularProgress, Typography, Box, SxProps } from '@mui/material';

function Loading({ label, sx }: { sx?: SxProps; label?: string | null }) {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        alignContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress />

      {label !== null && (
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          {label || 'Loading...'}
        </Typography>
      )}
    </Box>
  );
}

export default Loading;
