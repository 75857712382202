import { getAllowedParent } from './allowedParentCheck';

export function postNextToParent(parent?: string) {
  if (!window.parent) {
    console.error(
      'Trying to comminicate with parents. However, this page is not embedded in an iframe',
    );
    return;
  } else {
    const search = new URLSearchParams(window.location.search);
    const referrer = parent ?? getAllowedParent();
    const nextAction = search.get('nextAction');
    const nextData = search.get('nextData');
    if (nextAction && nextData && referrer) {
      window.parent.postMessage(
        {
          success: true,
          message: 'Performing next action',
          code: nextAction,
          data: nextData,
        },
        referrer,
      );
    }
  }
}
