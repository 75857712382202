import React from 'react';
import { Box, Typography } from '@mui/material';
import style from './Powered.module.css';
import Portfoplus, { LogoType } from '../Portfoplus';

const PoweredBy = props => {
  return (
    <Box
      className={style['powered-by']}
      onClick={() => {
        window.open(process.env.REACT_APP_PFPLUS_FRONTEND_URL, '_blank');
      }}
    >
      <Typography className={style.label}>Powered By</Typography>
      <Portfoplus
        type={LogoType.Fit}
        style={{ width: 87, height: 25 }}
        classNames={{
          container: style.imgContainer,
          image: style.img,
        }}
      />
    </Box>
  );
};

export default PoweredBy;
