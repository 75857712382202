import { useCallback } from 'react';
import { toast } from 'react-toastify';

export default function useCopy({
  textToCopy,
  successMessage = 'Copied to clipboard',
}: {
  textToCopy: string;
  successMessage?: string;
}) {
  const copy = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    toast.info(successMessage);
  }, [textToCopy, successMessage]);
  return copy;
}
