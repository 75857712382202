import { Box, Grid } from '@mui/material';
import { TimeslotSelectorLayoutProps } from '../TimeslotSelectorPanel.types';
import TimeslotCard from '../TimeslotCard/TimeslotCard';
import { SelectableStatus } from 'app/components/Animatable/Animatable';
function TimeSelectorVScroll({
  sizeProfile,
  ...props
}: TimeslotSelectorLayoutProps & {
  sizeProfile?: {
    [key: string]: number;
  };
}) {
  return (
    <Box>
      <Grid
        container
        sx={{ justifyContent: 'center' }}
        spacing={'20px'}
        rowSpacing={'20px'}
      >
        {props.timeslots.map((t, index) => {
          const tStatus =
            props.timeslotLoading === t._id
              ? SelectableStatus.SELECTED
              : props.timeslotLoading === undefined
              ? SelectableStatus.DISPLAYING
              : SelectableStatus.NOTSELECTED;

          return (
            <Grid item {...sizeProfile} key={t._id}>
              <TimeslotCard
                onMakeAppointment={() => {
                  props.handleMakeAppointment(t);
                }}
                index={index}
                timeslot={t}
                status={tStatus}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default TimeSelectorVScroll;
