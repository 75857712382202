import DEFAULT_ADVISER_MESSAGES_EN from 'app/components/Editor/adviser-message-en.json';
import DEFAULT_ADVISER_MESSAGES_ZH_HANT_HK from 'app/components/Editor/adviser-message-zh-Hant-HK.json';
import DEFAULT_ADVISER_MESSAGES_ZH from 'app/components/Editor/adviser-message-zh.json';
import { AdviserInfo } from 'types';

export function getAdviserMessage(advisor: AdviserInfo, lang: string) {
  if (advisor.adviserMessage) {
    return advisor.adviserMessage;
  } else {
    switch (lang) {
      case 'zh-Hant-HK':
        return DEFAULT_ADVISER_MESSAGES_ZH_HANT_HK;
      case 'zh':
        return DEFAULT_ADVISER_MESSAGES_ZH;
      default:
        return DEFAULT_ADVISER_MESSAGES_EN;
    }
  }
}
