import { EditProfilePage } from 'app/pages/EditProfilePage';
import { AvailabeTimeslotFrame } from '../../frames/AvailabeTimeslotFrame/Loadable';
import { Route } from '../types';
import { LinkCard } from 'app/frames/LinkCard/Loadable';

const base = 'frame/';

export const FRAME_ROUTES: Route[] = [
  {
    path: base + 'link-card/:id',
    element: <LinkCard />,
  },
  {
    path: base + 'timeslots',
    element: <AvailabeTimeslotFrame />,
  },
  {
    path: base + 'my-details',
    element: <EditProfilePage viewMode="iframe" />,
  },
];
