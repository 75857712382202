// datetime : {type:Date, required: true, unique: true},
// cutOff : {type:Date, required: false, unique: false},
// address : {type:String, required: true, unique: false},
// seats:{type:Number, required: true, unique: false},
// appointment: [{ type: Schema.ObjectId, ref: 'appointment' }],
// remarks: {type:String, required: false, unique: false},
// type: {type:String, required: true, default: 'Customer Service', enum: ['Customer Service']},
// status: {type:String, required: true, default: 'Pending', enum: ['Active', 'Closed','Pending']},
// updatedBy: { type: Schema.ObjectId, ref: 'Users' },
// updatedAt: { type: Date, default: Date.now },
// createdBy: { type: Schema.ObjectId, ref: 'Users' },
// createDate: { type: Date, default: Date.now }

export enum TimeslotType {
  CustomerService = 'CustomerService',
}

export interface Timeslot {
  _id: string;
  eventName?: { [key: string]: string };
  eventDescription?: { [key: string]: string };
  address: { [key: string]: string };
  datetime: string;
  cutOff?: Date;
  seats?: number;
  type: string;
  status: string;
}
