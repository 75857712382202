import { DASHBOARD_ROUTES } from './routes/dashboard';
import { Route } from './types';
import Login from 'app/frames/Login';
import { Route as ReactRoute } from 'react-router-dom';
import { PUBLIC_ROUTES, FRAME_ROUTES, PRIVATE_ROUTES } from './routes/routes';

export * from './types';
export * from './routes/routes';
export * from './utils/utils';

const ROUTES = [
  ...PUBLIC_ROUTES,
  ...DASHBOARD_ROUTES,
  ...FRAME_ROUTES,
  ...PRIVATE_ROUTES,
];

export function builder(props: Route, authed: boolean) {
  if (props.isPrivate && !authed) {
    return (
      <ReactRoute
        key={props.path}
        path={props.path}
        element={<Login redirectTo={props.path ?? '/'} />}
      />
    );
  } else {
    return (
      <ReactRoute key={props.path} path={props.path} element={props.element} />
    );
  }
}
export default ROUTES;
