// import { TrackableUserProperties } from './types/TrackableUserProperties';
import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from 'mixpanel-browser';
// import * as mixpanel from 'mixpanel-browser';

// function initAmpUser(props: TrackableUserProperties) {
//   amplitude.setUserId(props._id);
//   const userProps = props.amplitude;
//   const iden = new amplitude.Identify();
//   iden.setOnce('Created', props.created ?? '');
//   Object.entries(userProps).forEach(([key, value]) => {
//     if (value) {
//       iden.set(key, value);
//     }
//   });
//   amplitude.identify(iden);
//   console.log(
//     'Amplitude user properties set, iden: ',
//     iden.getUserProperties(),
//   );
// }

// function setTrackingUserProperties(props: TrackableUserProperties) {
//   initAmpUser(props);
// }

function identifyTrackingUser(_id: string | undefined) {
  amplitude.setUserId(_id);
  mixpanel.identify(_id);
}

function setTrackingUser(_id: string | undefined) {
  identifyTrackingUser(_id);
}

export { setTrackingUser };
