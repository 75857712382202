import React from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import setKColor from 'utils/Lottie/setKColor';
import { hexColorToRGB } from 'utils';

interface LottieProps {
  initialFrame?: number;
  options: {
    animationData: string | object;
    [key: string]: any;
  };
  onComplete?: () => void;
  isPlaying?: boolean;
  assistantExpand?: boolean;
  onClick?: () => void;
  className?: string;
  [key: string]: any;
}
export default class Lottie extends React.PureComponent<LottieProps> {
  private container: React.RefObject<HTMLDivElement>;
  private anim?: AnimationItem;
  private animLoading?: Promise<AnimationItem>;

  constructor(props: LottieProps) {
    super(props);
    this.container = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    const { initialFrame, options, onComplete } = this.props;

    this.animLoading = new Promise<AnimationItem>(async (resolve, reject) => {
      // if it is an url string
      if (typeof options.animationData === 'string') {
        try {
          const anmi = await (await fetch(options.animationData)).json();
          if (options.color) {
            const rgbColor = hexColorToRGB(options.color);
            options.animationData = setKColor(anmi, rgbColor);
          }
        } catch (error) {
          console.log(error);
          reject();
          return;
        }
      }

      // Destroy any existing animation before creating a new one
      if (this.anim) {
        this.anim.destroy();
      }

      this.anim = lottie.loadAnimation({
        container: this.container.current as Element,
        ...options,
      });

      if (onComplete) {
        this.anim.addEventListener('complete', onComplete);
      }

      if (initialFrame) {
        this.anim.goToAndStop(initialFrame, true);
      }

      resolve(this.anim);
    });
  }

  async componentDidUpdate(prevProps: LottieProps): Promise<void> {
    if (!this.anim) {
      if (this.animLoading) {
        try {
          await this.animLoading;
        } catch (error) {}
      } else {
        return;
      }
    }

    // If animation data changed, reload animation
    if (prevProps.options.animationData !== this.props.options.animationData) {
      this.componentDidMount();
      return;
    }

    if (prevProps.isPlaying && !this.props.isPlaying) {
      if (this.props.assistantExpand) {
        this.anim?.stop();
      } else {
        this.anim?.pause();
      }
    }
    if (!prevProps.isPlaying && this.props.isPlaying) {
      this.anim?.play();
    }
  }

  componentWillUnmount(): void {
    if (this.anim) {
      this.anim.destroy();
    }
  }

  animationDidLoad = async (): Promise<AnimationItem | undefined> => {
    if (this.animLoading) {
      return await this.animLoading;
    }
    return this.anim;
  };

  onClick = (): void | null =>
    this.props.onClick ? this.props.onClick() : null;

  render(): React.ReactElement {
    const {
      className,
      initialFrame,
      options,
      onComplete,
      isPlaying,
      assistantExpand,
      ...rest
    } = this.props;
    return (
      <div
        onClick={this.onClick}
        className={`lottie ${className || ''}`}
        ref={this.container}
        {...rest}
      />
    );
  }
}
