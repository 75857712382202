import * as amplitude from '@amplitude/analytics-browser';
import { getFeature } from '../../services/types/events_properties/Feature';
import { getDeviceOS } from 'utils/Device/getDeviceOS';

export function enrichPageUrlPlugin(props?: {
  [key: string]: any;
}): amplitude.Types.EnrichmentPlugin {
  return {
    name: 'middleware-enrich-event-properties',
    execute: async (event: amplitude.Types.Event) => {
      const path = window.location.pathname;
      const event_properties = {
        ...props?.event_properties,
        ...event.event_properties,
        'Page Path': path,
        'Page Search': window.location.search,
        Domain: window.location.hostname,
        Platform: 'web',
        Feature: getFeature(path),
        DeviceOS: getDeviceOS(),
        AppVersion: '1.0.0',
      };
      delete event_properties?.['Password'];
      event.event_properties = event_properties;
      return event;
    },
  };
}
