import { createContext, useContext } from 'react';
export type EditProfileContent = {
  avatarDialog: boolean;
  setAvatarDialog: (val: boolean) => void;
  userName: string;
  avatar: string;
  setAvatar: (val: string) => void;
};
export const EditProfileContext = createContext<EditProfileContent>({
  avatarDialog: false,
  setAvatarDialog: () => {},
  userName: '',
  avatar: '',
  setAvatar: () => {},
});
export const useEditProfileContext = () => useContext(EditProfileContext);
