import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { GenericResponse } from 'services/apiTypes';
import { Advisor } from 'types';
import { NFCCardReference } from './types';
import i18next from 'i18next';

export * from './types';

export const cardAPI = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    'Advisor',
    'api'
  >,
) => {
  return {
    getAdviserByCardId: builder.query<
      GenericResponse<NFCCardReference | undefined> | Advisor,
      { id: string; search?: { [key: string]: any } }
    >({
      query: props => {
        const { id, search } = props;
        const _search = {
          ...search,
          medium: 'nfc',
          role: 'adviser',
          language: i18next.language,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const searchString = _search
          ? `?${new URLSearchParams(_search).toString()}`
          : '';
        return `/card/${id}${searchString}`;
      },
    }),
    linkCardToAdviser: builder.mutation<
      GenericResponse<Advisor | undefined>,
      string
    >({
      query: id => ({
        url: `/card/${id}/attach`,
        method: 'POST',
      }),
    }),
  };
};
