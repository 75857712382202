import { MilestoneEventType } from '../services';
import { trackRawEvent } from '../trackRawEvent';
import { MilestoneEventProps } from '../types/MilestoneEvent';

export function trackMilestoneEvent({
  type,
  event_properties,
}: {
  type: MilestoneEventType;
  event_properties: MilestoneEventProps;
  [key: string]: any;
}) {
  const event = {
    event: 'Milestone Event: ' + type.valueOf(),
    props: {
      ...event_properties,
    },
  };
  trackRawEvent(event);
}
