import { getAllowedParent } from './allowedParentCheck';

export function postMessageToParent<T>(
  m: {
    success: boolean;
    code: string;
    message?: string;
    data?: T;
  },
  parent?: string,
) {
  if (!window.parent) {
    console.error(
      'Trying to comminicate with parents. However, this page is not embedded in an iframe',
    );
    return;
  } else {
    const referrer = parent ?? getAllowedParent();
    if (referrer) {
      window.parent.postMessage(m, referrer);
    }
  }
}
