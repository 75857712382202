import { Box, Grid, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { trackInteractionEvent } from 'hooks/useTracking/useTracking';
import { useTranslation } from 'react-i18next';

export function showDemoCardAlert(myId?: string, onNext?: () => void) {
  toast(
    <DemoCardAlert
      id={myId}
      onNext={
        onNext &&
        (() => {
          onNext?.();
          toast.dismiss('demo-card-alert');
        })
      }
    />,
    {
      className: 'Toastify__toast special-toast',
      hideProgressBar: true,
      position: 'top-left',
      toastId: 'demo-card-alert',
      closeOnClick: false,
      autoClose: false,
    },
  );
}
export function demoDefaultMessage(id?: string) {
  const demoMessage = `(活動專享優惠) 我要預先登記 理財專屬AI數碼卡片\n\n附上公司名片 照片\n或\n我的名稱：\n所屬公司：\n\n\n(${id ?? 'DEMOCODE'
    })`;
  return demoMessage;
}
export function demoContactMeWhatsappLink(id?: string) {
  const demoMessage = demoDefaultMessage(id);
  return `https://wa.me/+85266370320?text=${encodeURIComponent(demoMessage)}`;
}

const DemoCardAlert = (props: { onNext?: () => void; id?: string }) => {
  const [t] = useTranslation();

  const contactMe = e => {
    trackInteractionEvent({
      type: 'Toast Clicked',
      event_properties: {
        Name: 'Demo Card Alert',
        'Content Name': 'Demo Card',
      },
    })
    window.open(demoContactMeWhatsappLink(props.id), '_blank');
  };

  return (
    <div
      onClick={props.onNext || contactMe}
      // target="_blank"
      // rel="noopener noreferrer"
      // variant="text"
      style={{ backgroundColor: 'transparent' }}
    // color="inherit"
    >
      <Box display={'flex'}>
        <Box style={{ color: '#3966f8', marginRight: '8px' }}>
          <InfoRounded style={{ color: '#3966f8' }} />
        </Box>
        <Grid container sx={{ textAlign: 'left' }}>
          {/* <Grid item xs={12} display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" style={{ color: '#3966f8' }}>
                理財專屬AI電子名片
              </Typography>
            </Box>
          </Grid> */}
          <Grid item>
            <Typography variant="body1" component="span">
              {t('CardDemoAlert.message')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DemoCardAlert;
