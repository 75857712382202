import { Button, ButtonProps, SxProps, Theme, Typography } from '@mui/material';
import LikeAnimation from '../LikeAnimation/LikeAnimation';
import { Advisor } from 'types';
import { useLikeAdviserProfilePlusByIdMutation } from 'services/api';
import { useState } from 'react';
import ClientReplyFormModal from './ClientReplyFormModal';
import { trackMilestoneEvent } from 'hooks/hooks';

export default function LikeButton({
  className,
  sx,
  advisor,
  shouldShowLikeForm,
  ...props
}: {
  shouldShowLikeForm: boolean;
  className?: string;
  sx?: SxProps<Theme>;
  advisor?: Advisor;
} & ButtonProps) {
  const [likeAdviser] = useLikeAdviserProfilePlusByIdMutation();

  const handleLikeAdvisor = async () => {
    if (advisor) {
      await likeAdviser(advisor.adviserInfo._id);
      trackMilestoneEvent({
        type: 'Profile Like',
        event_properties: {
          Success: true,
          'Focusing Advisor ID': advisor.adviserInfo._id,
          'Focusing Advisor Email': advisor.adviserInfo.email,
          'Focusing Advisor Name': advisor.adviserInfo.name,
        },
      });
      if (shouldShowLikeForm) {
        showLikeForm();
      }
    }
  };
  const [isShowClientReplyForm, setIsShowClientReplyForm] = useState(false);

  const showLikeForm = () => {
    setIsShowClientReplyForm(true);
  };

  const handleCloseLikeForm = () => {
    setIsShowClientReplyForm(false);
  };

  return (
    <LikeAnimation
      color="#ffffff"
      likes={advisor?.likes}
      render={anim => (
        <>
          <Button
            className={className}
            variant="contained"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
              borderRadius: '100px',
              ...sx,
            }}
            {...props}
            disabled={!advisor}
            onClick={handleLikeAdvisor}
          >
            <Typography fontWeight={600}>Like</Typography>
            {anim}
            <Typography fontWeight={600}> {advisor?.likes}</Typography>
          </Button>
          <ClientReplyFormModal
            advisor={advisor}
            show={isShowClientReplyForm}
            onClose={handleCloseLikeForm}
          />
        </>
      )}
    />
  );
}
