import VCard from 'vcard-creator';
import { getReferralLink, ReferralMedium } from './referral';
import { AdviserInfo } from 'types';

export const getVCardString = userDetails => {
  const { name, workEmail, phoneNumber, phoneNumber2, company } =
    userDetails || {};

  const vCard = new VCard();

  if (name) {
    vCard.addName('', name);
  }

  if (workEmail) {
    vCard.addEmail(workEmail);
  }

  if (phoneNumber) {
    vCard.addPhoneNumber(phoneNumber);
  }

  if (phoneNumber2) {
    vCard.addPhoneNumber(phoneNumber2);
  }

  if (company) {
    vCard.addCompany(company);
  }

  const url = getReferralLink({
    medium: ReferralMedium.LINK,
    target: userDetails.id,
  });
  if (url) {
    vCard.addURL(url);
  }

  return vCard.toString();
};

export const getVCardStringWithPhoto = async (userDetails: AdviserInfo) => {
  const { name, workEmail, phoneNumber, phoneNumber2, company, avatar } =
    userDetails || {};

  const vCard = new VCard();

  if (name) {
    vCard.addName('', name);
  }

  if (workEmail) {
    vCard.addEmail(workEmail);
  }

  if (phoneNumber) {
    vCard.addPhoneNumber(phoneNumber);
  }

  if (phoneNumber2) {
    vCard.addPhoneNumber(phoneNumber2);
  }

  if (company) {
    vCard.addCompany(company);
  }

  const url = getReferralLink({
    medium: ReferralMedium.LINK,
    target: userDetails._id,
  });
  if (url) {
    vCard.addURL(url);
  }

  const getImageInfo = async (
    url,
  ): Promise<{
    image: string;
    mime: string;
  }> => {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      const res = await fetch(url + `?t=${new Date().getTime()}`);
      const blob = await res.blob();
      const f = new File([blob], 'avatar', { type: blob.type });
      reader.readAsDataURL(f);
      reader.onloadend = () => {
        if (reader.result) {
          const result = reader.result as string;
          resolve({
            image: result.substring(result.indexOf(',') + 1),
            mime: blob.type.split('/')[1].toUpperCase(),
          });
        }
      };
      reader.onerror = () => {
        reject(reader.error);
      };
    });
  };

  if (avatar) {
    const _avatarUrl = avatar.startsWith('https')
      ? avatar
      : [process.env.REACT_APP_PUBLIC_URL, avatar].join('/');
    const imageInfo = await getImageInfo(_avatarUrl);
    vCard.addPhoto(imageInfo.image, imageInfo.mime);
  }
  return vCard.toString();
};
