import { useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BottomOrDialog from 'app/components/BottomOrDialog/BottomOrDialog';
import { getDeviceOS } from 'utils/Device/getDeviceOS';

interface ConfirmSubmitDialogProps {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

function ConfirmSubmitDialog({
  open,
  onClose,
  handleSubmit,
}: ConfirmSubmitDialogProps) {
  const [agree, setAgree] = useState(false);
  const [isErrorShow, setIsErrorShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCheckBoxChange = () => {
    if (isErrorShow) {
      setIsErrorShow(false);
    }
    setAgree(prevState => !prevState);
  };

  const handleConfirmBtnClick = () => {
    if (!agree) {
      setIsErrorShow(true);
      return;
    }
    handleSubmit();
  };

  return (
    <BottomOrDialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          padding: '20px 24px',
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-blue-300)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        {t('Global.Confirm')}
      </DialogTitle>
      <DialogContent
        sx={{
          py: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox checked={agree} onChange={handleCheckBoxChange} />
            }
            label={t('AdviserProfile.Consent')}
          />
        </Box>
        {isErrorShow && (
          <Typography
            variant="body1"
            color="var(--color-error-main)"
            sx={{
              textAlign: 'center',
            }}
          >
            {t('Global.PleaseCheckTheBox')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: getDeviceOS() === 'iOS' ? '22px 46px' : '22px 24px',
        }}
      >
        <Button
          variant="text"
          sx={{ minWidth: '96px' }}
          onClick={() => onClose()}
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          variant="contained"
          sx={{ minWidth: '96px' }}
          onClick={handleConfirmBtnClick}
        >
          {t('Global.Confirm')}
        </Button>
      </DialogActions>
    </BottomOrDialog>
  );
}

export default ConfirmSubmitDialog;
