import { memo, ReactElement } from 'react';
import DraftEditorToolBarButtonWrapper from './DraftEditorToolBarButtonWrapper/DraftEditorToolBarButtonWrapper';
import { Button } from '@mui/material';

type StyleButtonProps = {
  active: boolean;
  style: string;
  label: string | ReactElement;
  separating?: boolean;
  onToggle: (bockType: string) => void;
  disabled: boolean;
};

const StyleButton = ({
  active,
  style,
  label,
  separating,
  onToggle,
  disabled,
}: StyleButtonProps) => {
  const _onToggle = (e: any) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <DraftEditorToolBarButtonWrapper active={active}>
      <Button onClick={_onToggle}
        disabled={disabled}
      >
        {label}
      </Button>
    </DraftEditorToolBarButtonWrapper>
  );
};

export default memo(StyleButton);
