import { useRef, useState } from 'react';
import { EditorState } from 'draft-js';
import { MenuItem, Menu, Button } from '@mui/material';

const DEFAULT_FONT_SIZES = [11, 12, 14, 16, 18, 24, 30, 36];

interface FontSizeButtonProps {
    theme?: any;
    onChange: (editorState: any) => void;
    editorState: EditorState,
    fontSizeStyles: any;
}

function FontSizeButton(
    { editorState,
        fontSizeStyles,
        onChange,
        theme }: FontSizeButtonProps
) {
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef(null);
    const open = Boolean(anchorEl);

    let currentFontSize = fontSizeStyles.current(editorState) || '16px';

    // if (!DEFAULT_FONT_SIZES.find(fontSize => fontSize.toString() === currentFontSize.toString())) {
    //     currentFontSize =
    // }
    return (
        <div
            onMouseDown={event => event.preventDefault()}
        >
            <Button
                ref={buttonRef}
                onClick={event => {
                    event.stopPropagation();
                    setAnchorEl(buttonRef.current);
                }}
            >
                <div>{currentFontSize.replace('px', '')}</div>
            </Button>

            <Menu
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableRestoreFocus={true}
                disableAutoFocusItem={true}
                anchorEl={anchorEl}
                autoFocus={false}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {DEFAULT_FONT_SIZES.map((fontSize, index) => (
                    <MenuItem
                        key={index}
                        value={fontSize}
                        onClick={event => {
                            event.stopPropagation();
                            setAnchorEl(null);
                            const newEditorState = fontSizeStyles.toggle(editorState, `${fontSize}px`);
                            onChange(newEditorState);
                        }}
                    >
                        {fontSize}
                    </MenuItem>
                ))}
            </Menu>
        </div >
    );

}

export default FontSizeButton;
