import { createContext, useContext, useEffect, useMemo } from 'react';
import Color from 'color';

export type AdvisorProfileThemeContextType = {
  mainColor: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  tagStyle?: React.CSSProperties;
  buttonSx?: React.CSSProperties;
};

export const AdvisorProfileThemeContext =
  createContext<AdvisorProfileThemeContextType>({
    mainColor: '#3D71FF',
    color1: '#3D71FF',
    color2: '#3D71FF',
    color3: '#3D71FF',
    color4: '#3D71FF',
  });

interface AdvisorProfilePageColors {
  baseColor: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  cardBackground: string;
}

export function getAdvisorProfilePageDefaultColor(defaultColor: string): AdvisorProfilePageColors {
  switch (defaultColor) {
    // main color, color1, color2, color3, color4
    case 'blue':
      return {
        baseColor: '#3966F8',
        color1: '#B6C4F2',
        color2: '#172A66',
        color3: '#99B1FF',
        color4: '#7A8DCC',
        cardBackground: Color('#3966F8').green(187).hex()
      };
    case 'green':
      return {
        baseColor: '#13BA65',
        color1: '#B6F2C9',
        color2: '#006620',
        color3: '#99FFB9',
        color4: '#7ACC94',
        cardBackground: Color('#13BA65').green(187).hex()
      };
    case 'orange':
      return {
        baseColor: '#F08E4C',
        color1: '#F2CEB6',
        color2: '#662900',
        color3: '#FFC299',
        color4: '#CC9B7A',
        cardBackground: Color('#F08E4C').green(187).hex()
      };
    case 'violet':
      return {
        baseColor: '#5659B8',
        color1: '#B6B8F2',
        color2: '#000366',
        color3: '#999CFF',
        color4: '#7A7DCC',
        cardBackground: Color('#5659B8').green(187).hex()
      };
    case 'earth':
      return {
        baseColor: '#E0C2A6',
        color1: '#F2D3B6',
        color2: '#663100',
        color3: '#FFCA99',
        color4: '#CCA27A',
        cardBackground: Color('#E0C2A6').green(187).hex()
      }
    default:
      const mainColorHsv = Color(defaultColor).hsv();
      const mainColorH = mainColorHsv.array()[0];
      const mainColorS = mainColorHsv.array()[1];
      return {
        baseColor: defaultColor,
        color1: Color.hsv([mainColorH, 20, 95]).hex(),
        color2: Color.hsv([mainColorH, mainColorS, 50]).hex(),
        color3: Color.hsv([mainColorH, 40, 80]).hex(),
        color4: Color.hsv([mainColorH, 40, 60]).hex(),
        cardBackground: Color(mainColorH).green(187).hex()
      };
  }
}

export const AdvisorProfileThemeProvider = ({ mainColor, children }) => {
  const value = useMemo(() => {
    // Currently, abort the dynamic color calculation
    const { baseColor, color1, color2, color3, color4, cardBackground } = getAdvisorProfilePageDefaultColor(mainColor ?? 'blue');

    const mainColorHsv = Color(baseColor).hsv();

    const tagStyle = {
      borderWidth: 0,
      boxShadow: `rgb(0 0 0 / 12%) 0px 0px 2px, ${mainColorHsv
        .fade(0.4)
        .rgb()
        .toString()} 2px 2px 5px 0px`,
    };
    const buttonSx = {
      backgroundColor: mainColor,
      '&:hover': {
        backgroundColor: color4,
      },
      height: '100%',
    };

    return {
      mainColor: baseColor,
      color1,
      color2,
      color3,
      color4,
      tagStyle,
      buttonSx,
      cardBackground,
    };
  }, [mainColor]);

  const { color1, color2, color3, color4, cardBackground } = value;

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--swiper-theme-color', mainColor);
    root.style.setProperty('--advisor-profile-page-main-color', mainColor);
    root.style.setProperty('--advisor-profile-page-color1', color1);
    root.style.setProperty('--advisor-profile-page-color2', color2);
    root.style.setProperty('--advisor-profile-page-color3', color3);
    root.style.setProperty('--advisor-profile-page-color4', color4);
    root.style.setProperty(
      '--advisor-profile-card-top-background',
      cardBackground,
    );
  }, [mainColor, color1, color2, color3, color4, cardBackground]);

  return (
    <AdvisorProfileThemeContext.Provider value={value}>
      {children}
    </AdvisorProfileThemeContext.Provider>
  );
};

export const useAdvisorProfileTheme = () => {
  const themeContextValue = useContext(AdvisorProfileThemeContext);

  return themeContextValue;
};
