import { SxProps, Theme, Typography, SvgIcon, Box } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import HSpace from 'app/components/HSpace';
import VSpace from 'app/components/VSpace';

const TimeslotCardItem = (props: {
  icon: React.ReactElement;
  text: string;
  sx?: SxProps<Theme>;
  variant?: Variant | undefined;
  fontSize?: string;
  color?: string;
  [key: string]: any;
}) => {
  const { icon, text, fontSize = '1rem', color = '#797979' } = props;

  return (
    <Box
      component={'div'}
      sx={
        props.sx ?? {
          display: 'flex',
          alignItems: 'start',
        }
      }
    >
      <SvgIcon
        sx={{
          margin: '4px 0 0 0',
          width: fontSize,
          height: fontSize,
          color: color,
        }}
      >
        {icon}
      </SvgIcon>

      <HSpace size={2} />
      <Typography
        variant={props.variant ?? 'body1'}
        fontWeight={600}
        color={color}
        component={props.component ?? 'div'}
        fontSize={fontSize}
      >
        {text}
      </Typography>
      <VSpace size={3} />
    </Box>
  );
};

export default TimeslotCardItem;
