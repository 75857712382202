import type { GenderType, SeniorityType, AgeGroup } from 'types';
import * as yup from 'yup';
import type { FormFieldProps, SelectOption } from 'app/components/FormField';
import { translationsJson } from 'locales/i18n';

import type { SxProps } from '@mui/material';
import email from 'utils/validators/validator/email';
import { password } from 'utils/validators/validators';
import { t } from 'i18next';

export const TrHeader = 'Registration';

export type RegistrationFormData = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  ageGroup: AgeGroup;
  gender: GenderType;
  company: string;
  seniority: SeniorityType;
  optOutOfEmail: boolean;
  userType: string;
  campaignCodes: string[];
  language: string;
};

export const RegistrationFormSchema = yup.object().shape({
  name: yup.string().required('此欄位為必填').default(''),
  email: yup
    .string()
    .default('')
    .matches(email.validator, t('Registration.forms-helper-text.email')),
  password: yup
    .string()
    .matches(password.validator, t('Registration.forms-helper-text.password'))
    .default(''),
  phoneNumber: yup
    .string()
    .required(t('Registration.forms-helper-text.phoneNumber')),
  ageGroup: yup.mixed<AgeGroup>().required('此欄位為必填'),
  gender: yup.mixed<GenderType>().required('此欄位為必填'),
  seniority: yup.mixed<SeniorityType>().required('此欄位為必填'),
  company: yup.string().required('此欄位為必填').default(''),
  optOutOfEmail: yup.boolean().required('此欄位為必填').default(false),
  userType: yup.string().required('此欄位為必填').default('Adviser'),
  campaignCodes: yup
    .array()
    .of(yup.string())
    .required('此欄位為必填')
    .default([]),
  language: yup.string().required('此欄位為必填').default('zh-Hant-HK'),
});

export interface RegistrationFormFieldProps {
  sx?: SxProps;
  size?: {
    xs?: number; // Default 12
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  section: 'registration' | 'profile';
  formfield: FormFieldProps;
}

function valueOptionsBuilder<T extends string>(options: {
  [key: string]: string;
}): SelectOption<T>[] {
  return Object.entries(options).map(([key, value]) => {
    return { label: value, value: key } as SelectOption<T>;
  });
}

export function trRegistrationSteps(locale: string): string[] {
  return translationsJson[locale].translation.Registration.steps;
}

export function trRegistrationFormFieldProps(
  locale: string,
): RegistrationFormFieldProps[] {
  const tr = translationsJson[locale].translation.Registration;
  const fieldsNameTr = tr['fields-name'];

  return [
    {
      formfield: {
        type: 'text',
        label: fieldsNameTr.email,
        name: 'email',
        required: true,
        placeholder: 'mail@example.com',
      },
      section: 'registration',
    },
    {
      formfield: {
        type: 'password',
        label: fieldsNameTr.password,
        name: 'password',
        required: true,
        placeholder: fieldsNameTr.password,
      },
      section: 'registration',
    },
    {
      formfield: {
        name: 'phoneNumber',
        type: 'phoneNumber',
        label: fieldsNameTr.phoneNumber,
        required: true,
      },
      section: 'registration',
    },
    {
      formfield: {
        type: 'text',
        label: fieldsNameTr.name,
        name: 'name',
        required: true,
      },
      section: 'profile',
    },
    {
      formfield: {
        name: 'company',
        type: 'text',
        label: fieldsNameTr.company,
        required: true,
      },
      section: 'profile',
    },
    {
      formfield: {
        name: 'ageGroup',
        type: 'select',
        label: fieldsNameTr.ageGroup,
        required: true,
        options: valueOptionsBuilder(tr.options.ageGroup),
      },
      section: 'profile',
    },
    {
      formfield: {
        name: 'gender',
        type: 'select',
        label: fieldsNameTr.gender,
        required: true,
        options: valueOptionsBuilder(tr.options.gender),
      },
      section: 'profile',
    },

    {
      formfield: {
        name: 'seniority',
        type: 'select',
        label: fieldsNameTr.seniority,
        required: true,
        options: valueOptionsBuilder(tr.options.seniority),
      },
      section: 'profile',
    },
  ];
}
