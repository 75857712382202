import { Button, Box, IconButton } from '@mui/material';
import AdvisorProfileCard, {
  AdvisorProfileCardProps,
} from './AdvisorProfileCard';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReactComponent as WeChatIcon } from '../../../icons/wechat.svg';
import VSpace from '../VSpace';
import styles from './AdvisorProfileTeamCard.module.css';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import { formatOpenUrl } from 'utils/formatters';
import { useTranslation } from 'react-i18next';
import ReadOnlyEditor from '../Editor/ReadOnlyEditor';

export default function AdvisorProfileTeamCard({
  advisor,
  loading,
  isDemo,
  isFreeUser
}: AdvisorProfileCardProps) {
  const { t } = useTranslation();
  const { mainColor, buttonSx } = useAdvisorProfileTheme();

  if (!advisor || loading) {
    return <AdvisorProfileCard loading={loading} isDemo={isDemo} isFreeUser={isFreeUser} />;
  }

  const {
    adviserInfo: {
      shouldShowProfilePlusTeamLogo,
      teamDescription,
      teamPage,
      teamInstagram,
      teamFacebook,
      teamLinkedin,
      teamWechat,
      teamLogoUrl,
    },
  } = advisor;

  return (
    <AdvisorProfileCard
      loading={loading}
      heading={t('AdviserProfile.TEAM')}
      isDemo={isDemo}
      isFreeUser={isFreeUser}
    >
      {shouldShowProfilePlusTeamLogo && (
        <>
          <VSpace size={3} />
          <img className={styles.teamLogo} src={teamLogoUrl} alt="team logo" />
        </>
      )}
      <VSpace size={2} />
      {/* TODO: migrate wysiwyg editor */}
      <ReadOnlyEditor defaultValue={teamDescription} readOnly />
      <VSpace size={2} />
      {teamPage && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              href={formatOpenUrl(teamPage)}
              target="_blank"
              sx={buttonSx}
            >
              {t('AdviserProfile.TeamPage')}
            </Button>
          </Box>
          <VSpace size={2} />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          justifyContent: 'center',
          margin: '0 24px',
        }}
      >
        {teamInstagram && (
          <IconButton
            href={`https://www.instagram.com/${teamInstagram}`}
            target="_blank"
            sx={{ color: mainColor }}
          >
            <InstagramIcon />
          </IconButton>
        )}
        {teamFacebook && (
          <IconButton
            href={formatOpenUrl(teamFacebook)}
            target="_blank"
            sx={{ color: mainColor }}
          >
            <FacebookIcon />
          </IconButton>
        )}
        {teamLinkedin && (
          <IconButton
            href={formatOpenUrl(teamLinkedin)}
            target="_blank"
            sx={{ color: mainColor }}
          >
            <LinkedInIcon />
          </IconButton>
        )}
        {teamWechat && (
          <IconButton
            href={formatOpenUrl(teamWechat)}
            target="_blank"
            sx={{ color: mainColor }}
          >
            <WeChatIcon style={{ width: '22px' }} />
          </IconButton>
        )}
      </Box>
    </AdvisorProfileCard>
  );
}
