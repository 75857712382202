import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { User } from 'services/apiTypes';
import { api, subscriptionAPI } from 'services/api';
import { AdviserInfo, Advisor } from 'types';
import { setTrackingUser } from 'hooks/useTracking/useTracking';
import { toURL } from 'utils/index';
import { getIsFreeUser } from 'utils/validators/validator/subscription';
import { useTranslation } from 'react-i18next';
import { Team } from 'services/api';

export type AuthenticatedUser = {
  initialized: boolean;
  user: User | undefined;
  profile?: Advisor | undefined | null;
  subscriptionNickname?: string;
  teams: Team[];
  emailVerified?: boolean;
  isLoading: {
    user: boolean;
    profile: boolean;
  };
  isAnyPartLoading: boolean;
  error: {
    user: any;
    profile: any;
  };
  authenticated: boolean;
  updateProfile: (advisor?: AdviserInfo) => Promise<void> | void;
  signIn: () => void;
  signOut: () => void;
  reAuth: (destination?: string) => Promise<void>;
  isAdviserProfileOwner: (profileId: string) => boolean;
  updateEmailVerified: () => Promise<void>;
  isFreeUser: boolean;
};

const INIT_STATE = {
  initialized: false,
  user: undefined,
  profile: undefined,
  isLoading: {
    user: false,
    profile: false,
  },
  isAnyPartLoading: false,
  error: {
    user: undefined,
    profile: undefined,
  },
  teams: [],
  signOut: () => undefined,
  signIn: () => undefined,
  reAuth: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateEmailVerified: () => Promise.resolve(),
  authenticated: false,
  isAdviserProfileOwner: () => false,
  isFreeUser: true,
};

export const AuthenticatedUserContext =
  createContext<AuthenticatedUser>(INIT_STATE);

const AuthenticatedUserProvider = ({ children }) => {
  const [_state, setState] = useState<AuthenticatedUser>(INIT_STATE);
  const { i18n } = useTranslation();
  const [getUser] = api.useLazyAuthenticateUserQuery();
  const [getProfile] = api.useLazyGetAdviserProfilePlusByIdQuery();
  const [getEmailVerified] = api.useCheckEmailVerifiedMutation();
  const [getActiveSubscription] =
    subscriptionAPI.useLazyGetActiveSubscriptionQuery();
  const [getTeams] = api.useLazyGetTeamsQuery();
  const updateProfile = useCallback(
    async (advisor?: AdviserInfo) => {
      if (!_state.user) return;
      if (_state.profile && advisor) {
        setState(state => {
          return {
            ...state,
            profile: {
              ...state.profile!,
              adviserInfo: advisor,
            } as Advisor,
          };
        });
        return;
      } else {
        setState(state => ({
          ...state,
          isLoading: {
            ...state.isLoading,
            profile: true,
          },
        }));
        const profileRes = await getProfile({ id: _state.user!._id });
        const profile = profileRes.data;
        setState(state => ({
          ...state,
          profile: profile,
          isLoading: {
            ...state.isLoading,
            profile: false,
          },
          error: {
            ...state.error,
            profile: profileRes.error,
          },
        }));
      }
    },
    [_state.profile, _state.user, getProfile],
  );

  const init = useCallback(
    async (redirectTo?: string) => {
      setState({
        ..._state,
        initialized: true,
        isLoading: {
          user: true,
          profile: true,
        },
        isAnyPartLoading: true,
      });
      const usrRes = await getUser();
      const usr = usrRes.data;
      if (usr) {
        setTrackingUser(usr._id);
      } else {
        setTrackingUser(undefined);
      }

      const [profileRes, emailVerified, subscription, teams] =
        await Promise.all([
          usr && getProfile({ id: usr._id }),
          usr && getEmailVerified(usr.email),
          usr && getActiveSubscription(usr._id),
          usr && getTeams(),
        ]);
      let profile = profileRes?.data;

      if (usr?.language) {
        i18n.changeLanguage(usr?.language);
      }

      if (redirectTo) {
        window.location.href = toURL(redirectTo);
      }
      const loading = {
        user: false,
        profile: false,
      };
      setState({
        initialized: true,
        user: usr,
        profile: profile,
        emailVerified: emailVerified?.['data']?.['success'] ?? false,
        teams: teams?.data ?? [],
        isLoading: loading,
        isAnyPartLoading: Object.values(loading).some(Boolean),
        error: {
          user: usrRes.error,
          profile: profileRes?.error,
        },
        authenticated: !!usr,
        subscriptionNickname: subscription?.data?.subscriptionNickname,
        isFreeUser: !!getIsFreeUser(subscription?.data?.subscriptionNickname),
        signIn: () => {
          return window.open(
            process.env.REACT_APP_PFPLUS_FRONTEND_URL,
            '_blank',
          );
        },
        updateProfile: updateProfile,
        signOut: () => {},
        reAuth: init,
        isAdviserProfileOwner: (profileId: string) => {
          return !!usr?._id && usr?._id === profileId;
        },
        updateEmailVerified: async () => {
          if (!usr) return;
          const res = (await getEmailVerified(usr?.email).unwrap()).success;
          setState(state => ({
            ...state,
            emailVerified: res,
          }));
          return;
        },
      });
    },
    [
      _state,
      getUser,
      getProfile,
      getEmailVerified,
      getActiveSubscription,
      getTeams,
      updateProfile,
      i18n,
    ],
  );

  useEffect(() => {
    if (!_state.initialized && !_state.isAnyPartLoading) {
      init();
    }
  }, [init, _state.initialized, _state.isAnyPartLoading]);

  return useMemo(() => {
    return (
      <AuthenticatedUserContext.Provider value={_state}>
        {children}
      </AuthenticatedUserContext.Provider>
    );
  }, [_state, children]);
};

export default AuthenticatedUserProvider;
