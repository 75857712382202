import { Box } from '@mui/material';
import { PageLayoutProps } from './PageLayout.types';
import style from './PageLayout.module.css';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

function PageLayout(props: PageLayoutProps): JSX.Element {
  const pathName = useLocation().pathname.split('/')[1];
  const { t } = useTranslation();
  const layoutClass = clsx(
    style.PageLayout,
    props.options?.fullHeight && style.fullHeight,
    props.options?.fullWidth && style.fullWidth,
    props.options?.noPadding && style.noPadding,
  );

  return (
    props.builder?.(
      {
        className: layoutClass,
        maxWidth: props.options?.maxWidth ?? 'sm',
      },
      props.children,
    ) || (
      <>
        {(props.options?.helmet || true) && (
          <Helmet>
            {<title>{t(`PageSettings.title.${pathName}`)}</title>}
            {props.headers && props.headers}
          </Helmet>
        )}
        <Box className={layoutClass} maxWidth={props.options?.maxWidth ?? 'sm'}>
          {props.children}
        </Box>
      </>
    )
  );
}

export default PageLayout;
