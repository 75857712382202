import { Button, Box } from '@mui/material';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import styles from './AdvisorProfileCardSlides.module.css';
import {
  useHeightDifference,
  useIsTablet,
  useMostFitDownSize,
} from 'hooks/useBreakpoints/useBreakpoints';
import AdvisorProfileSummaryCard from './AdvisorProfileSummaryCard';
import AdvisorProfileAboutMeCard from './AdvisorProfileAboutMeCard';
import { Advisor } from 'types';
import AdvisorProfileSocialCard from './AdvisorProfileSocialCard';
import AdvisorProfileBusinessCard from './AdvisorProfileBusinessCard';
import AdvisorProfileContactCard from './AdvisorProfileContactCard';
import AdvisorProfileTeamCard from './AdvisorProfileTeamCard';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import { PaidFeatureAlertType } from '../PaidFeatureAlert/PaidFeatureAlert.types';
import LanguageButton from 'app/components/LanguageButton';

export default function AdvisorProfileCardSlides({
  advisor,
  onShowShortcut,
  children,
  isFreeUser,
  paidFeatureAlertType,
  isDemo,
}: {
  isDemo: boolean;
  isFreeUser: boolean;
  advisor?: Advisor;
  onShowShortcut?: () => void;
  children?: React.ReactNode;
  paidFeatureAlertType: PaidFeatureAlertType;
}) {
  const isTablet = useIsTablet();

  const swiperRef = useRef<any>(null);
  const onSwiper = swiper => (swiperRef.current = swiper);
  const [, setSlideIndex] = useState(0);
  const onSlideChange = swiper => {
    setSlideIndex(swiper.realIndex);
  };

  const showSwiperPagination = useHeightDifference(670) >= 0;
  const isSMDevice = useMostFitDownSize() === 'sm';

  const { mainColor, color4 } = useAdvisorProfileTheme();

  const navButtonSx = {
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: mainColor,
    '&:hover': {
      backgroundColor: color4,
    },
  };

  const shouldShowProfilePlusTeamPage =
    advisor?.adviserInfo.shouldShowProfilePlusTeamPage ?? false;

  function getSwiperPagination() {
    const content = (
      <Box className={clsx(styles.swiperPagination, 'swiper-pagination')} />
    );

    if (!showSwiperPagination) {
      return <></>;
    } else if (isSMDevice) {
      return (
        <Box
          style={{
            position: 'fixed',
            top: 24,
            left: 0,
            width: '100%',
            zIndex: 100,
          }}
        >
          {content}
        </Box>
      );
    } else {
      return <Box>{content}</Box>;
    }
  }
  
  return (
    <Box className={styles.container} paddingTop={'24px'}>
      <Box style={{ position: 'fixed', top: 16, right: 6, zIndex: 100 }}>
        <LanguageButton color={mainColor} />
      </Box>

      {getSwiperPagination()}

      <Box className={styles.cardsContainer} paddingTop={'24px'}>
        <Button
          variant="contained"
          className={clsx(styles.swiperButtonPrev)}
          sx={navButtonSx}
          onClick={() => swiperRef.current?.slidePrev()}
          disabled={!advisor}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
        </Button>
        <Swiper
          initialSlide={0}
          slidesPerView={isTablet ? 1.3 : 1.5}
          fadeEffect={{
            crossFade: true,
          }}
          loop={true}
          loopedSlides={0}
          centeredSlides={true}
          centeredSlidesBounds={true}
          autoHeight={true}
          touchStartPreventDefault={false}
          effect="coverflow"
          autoplay={{
            delay: 12000,
          }}
          coverflowEffect={{
            rotate: 0,
            depth: 350,
            modifier: 2,
            stretch: 75,
            slideShadows: false,
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          onSwiper={onSwiper}
          onSlideChange={onSlideChange}
          allowSlidePrev={!!advisor}
          allowSlideNext={!!advisor}
        >
          {isTablet && (
            <SwiperSlide>
              <AdvisorProfileSummaryCard
                advisor={advisor}
                onShowShortcut={onShowShortcut}
                isFreeUser={isFreeUser}
                paidFeatureAlertType={paidFeatureAlertType}
                isDemo={isDemo}
              />
            </SwiperSlide>
          )}
          <SwiperSlide>
            <AdvisorProfileAboutMeCard
              advisor={advisor}
              isDemo={isDemo}
              isFreeUser={isFreeUser}
            />
          </SwiperSlide>
          <SwiperSlide>
            <AdvisorProfileSocialCard
              advisor={advisor}
              isDemo={isDemo}
              isFreeUser={isFreeUser}
            />
          </SwiperSlide>
          <SwiperSlide>
            <AdvisorProfileBusinessCard
              advisor={advisor}
              isDemo={isDemo}
              isFreeUser={isFreeUser}
            />
          </SwiperSlide>
          <SwiperSlide>
            <AdvisorProfileContactCard
              advisor={advisor}
              isDemo={isDemo}
              isFreeUser={isFreeUser}
            />
          </SwiperSlide>
          {shouldShowProfilePlusTeamPage && (
            <SwiperSlide>
              <AdvisorProfileTeamCard
                advisor={advisor}
                isDemo={isDemo}
                isFreeUser={isFreeUser}
              />
            </SwiperSlide>
          )}
        </Swiper>
        <Button
          variant="contained"
          className={clsx(styles.swiperButtonNext)}
          sx={navButtonSx}
          onClick={() => swiperRef.current?.slideNext()}
          disabled={!advisor}
        >
          <KeyboardArrowRightIcon fontSize="small" />
        </Button>
      </Box>
      {children}
    </Box>
  );
}
