import { Box, Button, Chip, Grid, Link, Typography } from '@mui/material';
// import {
//   AnimatableSelect,
//   SelectableStatus,
// } from 'app/components/Animatable/Animatable';
import React, { type ReactNode } from 'react';
// import HSpace from 'app/components/HSpace';
import {
  type RegistrationFormData,
  type RegistrationFormFieldProps,
  trRegistrationFormFieldProps,
} from 'services/signup/signup';
import FormField from 'app/components/FormField';
import { TrHeader } from 'services/signup/signup';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import {
//   AnimatableSelect,
//   SelectableStatus,
// } from 'app/components/Animatable/Animatable';
import VSpace from 'app/components/VSpace';
import styles from './ProfileForm.module.css';
import clsx from 'clsx';
import type { ProfileFormProps } from './ProfileForm.types';
import { trackInteractionEvent } from 'hooks/hooks';
import Loader from 'app/components/Loader/Loader';

const ProfileForm = (props: ProfileFormProps) => {
  const { control, trigger, setValue } = useFormContext();

  const { t, i18n } = useTranslation();

  const formFiels = trRegistrationFormFieldProps(i18n.language);
  const formfieldProfiles = trRegistrationFormFieldProps(i18n.language).filter(
    setting => setting.section === 'profile',
  );

  const [loading, setLoading] = React.useState(false);

  function optionSelect(setting: RegistrationFormFieldProps): ReactNode {
    const { name, options } = setting.formfield;
    if (!name) return <></>;

    return (
      <Controller
        control={control}
        name={name as string}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: error ? 'var(--color-error-main)' : 'inherit',
                marginBottom: '8px',
                textAlign: 'start',
              }}
            >
              {setting.formfield.label}
              <Typography
                variant="body1"
                component={'span'}
                sx={{ color: 'var(--color-error-main)' }}
              >
                {' '}
                *
              </Typography>
            </Typography>
            <VSpace size="3px" />
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {options?.map((option, index) => {
                const cls = clsx(
                  value === option.value
                    ? [styles.chip, styles.selected].join(' ')
                    : [styles.chip, styles.display].join(' '),
                );
                return (
                  <Box key={`${name}-${index}`}>
                    <Chip
                      className={cls}
                      label={option.label}
                      variant="outlined"
                      sx={{
                        margin: '0 8px 8px 0',
                      }}
                      onClick={() => setValue(name, option.value)}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      />
    );
  }

  async function onFormSummit(e: React.MouseEvent<HTMLButtonElement>) {
    setLoading(true);
    trackInteractionEvent({
      type: 'Button Clicked',
      event_properties: {
        Name: 'Register',
        'Content Name': 'Set Up Card',
      },
    });

    const v = await trigger(formFiels.map(setting => setting.formfield.name!));
    const values = control._formValues as RegistrationFormData;
    
    if (v) {
      await props.onReg({ ...values, language: i18n.language });
    }
    setLoading(false);
  }

  return (
    <Box component="form" noValidate>
      <Grid container spacing={2}>
        {formfieldProfiles.map((setting, index) => {
          const { sx, size, formfield } = setting;
          return (
            <Grid
              key={`reg-form-field-${formfield.name}`}
              item
              xs={12}
              sx={sx}
              {...size}
            >
              {formfield.type === 'select' ? (
                optionSelect(setting)
              ) : (
                <FormField control={control} {...formfield} />
              )}
            </Grid>
          );
        })}
      </Grid>

      <Loader loading={loading}>
        <Button
          onClick={onFormSummit}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t(`${TrHeader}.registerCTA`)}
        </Button>
      </Loader>

      <Grid container justifyContent="center">
        <Grid item>
          <Link
            href={`${process.env.REACT_APP_PFPLUS_FRONTEND_URL}/login`}
            variant="body2"
          />
        </Grid>
      </Grid>
      <VSpace size={5} />
    </Box>
  );
};

export default ProfileForm;
