import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { builder } from './route/route';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { getThemeOptions } from 'styles/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { ColorModeProvider, useColorMode } from 'hooks/useColorMode';
import { CssBaseline } from '@mui/material';
import {
  PUBLIC_ROUTES,
  DASHBOARD_ROUTES,
  FRAME_ROUTES,
  PRIVATE_ROUTES,
} from './route/route';
import ErrorBoundary from './components/ErrorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DashboardMenu } from './components/DashboardMenu';
import './index.css';
import { AuthenticatedUserContext } from 'hooks/hooks';

// import { useTrackEvent, Events, Channels, Features } from 'hooks/useTracking';

// import { mixpanelTest } from 'services/tracking/tracking';

// mixpanelTest();

export const ColorModeContext = React.createContext({
  toggleColorMode: () => undefined,
});

const router = (authed: boolean) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* Dashboard routes */}
        <Route element={<DashboardMenu />}>
          {DASHBOARD_ROUTES.map(props => builder(props, authed))}
        </Route>
        {/* Public routes routes */}
        {PRIVATE_ROUTES.map(props => builder(props, authed))},
        {PUBLIC_ROUTES.map(props => builder(props, authed))},
        {/* FRAME routes routes */}
        {FRAME_ROUTES.map(props => builder(props, authed))},
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );

export function App() {
  return (
    <ColorModeProvider>
      <WrappedApp />
    </ColorModeProvider>
  );
}

export function WrappedApp() {
  const { t, i18n } = useTranslation();
  const { colorMode } = useColorMode();
  const AuthenticatedUser = React.useContext(AuthenticatedUserContext);

  const theme = useMemo(
    () => createTheme(getThemeOptions(colorMode)),
    [colorMode],
  );

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet
            titleTemplate="%s - Linker"
            defaultTitle="Linker"
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content={t('Meta.Description')} />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-translucent"
            />
          </Helmet>
          <RouterProvider
            router={router(AuthenticatedUser.authenticated)}
          ></RouterProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}
