import {
  Box,
  Button,
  type ButtonProps,
  IconButton,
  Modal,
  type SxProps,
  TextField,
  type Theme,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  Avatar,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import type { Advisor } from 'types';
import { useState, useContext } from 'react';
import styles from './ShareButton.module.css';
import { useTranslation } from 'react-i18next';
import VSpace from '../VSpace';
import useCopy from 'hooks/useCopy';
import { useAdvisorProfileTheme } from './AdvisorProfileThemeProvider';
import { languageToLabel, type SupportedLanguage } from 'locales/i18n';
import {
  useGetAdviserTeamLogoNewsQuery,
  useLazyGetWalletPassQuery,
} from 'services/api';
import { getReferralLink, ReferralMedium } from 'utils/referral';
import { getDeviceOS } from 'utils/Device/Device';
import { isFrameCheck } from 'utils/Frame/isFrameCheck';
import Loading from 'app/components/Loading/Loading';
import { postMessageToParent } from 'utils/Frame/Frame';
import { AuthenticatedUserContext } from 'hooks/useAuthenticatedUser/useAuthenticatedUser';
import PaidFeatureAlert from 'app/components/PaidFeatureAlert/PaidFeatureAlert';
import { PaidFeatureAlertType } from 'app/components/PaidFeatureAlert/PaidFeatureAlert.types';
import { api } from 'services/api';
import { trackInteractionEvent } from 'hooks/hooks';
import { getAvatarURL } from 'utils/getAvatarList';

const SHARE_MODAL_STYLE = {
  position: 'absolute',
  top: {
    md: '50%',
  },
  bottom: {
    xs: 0,
  },
  left: '50%',
  transform: {
    xs: 'translate(-50%, 0)',
    md: 'translate(-50%, -50%)',
  },
  width: {
    xs: '100%',
    md: 400,
  },
  // minHeight: 'max-content',
  maxHeight: '80vh',
  height: 'max-content',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: {
    xs: 2,
    md: 4,
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
  },
};

export default function ShareButton({
  role = 'user',
  className,
  sx,
  advisor,
  buttonText,
  ...props
}: {
  role?: 'user' | 'advisor';
  className?: string;
  sx?: SxProps<Theme>;
  advisor?: Advisor;
  buttonText?: string;
} & ButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className={className}
        variant="contained"
        sx={{
          height: '100%',
          width: '100%',
          minWidth: 0,
          borderRadius: '100px',
          ...(role === 'advisor' && {
            display: 'flex',
            gap: 1,
          }),
          ...sx,
        }}
        {...props}
        disabled={!advisor}
        onClick={() => setOpen(true)}
      >
        {role === 'advisor' ? (
          <>
            <ShareIcon fontSize="small" />
            {t('AdviserProfile.ShareToClient')}
          </>
        ) : buttonText ? (
          <Typography>{buttonText}</Typography>
        ) : (
          <ShareIcon fontSize="small" />
        )}
      </Button>
      {role === 'user' && (
        <UserShareModal
          open={open}
          onClose={() => setOpen(false)}
          advisor={advisor}
        />
      )}
      {role === 'advisor' && (
        <AdvisorShareModal
          open={open}
          onClose={() => setOpen(false)}
          advisor={advisor}
        />
      )}
    </>
  );
}

export function AdvisorShareModal({
  open,
  onClose,
  advisor,
}: {
  open: boolean;
  onClose: () => void;
  advisor?: Advisor;
}) {
  const [loading, setLoading] = useState(false);
  const [getWalletPass] = useLazyGetWalletPassQuery();
  const AuthenticatedUser = useContext(AuthenticatedUserContext);
  const { data: teamLogoNews } = useGetAdviserTeamLogoNewsQuery();
  const { mainColor, buttonSx } = useAdvisorProfileTheme();
  const { t, i18n } = useTranslation();
  const os = getDeviceOS();

  const getGoogleWalletPassAndOpen = async () => {
    try {
      setLoading(true);
      let result = await getWalletPass({
        platform: 'google',
        language:
          i18n.language === 'zh-Hant-HK'
            ? 'zh-Hant'
            : i18n.language === 'zh'
            ? 'zh-Hans'
            : 'en',
      });
      if (result?.data?.url) {
        if (os === 'Android' && isFrameCheck()) {
          postMessageToParent({
            success: true,
            code: 'OPEN_IN_NEW_WINDOW',
            data: result.data.url,
          });
        } else {
          window.open(result.data.url, '_blank');
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppleWalletPassAndOpen = async () => {
    setLoading(true);
    if (os === 'iOS' && isFrameCheck()) {
      setLoading(true);
      postMessageToParent({
        success: true,
        code: 'DOWNLOAD_APPLE_WALLET_ECARD',
        data:
          i18n.language === 'zh-Hant-HK'
            ? 'zh-Hant'
            : i18n.language === 'zh'
            ? 'zh-Hans'
            : 'en',
      });
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(true);
      setTimeout(() => {
        const a = document.createElement('a');
        a.href = `${
          process.env.REACT_APP_PFPLUS_BACKEND_URL
        }/wallet-pass/apple?language=${
          i18n.language === 'zh-Hant-HK'
            ? 'zh-Hant'
            : i18n.language === 'zh'
            ? 'zh-Hans'
            : 'en'
        }`;
        a.target = '_self';
        a.click();
        a.remove();
        setLoading(false);
      }, 250);
    }
  };

  const [shareMessageLanguage, setShareMessageLanguage] =
    useState<SupportedLanguage>('zh-Hant-HK');

  const adviserLink = getReferralLink({
    medium: ReferralMedium.LINK,
    target: advisor?.adviserInfo._id ?? '',
  });
  const shareMessage = t('AdviserProfile.AdviserShareMessage', {
    adviserLink,
    adviserName: advisor?.adviserInfo.name,
    lng: shareMessageLanguage,
  });

  const copyShareMessage = useCopy({
    textToCopy: shareMessage,
    successMessage: t('AdviserProfile.ShareMessageCopied'),
  });

  const whatsAppSendHref = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    shareMessage,
  )}`;

  const [trackDirect] = api.useTrackDirectMutation();
  const handleCopyShareMessage = () => {
    if (AuthenticatedUser.isAdviserProfileOwner(advisor?.adviserInfo._id!)) {
      trackDirect({ type: 'adviserShareEcard' });
    }
    trackInteractionEvent({
      type: 'Content Copied',
      event_properties: {
        target: advisor?.adviserInfo._id!,
        'Content Name': 'Adviser Share Message',
        Name: 'Adviser Share Modal',
        Component: 'Adviser Share Modal',
        'Event Name': 'Adviser share E-Name card',
      },
    });
    copyShareMessage();
  };

  return (
    <PaidFeatureAlert
      type={PaidFeatureAlertType.info}
      plan={AuthenticatedUser.isFreeUser}
      render={(onAlert, shouldShowAlert) => {
        const handlePaidFeatureAlert = () => {
          onClose();
          setTimeout(() => {
            onAlert();
          }, 0);
        };
        return (
          <Modal open={open} onClose={onClose}>
            <Box sx={SHARE_MODAL_STYLE}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <VSpace size={4} />
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 3,
                  position: 'relative',
                  borderRadius: '20px',
                  border: '1px solid rgba(57, 102, 248, 0.08)',
                  background: 'rgba(57, 102, 248, 0.04)',
                }}
              >
                <Avatar
                  src={getAvatarURL(advisor?.adviserInfo.avatar)}
                  sx={{
                    width: '72px',
                    height: '72px',
                    margin: 'auto',
                    border: `2px solid ${mainColor}`,
                    position: 'absolute',
                    top: '-40px',
                  }}
                />
                <QRCodeSVG
                  style={{
                    maxWidth: '300px',
                    padding: '16px',
                  }}
                  width="100%"
                  height="100%"
                  value={adviserLink}
                  imageSettings={{
                    src: '/images/n-logo-rounded.svg',
                    height: 28,
                    width: 28,
                    excavate: false,
                  }}
                />
              </Paper>

              {os === 'iOS' && (
                <Box display="flex" justifyContent="center">
                  {loading ? (
                    <Loading sx={{ fontSize: 56 }} label={null} />
                  ) : (
                    <img
                      src={`/images/downloadFromAppStore/addToAppleWallet_${
                        i18n.language === 'zh-Hant-HK'
                          ? 'tc'
                          : i18n.language === 'zh'
                          ? 'sc'
                          : 'en'
                      }.svg`}
                      alt="add-to-apple-wallet"
                      onClick={
                        shouldShowAlert
                          ? handlePaidFeatureAlert
                          : getAppleWalletPassAndOpen
                      }
                      style={{ width: 216, height: 64, marginTop: 24 }}
                    />
                  )}
                </Box>
              )}
              {os === 'Android' && (
                <Box display="flex" justifyContent="center">
                  {loading ? (
                    <Loading sx={{ fontSize: 56 }} label={null} />
                  ) : (
                    <img
                      src={`/images/downloadFromAppStore/addToGoogleWallet_${
                        i18n.language === 'zh-Hant-HK'
                          ? 'tc'
                          : i18n.language === 'zh'
                          ? 'sc'
                          : 'en'
                      }.svg`}
                      alt="add-to-google-wallet"
                      onClick={
                        shouldShowAlert
                          ? handlePaidFeatureAlert
                          : getGoogleWalletPassAndOpen
                      }
                      style={{ width: 216, height: 64, marginTop: 24 }}
                    />
                  )}
                </Box>
              )}

              <VSpace size={2} />
              <Typography variant="body1" align="center">
                {t('AdviserProfile.OrShareLink')}
              </Typography>
              <VSpace size={2} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <RadioGroup
                  row
                  value={shareMessageLanguage}
                  onChange={e => setShareMessageLanguage(e.target.value as any)}
                >
                  <FormControlLabel
                    value="en"
                    control={<Radio />}
                    label={languageToLabel('en')}
                  />
                  <FormControlLabel
                    value="zh-Hant-HK"
                    control={<Radio />}
                    label={languageToLabel('zh-Hant-HK', true)}
                  />
                  <FormControlLabel
                    value="zh"
                    control={<Radio />}
                    label={languageToLabel('zh', true)}
                  />
                </RadioGroup>
              </Box>
              <VSpace size={2} />
              <TextField
                className={styles.shareMessage}
                multiline
                maxRows={4}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton onClick={handleCopyShareMessage}>
                      <ContentCopyIcon />
                    </IconButton>
                  ),
                  maxRows: 2,
                }}
                value={shareMessage}
              />
              <VSpace size={2} />
              <Button
                variant="contained"
                startIcon={<WhatsAppIcon />}
                fullWidth
                href={whatsAppSendHref}
                target="_blank"
                sx={{
                  ...buttonSx,
                  // Based on the button that set to 100% height, it's no a proper size for this button,
                  // so we set the height to 48px for temporary solution,  should check if height 100% is necessary later.
                  maxHeight: '48px',
                }}
              >
                {t('Global.Send')}
              </Button>
            </Box>
          </Modal>
        );
      }}
    />
  );
}

function UserShareModal({
  open,
  onClose,
  advisor,
}: {
  open: boolean;
  onClose: () => void;
  advisor?: Advisor;
}) {
  const { isAdviserProfileOwner } = useContext(AuthenticatedUserContext);
  const { mainColor, buttonSx } = useAdvisorProfileTheme();
  const { t } = useTranslation();

  const adviserLink = getReferralLink({
    medium: ReferralMedium.LINK,
    target: advisor?.adviserInfo._id ?? '',
  });
  const shareMessage = t('AdviserProfile.ShareMessage', {
    adviserLink,
  });

  const copyShareMessage = useCopy({
    textToCopy: shareMessage,
    successMessage: t('AdviserProfile.ShareMessageCopied'),
  });

  const whatsAppSendHref = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    shareMessage,
  )}`;

  const [trackDirect] = api.useTrackDirectMutation();
  const handleCopyShareMessage = () => {
    if (isAdviserProfileOwner(advisor?.adviserInfo._id ?? '')) {
      trackDirect({ type: 'adviserShareEcard' });
    }
    trackInteractionEvent({
      type: 'Content Copied',
      event_properties: {
        target: advisor?.adviserInfo._id!,
        'Content Name': 'Client Share Message',
        Name: 'Client Share Modal',
        Component: 'Client Share Modal',
        'Event Name': 'Client share E-Name card',
      },
    });
    copyShareMessage();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={SHARE_MODAL_STYLE}>
        <Box className={styles.headerTitle}>
          <ShareIcon sx={{ color: mainColor }} />
          <Typography variant="h6" color={mainColor} fontWeight={500}>
            {t('Global.Share')}
          </Typography>
        </Box>
        <VSpace size={2} />
        <TextField
          className={styles.shareMessage}
          multiline
          maxRows={4}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton onClick={handleCopyShareMessage}>
                <ContentCopyIcon />
              </IconButton>
            ),
          }}
          value={shareMessage}
        />
        <VSpace size={2} />
        <Button
          variant="contained"
          startIcon={<WhatsAppIcon />}
          fullWidth
          href={whatsAppSendHref}
          target="_blank"
          sx={{
            ...buttonSx,
            // Based on the button that set to 100% height, it's no a proper size for this button,
            // so we set the height to 48px for temporary solution,  should check if height 100% is necessary later.
            maxHeight: '48px',
          }}
        >
          {t('Global.Send')}
        </Button>
      </Box>
    </Modal>
  );
}
